import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EtatCivilComponent } from './etat-civil/etat-civil.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [EtatCivilComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatTooltipModule
  ],
  exports: [EtatCivilComponent]
})
export class EtatCivilModule { }
