<div class="headerTemplate">
  <app-header></app-header>
</div>
<div class="searchTemplate">
  <app-search-menu class="search" (eventFiltre)="updateFiltre($event)"></app-search-menu>
  <app-games-menu *ngIf="donneesChargees | async" [listeLettrines]="listeLettrines" [listeJeux]="listeJeux" [filtre]="filtreRecherche"></app-games-menu>
</div>
<div class="gameTemplate">
  <app-game-view></app-game-view>
</div>
