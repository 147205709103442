<div class="marginTop">Sélectionner l'âge de votre personnage et pensez à lire les indications des différentes étapes :</div>
<div class="col-md-8 affichageInline parcours marginTop">
  <div class="mobile">
    <div class="affichageInline cartouche noSelect titre mr-1 mb-2">
      <span class="affichageBloc textCenter graduationHaut">15/19</span>
      <span class="tooltiptext noSelect">-5 pts entre Force et Taille. Education -5 pts, vous pouvez lancer deux fois la chance et conserver le meilleur score.</span>
    </div>
    <div class="affichageInline cartouche noSelect titre mb-2 ml-1 mr-1">
      <span class="affichageBloc textCenter graduationBas width27">20/39</span>
      <span class="tooltiptext noSelect">1 jet d'expérience.</span>
    </div>
    <div class="affichageInline cartouche noSelect titre ml-1 mb-2">
      <span class="affichageBloc textCenter graduationHaut half">40/49</span>
      <span class="tooltiptext noSelect">-5 pts entre Force, Constitution et Dextérité. Apparence -5 pts. 2 jets d'expérience.</span>
    </div>
    <div class="affichageInline cartouche noSelect titre mr-1 mb-2">
      <span class="affichageBloc textCenter graduationBas half">50/59</span>
      <span class="tooltiptext noSelect">-10 pts entre Force, Constitution et Dextérité. Apparence -10 pts. 3 jets d'expérience.</span>
    </div>
    <div class="affichageInline cartouche noSelect titre  mb-2 ml-1 mr-1">
      <span class="affichageBloc textCenter graduationHaut half">60/69</span>
      <span class="tooltiptext noSelect">-20 pts entre Force, Constitution et Dextérité. Apparence -15 pts. 4 jets d'expérience.</span>
    </div>
    <div class="affichageInline cartouche noSelect titre  ml-1 mb-2">
      <span class="affichageBloc textCenter graduationBas half">70/79</span>
      <span class="tooltiptext noSelect">-40 pts entre Force, Constitution et Dextérité. Apparence -20 pts. 4 jets d'expérience.</span>
    </div>
    <div class="affichageInline cartouche noSelect titre">
      <span class="affichageBloc textCenter graduationHaut half">80/89</span>
      <span class="tooltiptext noSelect">-80 pts entre Force, Constitution et Dextérité. Apparence -25 pts. 4 jets d'expérience.</span>
    </div>
    <input type="range" min="15" max="89" step="1" class="slide affichageInline mt-2" id="age" value="{{age}}" (change)="actualisationAge($event)">
    <span class="affichageInline age">{{age}}</span>
  </div>
  <div class="desktop">
    <div class="affichageInline accolade4 noSelect titre">
      <span class="affichageBloc textCenter graduationHaut">15/19</span>
      <img src="assets/images/accolade.png" alt="Période 15-19 ans" class="full">
      <span class="tooltiptext noSelect">-5 pts entre Force et Taille. Education -5 pts, vous pouvez lancer deux fois la chance et conserver le meilleur score.</span>
    </div>
    <div class="affichageInline accolade20">
    </div>
    <div class="affichageInline accolade noSelect titre">
      <span class="affichageBloc textCenter graduationHaut half">40/49</span>
      <img src="assets/images/accolade.png" alt="Période 40-49 ans" class="full">
      <span class="tooltiptext noSelect">-5 pts entre Force, Constitution et Dextérité. Apparence -5 pts. 2 jets d'expérience.</span>
    </div>
    <div class="affichageInline accolade">
    </div>
    <div class="affichageInline accolade noSelect titre">
      <span class="affichageBloc textCenter graduationHaut half">60/69</span>
      <img src="assets/images/accolade.png" alt="Période 60-69 ans" class="full">
      <span class="tooltiptext noSelect">-20 pts entre Force, Constitution et Dextérité. Apparence -15 pts. 4 jets d'expérience.</span>
    </div>
    <div class="affichageInline accolade">
    </div>
    <div class="affichageInline accolade10 noSelect titre">
      <span class="affichageBloc textCenter graduationHaut half">80/89</span>
      <img src="assets/images/accolade.png" alt="Période 80-89 ans" class="full">
      <span class="tooltiptext noSelect">-80 pts entre Force, Constitution et Dextérité. Apparence -25 pts. 4 jets d'expérience.</span>
    </div>
    <input type="range" min="15" max="89" step="1" class="slide affichageInline" id="age" value="{{age}}" (change)="actualisationAge($event)">
    <span class="affichageInline age">{{age}}</span>
    <div class="affichageInline accolade4">
    </div>
    <div class="affichageInline accolade20 noSelect titre">
      <img src="assets/images/accolade.png" alt="Période 20-39 ans" class="full retournement">
      <span class="affichageBloc textCenter graduationBas width27">20/39</span>
      <span class="tooltiptext noSelect">1 jet d'expérience.</span>
    </div>
    <div class="affichageInline accolade">
    </div>
    <div class="affichageInline accolade noSelect titre">
      <img src="assets/images/accolade.png" alt="Période 50-59 ans" class="full retournement">
      <span class="affichageBloc textCenter graduationBas half">50/59</span>
      <span class="tooltiptext noSelect">-10 pts entre Force, Constitution et Dextérité. Apparence -10 pts. 3 jets d'expérience.</span>
    </div>
    <div class="affichageInline accolade">
    </div>
    <div class="affichageInline accolade noSelect titre">
      <img src="assets/images/accolade.png" alt="Période 70-79 ans" class="full retournement">
      <span class="affichageBloc textCenter graduationBas half">70/79</span>
      <span class="tooltiptext noSelect">-40 pts entre Force, Constitution et Dextérité. Apparence -20 pts. 4 jets d'expérience.</span>
    </div>
  </div>
  <div class="marginTop2">
    <p class="justifier">En fonction de son âge votre personnage va accroître ses connaissances intellectuelles mais diminuer ses capacités physiques. En cas de malus vous allez devoir ventiler un certains nombre de points de malus entre vos caractéristiques mais vous pourrez tenter de réussir vos jets d'expérience pour gagner de l'éducation.</p>
  </div>
  <div class="col-md-6 affichageInline">
    <div *ngIf="age < 20">
      <div>
        Vos jets de chances :
        <img src="assets/images/desLancer.png" alt="Lancer de dès" class="affichageInline lancer" (click)="lancerChance()">
      </div>
      <div class="marginTop2">
        <span>Actuel : </span>
        <span class="affichageInline jetChance jetChanceMargin">{{this.caracteristiques[8].valeur}}</span>
        <span *ngIf="chance > 0">Jet : </span>
        <span class="affichageInline jetChance jetChanceMargin">{{this.premierJetChance}}</span>
        <span class="affichageInline jetChance jetChanceMargin">{{this.secondJetChance}}</span>
      </div>
      <div>
        <span class="affichageInline resultat half">{{this.chance}}</span>
      </div>
    </div>
    <div *ngIf="age > 19">
      <div>
        Vos jets d'expérience :
        <img src="assets/images/desLancer.png" alt="Lancer de dès" class="affichageInline lancer" (click)="lancerExperience()">
      </div>
      <div class="marginTop2">
        <span class="affichageInline">Base :</span><p class="jetChance jetChanceMargin affichageInline">{{this.caracteristiques[6].valeur}}</p>
        <span *ngIf="experienceJets.length > 0" class="affichageInline">Jet :</span>
        <span *ngFor="let resultat of experienceJets">
          <span *ngIf="resultat == 'OK'" class="jetExperienceOK jetChanceMargin affichageInline result"> {{resultat}} </span>
          <span *ngIf="resultat == 'KO'" class="jetExperienceKO jetChanceMargin affichageInline result"> {{resultat}} </span>
        </span>
      </div>
      <div class="half">
        <span class="affichageInline resultat half">{{this.education}}</span><span class="affichageInline"> soit {{bonus}} de bonus</span>
      </div>
    </div>
  </div>
  <div *ngIf="age < 20 || age > 39" class="col-md-6 affichageInline">
    <div>
      Les points à enlever restant : <p class="jetChance jetChanceMargin affichageInline">{{malusCourant}}</p>
      <p class="affichageInline"> / </p>
      <p class="jetChance jetChanceMargin affichageInline">{{malusCommun}}</p>
    </div>
    <div *ngIf="age < 20" class="desktop">
      <div class="col-md-4 affichageInline">
        <span class="affichageBloc full textCenter">Education</span>
        <span class="affichageBloc full textCenter">- {{malusEducation}}</span>
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[6], -malusEducation)}}</span>
      </div>
      <div class="col-md-4 affichageInline">
        <span class="affichageBloc full textCenter">Force</span>
        <input type="number" min="0" max="{{malusMaxForce}}" class="affichageBloc full textCenter malus" onkeydown="return false" value="{{malusForce}}" (change)="updateMalus($event, caracteristiques[7])">
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[7], -malusForce)}}</span>
      </div>
      <div class="col-md-4 affichageInline">
        <span class="affichageBloc full textCenter">Taille</span>
        <input type="number" min="0" max="{{malusMaxTaille}}" class="affichageBloc full textCenter malus" onkeydown="return false" value="{{malusTaille}}" (click)="updateMalus($event, caracteristiques[4])" >
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[4], -malusTaille)}}</span>
      </div>
    </div>
    <div *ngIf="age < 20" class="mobile">
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Education</span>
        <span class="affichageInline textCenter w-25">- {{malusEducation}}</span>
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[6], -malusEducation)}}</span>
      </div>
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Force</span>
        <input type="number" class="affichageInline textCenter malus w-25" (keyup)="checkNumber($event, 0, malusMaxForce, caracteristiques[7])" value="{{malusForce}}">
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[7], -malusForce)}}</span>
      </div>
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Taille</span>
        <input type="number" class="affichageInline textCenter malus w-25" (keyup)="checkNumber($event, 0, malusMaxTaille, caracteristiques[4])"  value="{{malusTaille}}">
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[4], -malusTaille)}}</span>
      </div>
    </div>
    <div *ngIf="age > 39" class="desktop">
      <div class="col-md-3 affichageInline">
        <span class="affichageBloc full textCenter">Apparence</span>
        <span class="affichageBloc full textCenter">- {{malusApparence}}</span>
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[2], -malusApparence)}}</span>
      </div>
      <div class="col-md-3 affichageInline">
        <span class="affichageBloc full textCenter">Force</span>
        <input type="number" min="0" max="{{malusMaxForce}}" class="affichageBloc full textCenter malus" onkeydown="return false" value="{{malusForce}}" (change)="updateMalus($event, caracteristiques[7])">
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[7], -malusForce)}}</span>
      </div>
      <div class="col-md-3 affichageInline">
        <span class="affichageBloc full textCenter">Constitution</span>
        <input type="number" min="0" max="{{malusMaxConstitution}}" class="affichageBloc full textCenter malus" onkeydown="return false" value="{{malusConstitution}}" (click)="updateMalus($event, caracteristiques[1])" >
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[1], -malusConstitution)}}</span>
      </div>
      <div class="col-md-3 affichageInline">
        <span class="affichageBloc full textCenter">Dextérité</span>
        <input type="number" min="0" max="{{malusMaxDexterite}}" class="affichageBloc full textCenter malus" onkeydown="return false" value="{{malusDexterite}}" (click)="updateMalus($event, caracteristiques[0])" >
        <span class="affichageBloc full textCenter resultat">{{calculValeur(caracteristiques[0], -malusDexterite)}}</span>
      </div>
    </div>
    <div *ngIf="age > 39" class="mobile">
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Apparence</span>
        <span class="affichageInline textCenter w-25">- {{malusApparence}}</span>
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[2], -malusApparence)}}</span>
      </div>
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Force</span>
        <input type="text" class="affichageInline textCenter malus w-25" (keyup)="checkNumber($event, 0, malusMaxForce, caracteristiques[7])" value="{{malusForce}}">
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[7], -malusForce)}}</span>
      </div>
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Constitution</span>
        <input type="text" class="affichageInline textCenter malus w-25" (keyup)="checkNumber($event, 0, malusMaxConstitution, caracteristiques[1])" value="{{malusConstitution}}">
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[1], -malusConstitution)}}</span>
      </div>
      <div class="affichageBloc">
        <span class="affichageInline textCenter w-25">Dextérité</span>
        <input type="text" class="affichageInline textCenter malus w-25" (keyup)="checkNumber($event, 0, malusMaxDexterite, caracteristiques[0])" value="{{malusDexterite}}">
        <span class="affichageInline textCenter resultat">{{calculValeur(caracteristiques[0], -malusDexterite)}}</span>
      </div>
    </div>
  </div>
</div>
<div class="col-md-4 affichageInline">
  <img src="assets/images/composants/age.jpg" alt="Age" class="imageAge">
</div>
