import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Faction } from 'src/modules/core/modeles/fiche/commun/faction';
import { QualiteDefaut } from 'src/modules/core/modeles/fiche/commun/qualiteDefaut';

@Injectable({
  providedIn: 'root'
})
export class FactionService {

  private factionSubject= new Subject<Faction>();
  private qualitesSubject= new Subject<QualiteDefaut[]>();
  private defautsSubject= new Subject<QualiteDefaut[]>();
  private atoutsSubject= new Subject<QualiteDefaut[]>();
  private handicapsSubject= new Subject<QualiteDefaut[]>();
  private jokerSubject= new Subject<number>();

  sendFaction(faction: Faction): void {
    this.factionSubject.next(faction);
  }

  getFaction(): Subject<Faction>{
    return this.factionSubject;
  }

  sendQualites(qualites: QualiteDefaut[]): void {
    this.qualitesSubject.next(qualites);
  }

  getQualites(): Subject<QualiteDefaut[]>{
    return this.qualitesSubject;
  }

  sendDefauts(defauts: QualiteDefaut[]): void {
    this.defautsSubject.next(defauts);
  }

  getDefauts(): Subject<QualiteDefaut[]>{
    return this.defautsSubject;
  }

  sendAtouts(atouts: QualiteDefaut[]): void {
    this.atoutsSubject.next(atouts);
  }

  getAtouts(): Subject<QualiteDefaut[]>{
    return this.atoutsSubject;
  }

  sendHandicaps(handicaps: QualiteDefaut[]): void {
    this.handicapsSubject.next(handicaps);
  }

  getHandicaps(): Subject<QualiteDefaut[]>{
    return this.handicapsSubject;
  }

  sendJoker(joker: number): void {
    this.jokerSubject.next(joker);
  }

  getJoker(): Subject<number>{
    return this.jokerSubject;
  }
}
