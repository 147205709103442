import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaracteristiquesComponent } from './caracteristiques/caracteristiques.component';

@NgModule({
  declarations: [CaracteristiquesComponent],
  imports: [
    CommonModule
  ],
  exports: [CaracteristiquesComponent]
})
export class CaracteristiquesModule { }
