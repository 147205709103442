import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  public emailInfos: string;

  constructor() { }

  ngOnInit(): void {
    this.emailInfos = "mailto:decados@decados.fr?Subject=Site";
  }

  copyright(){
    alert("L'ensemble des images utilisées sur ce site sont et restent la propriété de leur auteur et ne sauraient être utilisées sans leur consentement exprès." +
    "\nL'auteur des illustrations de chaque étape du processus de création peut-être contacté par le bouton e-mail du site.");
  }
}
