import { Component, Input, OnInit } from '@angular/core';
import { EtatCivil } from 'src/modules/core/modeles/fiche/commun/etatCivil';
import { Profession } from 'src/modules/core/modeles/fiche/profession/profession';
import { Race } from 'src/modules/core/modeles/fiche/race/race';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { LoadDataService } from 'src/services/loadData.service';
import { EtatCivilService } from '../services/etat-civil.service';

@Component({
  selector: 'app-etat-civil',
  templateUrl: './etat-civil.component.html',
  styleUrls: ['./etat-civil.component.css']
})
export class EtatCivilComponent implements OnInit {

  @Input() jeu: Jeu;
  @Input() etatCivil: EtatCivil;
  @Input() regles: Map<string,string>;

  public sexes: string[] = ["Homme","Femme"];
  public races: Race[] = [];
  public professions: Profession[] = [];

  donneesChargees: Promise<boolean>;

  constructor(private loadDataService: LoadDataService, private etatCivilService: EtatCivilService) { }

  ngOnInit(): void {
    if(this.jeu.raceSelectionnable){
      this.chargementRace(this.jeu.acronyme);
    }

    if(this.jeu.professionSelectionnable){
      this.chargementProfession(this.jeu.acronyme);
    }
  }

  launchUpload(event) {
    let btn: HTMLElement = document.getElementById("uploadBtn") as HTMLElement;
    btn.click();
  }

  selectAvatar(event): void{

    if (event.target.files && event.target.files[0]) {

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.etatCivil.avatar = event.target.result.toString();
      }
    }
  }

  chargementProfession(acronyme){
    if(this.jeu.professionSelectionnable){
      var  fichierProfessions = '../assets/professions/' + acronyme + '/' + acronyme + '.json';
      this.loadDataService.getByAcronyme(fichierProfessions).subscribe(data => {
        this.professions = data.professions;
        this.donneesChargees = Promise.resolve(true);
      });
    }
  }

  chargementRace(acronyme){
    if(this.jeu.raceSelectionnable){
      var  fichierRaces = '../assets/races/' + acronyme + '/' + acronyme + '.json';
      this.loadDataService.getByAcronyme(fichierRaces).subscribe(data => {
        this.races = data.races;
        this.donneesChargees = Promise.resolve(true);
      });
    }
  }

  onOptionsRaceSelected(race: string){
    if(race){
      this.etatCivilService.sendRaceSelectionnee(race);
    }
  }

  onOptionsProfessionSelected(profession: string){
    if(profession){
      this.etatCivilService.sendProfessionSelectionnee(profession);
    }
  }

  validateNumberKey(event): boolean{
    const regex = /^\d*\.?\d*$/;
    if (event.key.match(regex) || event.key == "Backspace" || event.key == "Delete" || event.key == "ArrowLeft" || event.key == "ArrowRight" || event.key == "Tab") {
      return true;
    } else {
      return false;
    }
  }

  bornageTaille(){

    if(this.regles.size == 0){
      return;
    }

    const tailleMin = this.regles.get("tailleMin");
    if(tailleMin != null && tailleMin != undefined && tailleMin != "0" && this.etatCivil.taille < Number(tailleMin)){
      this.etatCivil.taille = Number(tailleMin);
    }

    const tailleMax = this.regles.get("tailleMax");
    if(tailleMax != null && tailleMax != undefined && tailleMax != "0" && this.etatCivil.taille > Number(tailleMax)){
      this.etatCivil.taille = Number(tailleMax);
    }
  }

  bornagePoids(){

    if(this.regles.size == 0){
      return;
    }

    const poidsMin = this.regles.get("poidsMin");
    if(poidsMin != null && poidsMin != undefined && poidsMin != "0" && this.etatCivil.poids < Number(poidsMin)){
      this.etatCivil.taille = Number(poidsMin);
    }

    const poidsMax = this.regles.get("poidsMax");
    if(poidsMax != null && poidsMax != undefined && poidsMax != "0" && this.etatCivil.poids > Number(poidsMax)){
      this.etatCivil.taille = Number(poidsMax);
    }
  }
}
