import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/modules/shared/shared.module';
import { StaComponent } from './sta/sta.component';

@NgModule({
  declarations: [StaComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [StaComponent]
})
export class StarwarsModule { }
