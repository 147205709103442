import { Injectable } from '@angular/core';
import { FicheAdc } from 'src/modules/core/modeles/fiche/fichePersonnage/ficheAdc';
import { Profession } from 'src/modules/core/modeles/fiche/profession/profession';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { Des } from 'src/modules/core/utilitaires/des';
import { GenerationHelper } from 'src/modules/core/utilitaires/generationHelper';
import { LoadDataService } from 'src/services/loadData.service';
import { AdcComponent } from '../adc/adc.component';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';
import { Competence } from 'src/modules/core/modeles/fiche/commun/competence';

@Injectable({
  providedIn: 'root'
})
export class AdcAleatoireService {

  private professions: Profession[] = [];

  constructor(private fiche: FicheAdc, private jeu: Jeu, private loadDataService: LoadDataService, private adcComponent: AdcComponent) { }

  generationAleatoire(professions: Profession[]) {
    this.professions = professions;
    this.etatCivil();
  }

  etatCivil() {

    if (this.professions && this.professions.length > 0) {

      const sexe = Des.aleatoire(1, 2);
      const poidsMin = 40;
      const poidsMax = 130;
      const tailleMin = 120;
      const tailleMax = 200;

      if (sexe === 1) {
        this.fiche.etatCivil.sexe = "Homme";
        this.fiche.etatCivil.prenom = this.adcComponent.prenomMasculins[Des.aleatoire(1, this.adcComponent.prenomMasculins.length)];
        if (tailleMin && tailleMax) {
          this.fiche.etatCivil.taille = Des.aleatoire(Number(tailleMin), Number(tailleMax));
        } else {
          this.fiche.etatCivil.taille = Des.aleatoire(140, 200);
        }
        if (poidsMin && poidsMax) {
          this.fiche.etatCivil.poids = Des.aleatoire(Number(poidsMin), Number(poidsMax));
        } else {
          this.fiche.etatCivil.poids = 40 + Des.aleatoire(20, 50);
        }
      } else {
        this.fiche.etatCivil.sexe = "Femme";
        this.fiche.etatCivil.prenom = this.adcComponent.prenomFeminins[Des.aleatoire(1, this.adcComponent.prenomFeminins.length)];
        if (tailleMin && tailleMax) {
          this.fiche.etatCivil.taille = Des.aleatoire(Number(tailleMin), Number(tailleMax));
        } else {
          this.fiche.etatCivil.taille = Des.aleatoire(130, 180);
        }
        if (poidsMin && poidsMax) {
          this.fiche.etatCivil.poids = Des.aleatoire(Number(poidsMin), Number(poidsMax));
        } else {
          this.fiche.etatCivil.poids = 40 + Des.aleatoire(20, 40);
        }
      }

      this.fiche.etatCivil.nom = GenerationHelper.creerUnNom(Des.aleatoire(4, 12));
      const pyramideAge = Des.aleatoire(0, 100);

      if (pyramideAge < 25) {
        this.fiche.etatCivil.age = Des.aleatoire(16, 19);
      } else if (pyramideAge > 25 && pyramideAge < 59) {
        this.fiche.etatCivil.age = Des.aleatoire(20, 39);
      } else if (pyramideAge > 59 && pyramideAge < 71) {
        this.fiche.etatCivil.age = Des.aleatoire(40, 49);
      } else if (pyramideAge > 71 && pyramideAge < 82) {
        this.fiche.etatCivil.age = Des.aleatoire(50, 59);
      } else if (pyramideAge > 82 && pyramideAge < 89) {
        this.fiche.etatCivil.age = Des.aleatoire(60, 69);
      } else if (pyramideAge > 89 && pyramideAge < 95) {
        this.fiche.etatCivil.age = Des.aleatoire(70, 79);
      } else if (pyramideAge > 95) {
        this.fiche.etatCivil.age = Des.aleatoire(80, 89);
      }

      this.chargementProfession();
    }
  }

  chargementProfession() {
    const des = Des.aleatoire(0, this.professions.length - 1);
    const profession = this.professions[des];
    this.fiche.etatCivil.profession = profession.nom;
    const fichierProfession = '../assets/professions/' + this.jeu.acronyme + '/' + profession.nom.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '.json';

    this.loadDataService.getByAcronyme(fichierProfession).subscribe(data => {
      this.fiche.profession = data.profession[0];
      this.fiche.equipement = data.profession[0].equipement;
      this.fiche.nombreCompentencesChoix = data.profession[0].nombreCompentencesChoix;
      this.fiche.pointsOccupation = data.profession[0].pointsOccupation;
      this.adcComponent.generationCompetencePro(data.profession[0].competences);
      this.adcComponent.generationChoixCompetence(data.profession[0].competencesChoix);
      this.adcComponent.generationRegles();
      this.equipement();
      this.divers();
      this.caracteristiques();
    });
  }

  equipement() {
    if(!this.fiche.equipement){
      this.fiche.equipement = new Equipement();
      this.fiche.equipement.description = "";
    }
  }

  divers() {
    this.fiche.divers = new Divers();
    this.fiche.divers.tableauDivers = new Map<string,any>();

    if (this.jeu.divers) {
      this.jeu.divers.forEach(inputText => {
        this.fiche.divers.tableauDivers.set(inputText, "");
      });
    }
  }

  caracteristiques() {
    this.fiche.caracteristiques.forEach(caracteristique => {
      caracteristique.valeur = Des.lancer(caracteristique.typeDes, caracteristique.nombreDes, caracteristique.seuilRelance, caracteristique.bonusDes, caracteristique.multiplicateur);
    });
    this.parcoursVie();
  }

  parcoursVie() {

    let malusCommun = 0;
    let malusApparence = 0;

    if (this.fiche.etatCivil.age < 20) {
      malusCommun = 5;
      malusApparence = 0;
    } else if (this.fiche.etatCivil.age > 19 && this.fiche.etatCivil.age < 40) {
      malusApparence = 0;
      malusCommun = 0;
    } else if (this.fiche.etatCivil.age > 39 && this.fiche.etatCivil.age < 50) {
      malusApparence = 5;
      malusCommun = 5;
    } else if (this.fiche.etatCivil.age > 49 && this.fiche.etatCivil.age < 60) {
      malusApparence = 10;
      malusCommun = 10;
    } else if (this.fiche.etatCivil.age > 59 && this.fiche.etatCivil.age < 70) {
      malusApparence = 15;
      malusCommun = 20;
    } else if (this.fiche.etatCivil.age > 69 && this.fiche.etatCivil.age < 80) {
      malusApparence = 20;
      malusCommun = 40;
    } else if (this.fiche.etatCivil.age > 79) {
      malusApparence = 25;
      malusCommun = 80;
    }

    this.fiche.malus.set("Apparence", malusApparence);
    this.ventilationMalus(malusCommun);
    this.competences();
  }

  ventilationMalus(malusCommun: number) {

    let malusForce = 0;
    let malusConstitution = 0;
    let malusDexterite = 0;
    let malusTaille = 0;
    let malusEducation = 0;

    if (this.fiche.etatCivil.age < 20) {
      malusEducation = 5
    }

    for (let i = 1; i <= malusCommun; i++) {
      const indexCarac = Des.aleatoire(1, 3);

      switch (indexCarac) {
        case 1:
          malusForce += 1;
          break;
        case 2:
          if (this.fiche.etatCivil.age < 20) {
            malusTaille += 1;
          } else {
            malusConstitution += 1;
          }
          break;
        case 3:
          if (this.fiche.etatCivil.age < 20) {
            malusTaille += 1;
          } else {
            malusDexterite += 1;
          }
          break;
        default:
          break;
      }
    }

    this.adcComponent.malus.set("Force", malusForce);
    this.adcComponent.malus.set("Constitution", malusConstitution);
    this.adcComponent.malus.set("Dexterite", malusDexterite);
    this.adcComponent.malus.set("Taille", malusTaille);
    this.adcComponent.malus.set("Education", malusEducation);
    this.adcComponent.malusDone = true;
  }

  competences() {

    let ptCompetences = (this.fiche.caracteristiques.filter(carac => carac.nom == "Intelligence")[0].valeur * 2);
    let ptCompetencesPro = this.adcComponent.calculPointsCompetencesPro();
    this.adcComponent.actualisationEsquive();

    if (this.fiche && this.fiche.competencesPro && this.fiche.competencesPro.length > 0 && this.fiche.competences.length > 0) {

      let nbCompAVentiler = Math.ceil(ptCompetencesPro / 90) + 1;
      if(nbCompAVentiler > this.fiche.competencesPro.length){
        nbCompAVentiler = this.fiche.competencesPro.length
      }

      let nombreCompentencesChoix = this.fiche.competencesAuChoix.length;

      for(let index = 1; index <= nbCompAVentiler; index++){
        const choixListe = Des.aleatoire(1, 5);
        let competence;

        if (nombreCompentencesChoix > 0 && choixListe == 2 && ptCompetencesPro > 0) {
          competence = this.fiche.competencesAuChoix[Des.aleatoire(0, this.fiche.competencesAuChoix.length - 1)];
          nombreCompentencesChoix -= 1;
        } else if (ptCompetencesPro > 0) {
          competence = this.fiche.competencesPro[Des.aleatoire(0, this.fiche.competencesPro.length - 1)];
        }

        if(competence){
          ptCompetencesPro = this.depenseMaxPts(competence, ptCompetencesPro, this.adcComponent.ptDepensesPro);
        }
      }
      this.adcComponent.actualisationPtDepensesPro(this.adcComponent.ptDepensesPro);

      nbCompAVentiler = Math.ceil(ptCompetences / 90) + 1;

      if(nbCompAVentiler > this.fiche.competences.length){
        nbCompAVentiler = this.fiche.competences.length
      }

      for(let index=1; index <=nbCompAVentiler; index++){
        if(ptCompetences > 0){
          const competence = this.fiche.competences[Des.aleatoire(0, this.fiche.competences.length - 1)];
          ptCompetences = this.depenseMaxPts(competence, ptCompetences, this.adcComponent.ptDepensesGeneral);
        }
      }

      this.adcComponent.actualisationPtDepenses(this.adcComponent.ptDepensesGeneral);
      this.adcComponent.pointsCompetences = 0;
      this.adcComponent.pointsCompetencesPro = 0;
    }
  }

  depenseMaxPts(competence: Competence, ptDisponible: number, competencesDepenses: Map<string, number>): number{
    if (!competence || competence.valeur >= 90) {
      return;
    }

    // On maximise à 90
    if(ptDisponible >= 90 - competence.valeur){
      competence.valeur = 90;
      ptDisponible -= (90 - competence.valeurMin);
    } else {
      competence.valeur += ptDisponible;
      ptDisponible = 0;
    }

    competencesDepenses.set(competence.nom, competence.valeur)

    return ptDisponible;
  }
}
