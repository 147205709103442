<div *ngIf="etatCivil" class="displayElement col-md-8">
  <div class="displayBlock col-md-12">
    <div class="col-md-3 displayElement verticalTop">
      <div class="form-group displayElement cursor noSelect titre">
        <img *ngIf="etatCivil.avatar == undefined" src="assets/images/defaultAvatar.jpg" class="rounded img-thumbnail avatar" alt="Vous" (click)="launchUpload($event)">
        <img *ngIf="etatCivil.avatar != undefined" [src]="etatCivil.avatar" class="rounded img-thumbnail avatar" alt="Vous" (click)="launchUpload($event)">
        <input type="file" id="uploadBtn" style="display:none" (change)="selectAvatar($event)">
        <span class="tooltiptext onRight noSelect">Cliquez pour sélectionner une image représentant votre personnage</span>
      </div>
    </div>
    <div class="col-md-9 displayElement">
      <div class="col-md-12 form-group displayBlock">
        <div class="noSelect titre">
          <label for="labelNom">Nom</label>
          <span class="tooltiptext onRight noSelect">Le nom de votre personnage</span>
        </div>
        <input tabindex="1" type="text" class="form-control" id="inputNom" name="inputNom" placeholder="Nom du personnage" [(ngModel)]="etatCivil.nom" required #inputNom="ngModel">
        <div *ngIf="inputNom.invalid && (inputNom.dirty || inputNom.touched)" class="alert alert-danger">
          <div *ngIf="inputNom.errors.required">Merci de renseigner un nom</div>
        </div>
      </div>
      <div class="col-md-12 form-group displayBlock">
        <div class="noSelect titre">
          <label for="labelPrenom">Prénom</label>
          <span class="tooltiptext onRight noSelect">Le prénom de votre personnage</span>
        </div>
        <input tabindex="2" type="text" class="form-control" id="inputPrenom" placeholder="Prénom du personnage" [(ngModel)]="etatCivil.prenom">
      </div>
    </div>
  </div>
  <div class="displayBlock col-md-12">
      <div *ngIf="jeu.raceSelectionnable" class="form-group col-md-6 displayElement">
        <div class="noSelect titre">
          <label for="labelRace">Race</label>
          <span [ngClass]="etatCivil.race != null && etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="info">?</span>
          <span [ngClass]="etatCivil.race != null && etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="tooltiptext onRight noSelect description">
            {{regles.get("raceDescription")}}
            <img src="{{regles.get('raceImage')}}" class="imageChargee" alt="Représentation de votre race"/>
          </span>
          <span [ngClass]="etatCivil.race != null && etatCivil.race != '' ? 'cacheMoi' : 'montreMoi'" class="tooltiptext onRight noSelect">Sélectionnez une race pour en avoir une description</span>
        </div>
        <select tabindex="3" #maRace *ngIf="donneesChargees | async" type="text" class="form-control" id="inputRace" placeholder="Race" [(ngModel)]="etatCivil.race" (change)="onOptionsRaceSelected(maRace.value)" required>
          <option></option>
          <option *ngFor="let race of races">{{race.nom}}</option>
        </select>
      </div>
      <div *ngIf="!jeu.raceSelectionnable" class="form-group col-md-6 displayElement">
          <label for="labelRace">Race</label>
          <input type="text" class="form-control disabled" id="inputRace" disabled="true">
      </div>
    <div class="form-group col-md-3 displayElement">
      <div class="noSelect titre">
        <label for="labelSexe">Sexe</label>
        <span class="tooltiptext onRight noSelect">Le sexe de votre personnage, pas sa taille ...</span>
      </div>
      <select  tabindex="4" type="text" class="form-control" id="inputSexe" placeholder="Sexe" [(ngModel)]="etatCivil.sexe">
        <option></option>
        <option *ngFor="let sexe of sexes">{{sexe}}</option>
      </select>
    </div>
    <div class="form-group col-md-3 displayElement">
      <div class="noSelect titre">
        <label for="labelAge">Age</label>
        <span class="tooltiptext onRight noSelect">L'âge de votre personnage</span>
      </div>
      <input *ngIf="jeu.ageLibre" tabindex="5" type="text" class="form-control textCenter" id="inputAge" (keydown)="validateNumberKey($event)" maxlength="3" placeholder="000" [(ngModel)]="etatCivil.age">
      <input *ngIf="!jeu.ageLibre" tabindex="5" type="text" class="form-control textCenter disabled" id="inputAge" disabled="true">
    </div>
  </div>
  <div class="displayBlock col-md-12">
    <div *ngIf="jeu.professionSelectionnable" class="form-group col-md-8 displayElement">
      <div class="noSelect titre">
        <label for="labelProfession">Profession</label>
        <span [ngClass]="etatCivil.profession != null && etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="info">?</span>
        <span [ngClass]="etatCivil.profession != null && etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="tooltiptext onRight noSelect description">
          {{regles.get("professionDescription")}}
          <img src="{{regles.get('profesionImage')}}" class="imageChargee" alt="Représentation de votre profession"/>
        </span>
        <span [ngClass]="etatCivil.profession != null && etatCivil.profession != '' ? 'cacheMoi' : 'montreMoi'" class="tooltiptext onRight noSelect">Sélectionnez la profession de votre personnage</span>
      </div>
      <select tabindex="6" #maProfession *ngIf="donneesChargees | async" type="text" class="form-control" id="inputProfession" placeholder="Profession" [(ngModel)]="etatCivil.profession" required (change)='onOptionsProfessionSelected(maProfession.value)' required>
        <option></option>
        <option *ngFor="let profession of professions">{{profession.nom}}</option>
      </select>
    </div>
    <div *ngIf="!jeu.professionSelectionnable" class="form-group col-md-8 displayElement">
      <div class="noSelect titre">
        <label for="labelProfession">Profession</label>
        <span class="tooltiptext onRight noSelect">La profession de votre personnage</span>
      </div>
      <input tabindex="6" type="text" class="form-control" id="inputProfession" placeholder="Profession du personnage" [(ngModel)]="etatCivil.profession">
    </div>
    <div class="form-group col-md-2 displayElement ">
      <div class="noSelect titre">
        <label for="labelTaille">Taille (cm)</label>
        <span class="tooltiptext onRight noSelect">La taille de votre personnage, peut dépendre de la race</span>
      </div>
      <input tabindex="7" type="text" class="form-control textCenter" id="inputTaille" (keydown)="validateNumberKey($event)" (focusout)="bornageTaille()" maxlength="3" placeholder="000" [(ngModel)]="etatCivil.taille">
    </div>
    <div class="form-group col-md-2 displayElement">
      <div class="noSelect titre">
        <label for="labelPoids">Poids (kg)</label>
        <span class="tooltiptext onRight noSelect">Le poids de votre personnage, peut dépendre de la race</span>
      </div>
      <input tabindex="8" type="text" class="form-control textCenter" id="inputPoids" (keydown)="validateNumberKey($event)" (focusout)="bornagePoids()" maxlength="3" placeholder="000" [(ngModel)]="etatCivil.poids">
    </div>
  </div>
  <div class="displayBlock col-md-12">
    <div class="input-group col-md-12">
      <div class="input-group-prepend noSelect titre">
        <span class="input-group-text">Description</span>
        <span class="tooltiptext onRight noSelect">La description de votre personnage, un peu d'imagination diantre !</span>
      </div>
      <textarea tabindex="9" class="form-control textAreaSize" id="inputDescription" aria-label="With textarea" [(ngModel)]="etatCivil.description"></textarea>
    </div>
  </div>
</div>
<div *ngIf="etatCivil" class="displayElement col-md-4 verticalBottom">
  <img src="assets/images/composants/etatCivil.jpg" alt="Etat civil" class="imageEtatCivil">
</div>
