import { Competence } from "src/modules/core/modeles/fiche/commun/competence";
import { FicheSta } from "src/modules/core/modeles/fiche/fichePersonnage/ficheSta";
import { GenerationHelper } from "src/modules/core/utilitaires/generationHelper";

export class StaGeneration {

  private ficheGeneration: string[][] = [];
  private fiche: FicheSta;

  constructor(fiche: FicheSta) {
    this.fiche = fiche;
  }

  generation(): string[][] {

    this.ficheGeneration[0] = [];
    this.ficheGeneration[0].push("nom#" + GenerationHelper.safeReturn(this.fiche.etatCivil.nom).substring(0, 17));
    this.ficheGeneration[0].push("prenom#" + GenerationHelper.safeReturn(this.fiche.etatCivil.prenom).substring(0, 17));
    let nomFiche = GenerationHelper.safeReturn(GenerationHelper.safeReturn(this.fiche.etatCivil.nom) + " " + GenerationHelper.safeReturn(this.fiche.etatCivil.prenom));
    if (nomFiche.length <= 1) {
      nomFiche = "FichePersonnageStarWars";
    }
    this.ficheGeneration[0].push("nomFiche#" + nomFiche);
    this.ficheGeneration[0].push("sexe#" + GenerationHelper.safeReturn(this.fiche.etatCivil.sexe) + " - ");
    this.ficheGeneration[0].push("taille#" + GenerationHelper.safeReturn(this.fiche.etatCivil.taille));
    this.ficheGeneration[0].push("race#" + GenerationHelper.safeReturn(this.fiche.etatCivil.race));
    this.ficheGeneration[0].push("age#" + GenerationHelper.safeReturn(this.fiche.etatCivil.age));
    this.ficheGeneration[0].push("poids#" + GenerationHelper.safeReturn(this.fiche.etatCivil.poids));
    this.ficheGeneration[0].push("profession#" + GenerationHelper.safeReturn(this.fiche.etatCivil.profession).substring(0, 50));
    this.ficheGeneration[0].push("description1#" + GenerationHelper.safeReturn(this.fiche.etatCivil.description).substring(0, 40));
    this.ficheGeneration[0].push("description2#" + GenerationHelper.safeReturn(this.fiche.etatCivil.description).substring(40, 70).trim());
    this.ficheGeneration[0].push("description3#" + GenerationHelper.safeReturn(this.fiche.etatCivil.description).substring(110, 70).trim());
    this.ficheGeneration[0].push("description4#" + GenerationHelper.safeReturn(this.fiche.etatCivil.description).substring(180, 70).trim());

    // avatar : width 427 / height 622
    if (GenerationHelper.safeReturn(this.fiche.etatCivil.avatar).length == 0) {
      this.ficheGeneration[0].push("avatar#427&622!assets/images/races/Sta/" + this.fiche.race.nom + ".JPG");
    } else {
      this.ficheGeneration[0].push("avatar#427&622!" + GenerationHelper.safeReturn(this.fiche.etatCivil.avatar));
    }

    this.ficheGeneration[0].push("equipement1#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(0, 39));
    this.ficheGeneration[0].push("equipement2#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(39, 55).trim());
    this.ficheGeneration[0].push("equipement3#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(94, 55).trim());
    this.ficheGeneration[0].push("equipement4#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(149, 55).trim());
    this.ficheGeneration[0].push("equipement5#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(204, 55).trim());
    this.ficheGeneration[0].push("equipement6#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(259, 55).trim());
    this.ficheGeneration[0].push("dexterite#" + GenerationHelper.getD6Value(this.fiche.caracteristiques[0].valeur));
    this.ficheGeneration[0].push("perception#" + GenerationHelper.getD6Value(this.fiche.caracteristiques[1].valeur));
    this.ficheGeneration[0].push("savoir#" + GenerationHelper.getD6Value(this.fiche.caracteristiques[2].valeur));
    this.ficheGeneration[0].push("vigueur#" + GenerationHelper.getD6Value(this.fiche.caracteristiques[3].valeur));
    this.ficheGeneration[0].push("mecanique#" + GenerationHelper.getD6Value(this.fiche.caracteristiques[4].valeur));
    this.ficheGeneration[0].push("technique#" + GenerationHelper.getD6Value(this.fiche.caracteristiques[5].valeur));
    this.ficheGeneration[0].push("ptPerso#" + GenerationHelper.safeReturn(this.fiche.race.ptPerso));
    this.ficheGeneration[0].push("mouvement#" + GenerationHelper.safeReturn(this.fiche.race.mouvement));
    this.ficheGeneration[0].push("obscur#" + GenerationHelper.safeReturn(this.fiche.race.obscur));
    this.ficheGeneration[0].push("presentation#" + GenerationHelper.safeReturn("Origine : " + this.fiche.race.planete));

    if (this.fiche.divers != null && this.fiche.divers != undefined) {

      this.fiche.divers.tableauDivers.forEach((value: string, key: string) => {

        const nom = key.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
        let max = 0;
        let ligne1 = 0;

        switch (nom) {
          case "presentation":
            ligne1 = 60
            max = 6;
            break;
          case "personnalite":
            ligne1 = 40;
            max = 7;
            break;
          case "relations":
            ligne1 = 60;
            max = 4;
            break;
          case "objectifs":
            ligne1 = 43;
            max = 5;
            break;
          case "citation":
            ligne1 = 43
            max = 5;
            break;
          default:
            break;
        }

        for (let i = 1; i <= max; i++) {

          if (i > 1) {
            this.ficheGeneration[0].push(nom + i + "#" + GenerationHelper.safeReturn(value).substring((i * 60) - 60 - (60 - ligne1), 60).trim());
            ligne1 = 60;
          } else {
            this.ficheGeneration[0].push(nom + i + "#" + GenerationHelper.safeReturn(value).substring((i * 60) - 60, ligne1).trim());
          }
        }
      })
    }

    if (this.fiche.race.sensibilite) {
      this.ficheGeneration[0].push("sensibilite#Oui");
      this.ficheGeneration[0].push("force#" + GenerationHelper.safeReturn(this.fiche.pouvoirsForce != null && this.fiche.pouvoirsForce != undefined && this.fiche.pouvoirsForce.length > 1 ? this.fiche.race.force : 2));
    } else {
      this.ficheGeneration[0].push("sensibilite#Non");
      this.ficheGeneration[0].push("force#" + 1);
    }

    let index = 1;

    if (this.fiche.race.sensibilite && this.fiche.caracteristiquesForce[0].valeur > 0
      || this.fiche.caracteristiquesForce[1].valeur > 0
      || this.fiche.caracteristiquesForce[2].valeur > 0) {

      this.ficheGeneration[0].push("controle#" + "Contrôle : " + GenerationHelper.getD6Value(this.fiche.caracteristiquesForce[0].valeur));
      this.ficheGeneration[0].push("sens#" + "Sens : " + GenerationHelper.getD6Value(this.fiche.caracteristiquesForce[1].valeur));
      this.ficheGeneration[0].push("alteration#" + "Altération : " + GenerationHelper.getD6Value(this.fiche.caracteristiquesForce[2].valeur));

      index += 3;
      if (this.fiche.pouvoirsForce != null && this.fiche.pouvoirsForce != undefined) {
        this.fiche.pouvoirsForce.forEach(pouvoir => {
          this.ficheGeneration[0].push("capacites" + index + "#" + GenerationHelper.safeReturn(pouvoir.nom).substring(0, 28));
          index += 1;
        });
      }

      for (let i = index; i <= 10; i++) {
        const indexDebut = ((i - index + 1) * 28) - 28;
        this.ficheGeneration[0].push("capacites" + i + "#" + GenerationHelper.safeReturn(this.fiche.race.capacites).substring(indexDebut, indexDebut + 28));
      }

    } else {
      if (index < 10) {
        for (let i = index; i <= 10; i++) {
          const indexDebut = (i * 28) - 28;
          this.ficheGeneration[0].push("capacites" + i + "#" + GenerationHelper.safeReturn(this.fiche.race.capacites).substring(indexDebut, indexDebut + 28));
        }
      }
    }

    // Max 9 compétences de chaque type de caractéristique
    let dex: number = 1; let per: number = 1; let sav: number = 1; let vig: number = 1; let mec: number = 1; let tec: number = 1;
    this.fiche.competences.forEach(competence => {

      switch (competence.caracteristique) {
        case "Dexterite":
          if (dex < 10 && !isNaN(competence.valeur)) {
            this.generateCompParCarac(competence, dex, this.fiche.caracteristiques[0].valeur);
            dex += 1;
          }
          break;
        case "Perception":
          if (per < 10 && !isNaN(competence.valeur)) {
            this.generateCompParCarac(competence, per, this.fiche.caracteristiques[1].valeur);
            per += 1;
          }
          break;
        case "Savoir":
          if (sav < 10 && !isNaN(competence.valeur)) {
            this.generateCompParCarac(competence, sav, this.fiche.caracteristiques[2].valeur);
            sav += 1;
          }
          break;
        case "Vigueur":
          if (vig < 10 && !isNaN(competence.valeur)) {
            this.generateCompParCarac(competence, vig, this.fiche.caracteristiques[3].valeur);
            vig += 1;
          }
          break;
        case "Mecanique":
          if (mec < 10 && !isNaN(competence.valeur)) {
            this.generateCompParCarac(competence, mec, this.fiche.caracteristiques[4].valeur);
            mec += 1;
          }
          break;
        case "Technique":
          if (tec < 10 && !isNaN(competence.valeur)) {
            this.generateCompParCarac(competence, tec, this.fiche.caracteristiques[5].valeur);
            tec += 1;
          }
          break;
        default:
          break;
      }
    });

    return this.ficheGeneration;
  }

  generateCompParCarac(competence: Competence, index: number, valeurCarateristique: number): void {
    this.ficheGeneration[0].push(competence.caracteristique.toLowerCase() + String(index) + "#" + competence.nom.substring(0, 22) + " : " + GenerationHelper.getD6Value(competence.valeur + valeurCarateristique));
  }
}
