import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CthulhuModule } from './cthulhu/cthulhu.module';
import { StarwarsModule } from './starwars/starwars.module';
import { FadingsunsModule } from './fadingsuns/fadingsuns.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CthulhuModule,
    StarwarsModule,
    FadingsunsModule
  ]
})
export class JeuxModule { }
