import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompetencesService {

  private ptRestantSubject= new Subject<Map<string, number>>();
  private ptDepensesSubject= new Subject<Map<string,Map<string, number>>>();

  sendPtRestant(ptRestant: Map<string, number>): void {
    this.ptRestantSubject.next(ptRestant);
  }

  getPtRestant(): Subject<Map<string, number>>{
    return this.ptRestantSubject;
  }

  sendPtDepenses(ptDepenses: Map<string,Map<string, number>>): void {
    this.ptDepensesSubject.next(ptDepenses);
  }

  getPtDepenses(): Subject<Map<string,Map<string, number>>>{
    return this.ptDepensesSubject;
  }
}
