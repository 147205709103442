<div *ngIf="jeu" class="marginTopCommon displayCommon">
  <app-time-line [jeu]="jeu" [selectedIndex]="selectedIndex"></app-time-line>
</div>
<div *ngIf="selectedIndex === 0" class="marginTopCommon displayCommon">
    <app-etat-civil [jeu]="jeu" [etatCivil]="fiche.etatCivil" [regles]="regles"></app-etat-civil>
</div>
<div *ngIf="fiche.caracteristiques != undefined" [ngClass]="selectedIndex === 1 && fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-caracteristiques *ngIf="fiche.caracteristiques != undefined && fiche.etatCivil.profession != null" [caracteristiques]="fiche.caracteristiques" [seuil]="0" [afficherIllustration]="true"></app-caracteristiques>
</div>
<div [ngClass]="fiche.caracteristiques[6].valeur > 0 && selectedIndex === 2 && fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-parcours-vie [resetMalus]="resetMalus" [malus]="fiche.malus" [age]="fiche.etatCivil.age" [caracteristiques]="fiche.caracteristiques"></app-parcours-vie>
</div>
<div *ngIf="fiche.competencesPro != undefined" [ngClass]="selectedIndex === 3 && fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' && malusDone ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-competences *ngIf="fiche.competencesPro != undefined && malusDone" [maxCompAvantScroll]="6" [ptCompetences]="pointsCompetencesPro" [toutAfficher]="true" [systemeDeJeu]="'D100'" [cumul]="false" [competences]="fiche.competencesPro" [afficherIllustration]="true" [identifiant]="'competencesPro'" class="d-inline-block"></app-competences>
  <app-competences class="competencesAuChoix" *ngIf="fiche.competencesAuChoix != undefined && fiche.nombreCompentencesChoix > 0 && malusDone" [maxCompAvantScroll]="2" [ptCompetences]="pointsCompetencesPro" [affichagePoints]="false" [nombreMaxChoixCompetences]="fiche.nombreCompentencesChoix" [toutAfficher]="false" [systemeDeJeu]="'D100'" [cumul]="false" [competences]="fiche.competencesAuChoix" [identifiant]="'competencesAuChoix'"></app-competences>
</div>
<div *ngIf="fiche.competences != undefined" [ngClass]="selectedIndex === 4 && fiche.etatCivil.profession != null && fiche.etatCivil.profession.nom != '' && malusDone ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-competences *ngIf="fiche.competences != undefined && malusDone" [ptCompetences]="pointsCompetences" [toutAfficher]="true" [systemeDeJeu]="'D100'" [cumul]="false" [competences]="fiche.competences" [afficherIllustration]="true"></app-competences>
</div>
<div [ngClass]="selectedIndex === 5 && fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-equipements *ngIf="selectedIndex === 5" [equipement]="fiche.equipement"></app-equipements>
</div>
<div [ngClass]="selectedIndex === 6 && fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-divers *ngIf="fiche.etatCivil.profession != null && fiche.etatCivil.profession != ''" [divers]="fiche.divers"></app-divers>
</div>
<div [ngClass]="selectedIndex === 7 && fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-generation *ngIf="selectedIndex === 7" [ficheValeurs]="ficheGeneration" [jeu]="jeu"></app-generation>
</div>
<div class="footerTemplate" [ngClass]="fiche.etatCivil.profession != null && fiche.etatCivil.profession != '' ? 'montreMoi' : 'cacheMoi'">
  <app-footer [selectedIndex]="selectedIndex" [etapeMax]="jeu.nbEtapes"></app-footer>
</div>
