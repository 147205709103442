<div *ngIf="jeu" class="marginTopCommon displayCommon">
  <app-time-line [jeu]="jeu" [selectedIndex]="selectedIndex"></app-time-line>
</div>
<div [ngClass]="selectedIndex === 0 ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-etat-civil [jeu]="jeu" [etatCivil]="fiche.etatCivil" [regles]="regles"></app-etat-civil>
</div>
<div *ngIf="fiche.caracteristiques != undefined" [ngClass]="selectedIndex === 1 && fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-caracteristiques *ngIf="fiche.caracteristiques != undefined && selectedIndex === 1" [seuil]="fiche.race.ptCarac" [ptCaracteristiques]="ptRestantCarac" [D6]="true" [caracteristiques]="fiche.caracteristiques" [afficherIllustration]="true"></app-caracteristiques>
</div>
<div *ngIf="fiche.caracteristiques != undefined" [ngClass]="selectedIndex === 2 && fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-force *ngIf="fiche.caracteristiques != undefined && selectedIndex === 2" [seuil]="fiche.race.ptCarac" [ptCaracteristiques]="ptRestantCarac" [caracteristiques]="fiche.caracteristiquesForce" [sensible]="fiche.race.sensibilite" [pouvoirsForce]="fiche.pouvoirsForce"></app-force>
</div>
<div *ngIf="fiche.competences != undefined" [ngClass]="selectedIndex === 3 && fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-competences *ngIf="fiche.competences != undefined" [caracteristiques]="fiche.caracteristiques" [ptCompetences]="fiche.race.ptComp" [toutAfficher]="false" [systemeDeJeu]="'D6'" [cumul]="true" [competences]="fiche.competences" [afficherIllustration]="true"></app-competences>
</div>
<div [ngClass]="selectedIndex === 4 && fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-equipements *ngIf="selectedIndex === 4" [equipement]="fiche.equipement"></app-equipements>
</div>
<div [ngClass]="selectedIndex === 5 && fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-divers [divers]="fiche.divers"></app-divers>
</div>
<div [ngClass]="selectedIndex === 6 && fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'" class="marginTopCommon displayCommon">
  <app-generation *ngIf="selectedIndex === 6" [ficheValeurs]="ficheGeneration" [jeu]="jeu"></app-generation>
</div>
<div class="footerTemplate" [ngClass]="fiche.etatCivil.race != null && fiche.etatCivil.race != '' ? 'montreMoi' : 'cacheMoi'">
  <app-footer [selectedIndex]="selectedIndex" [etapeMax]="jeu.nbEtapes"></app-footer>
</div>
