import { Injectable } from '@angular/core';
import { FicheSta } from 'src/modules/core/modeles/fiche/fichePersonnage/ficheSta';
import { Race } from 'src/modules/core/modeles/fiche/race/race';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { Des } from 'src/modules/core/utilitaires/des';
import { GenerationHelper } from 'src/modules/core/utilitaires/generationHelper';
import { LoadDataService } from 'src/services/loadData.service';
import { StaComponent } from '../sta/sta.component';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class StaAleatoireService {

  private races: Race[] = [];
  private ptCaracRestants: number = 0;
  private ptCompRestants: number = 0;
  private finAleatoire= new Subject<FicheSta>();

  constructor(private fiche: FicheSta, private jeu: Jeu, private loadDataService: LoadDataService, private staComponent: StaComponent) { }

  getFinAleatoire(): Subject<FicheSta>{
    return this.finAleatoire;
  }

  generationAleatoire(races: Race[]) {
    this.races = races;
    this.chargementRace();
  }

  chargementRace() {
    const des = Des.aleatoire(0, this.races.length - 1);
    const race = this.races[des];
    this.fiche.etatCivil.race = race.nom;
    const fichierRace = '../assets/races/' + this.jeu.acronyme + '/' + race.nom.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '.json';

    this.loadDataService.getByAcronyme(fichierRace).subscribe(data => {
      this.fiche.race = data.race[0];
      this.fiche.equipement = data.race[0].equipement;
      this.fiche.race.sensibilite = data.race[0].sensibilite == "true" ? true : false;
      this.ptCaracRestants = this.fiche.race.ptCarac;
      this.ptCompRestants = this.fiche.race.ptComp;
      this.fiche.race.ptComp = 0;
      this.staComponent.generationCaracteristiques();
      this.staComponent.generationRegles();
      this.etatCivil();
    });
  }

  etatCivil() {
    if (this.races && this.races.length > 0) {

      const sexe = Des.aleatoire(1, 2);
      const poidsMin = this.fiche.race.poidsMin > 0 ? this.fiche.race.poidsMin : 10;
      const poidsMax = this.fiche.race.poidsMax > 0 ? this.fiche.race.poidsMax : 100;
      const tailleMin = this.fiche.race.tailleMin > 0 ? this.fiche.race.tailleMin : 20;
      const tailleMax = this.fiche.race.tailleMax > 0 ? this.fiche.race.tailleMax : 200;

      if (sexe === 1) {
        this.fiche.etatCivil.sexe = "Homme";
      } else {
        this.fiche.etatCivil.sexe = "Femme";
      }

      this.fiche.etatCivil.taille = Des.aleatoire(Number(tailleMin), Number(tailleMax));
      this.fiche.etatCivil.poids = Des.aleatoire(Number(poidsMin), Number(poidsMax));
      this.fiche.etatCivil.prenom = GenerationHelper.creerUnNom(Des.aleatoire(4, 12));
      this.fiche.etatCivil.nom = GenerationHelper.creerUnNom(Des.aleatoire(4, 12));
      this.fiche.etatCivil.age = Des.aleatoire(0, 100);
      this.caracteristiques();
    }
  }

  caracteristiques() {

    this.fiche.caracteristiques.forEach(caracteristique => {
      caracteristique.valeur = caracteristique.valeurMin;
    });

    while (this.ptCaracRestants > 0){
      const selectedCaract = this.fiche.caracteristiques[Des.aleatoire(0, this.fiche.caracteristiques.length-1)];
      ++selectedCaract.valeur;
      this.ptCaracRestants -= 1;
    }

    this.competences();
  }

  competences() {

    const fichierCompetences = '../assets/competences/' + this.jeu.acronyme + '.json';
    this.loadDataService.getByAcronyme(fichierCompetences).subscribe(data => {
      this.fiche.competences = data.competences;
      while (this.ptCompRestants > 0){
        const valeurAjoutee = Des.aleatoire(1,this.ptCompRestants);
        const selectedComp = this.fiche.competences[Des.aleatoire(0, this.fiche.competences.length-1)];
        selectedComp.valeur = valeurAjoutee;
        this.ptCompRestants -= valeurAjoutee;
      }
      this.equipement();
      this.staComponent.ptRestantCarac = 0;
    });
  }

  equipement() {
    this.fiche.equipement = new Equipement();
    this.fiche.equipement.description = "";
    this.divers();
  }

  divers() {
    this.fiche.divers = new Divers();
    this.fiche.divers.tableauDivers = new Map<string,any>();

    if (this.jeu.divers) {
      this.jeu.divers.forEach(inputText => {
        this.fiche.divers.tableauDivers.set(inputText, "");
      });
    }

    this.finAleatoire.next(this.fiche);
  }
}
