import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { TimeLineService } from '../services/time-line.service';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.css']
})
export class TimeLineComponent implements OnInit {

  @Input() jeu: Jeu;
  @Input() selectedIndex = 1;

  public widthEtape = 0;

  constructor(private timeLineService: TimeLineService) { }

  ngOnInit(): void {
    this.goToEtape(0);
    this.jeu.etapes.push("Génération");
  }

  ngOnChanges() {
    this.widthEtape = 95 / (Number(this.jeu.nbEtapes) + 1);
  }

  goToEtape(index){
    this.selectedIndex = index;
    this.timeLineService.sendTimeLineIndex(this.selectedIndex);
  }

  isTobeDisplayed(previous: boolean): boolean {
    if(previous && this.selectedIndex == 0){
      return false;
    } else if(!previous && Number(this.jeu.nbEtapes) == this.selectedIndex){
      return false;
    }

    return true;
  }
}
