import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Systeme } from 'src/modules/core/enum/systeme.enum';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { Competence } from 'src/modules/core/modeles/fiche/commun/competence';
import { CompetencesService } from '../services/competences.service';

@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.css']
})
export class CompetencesComponent implements OnInit, OnChanges {

  @Input() cumul: boolean;
  @Input() ptCompetences: number;
  @Input() competences: Competence[];
  @Input() caracteristiques: Caracteristique[];
  @Input() toutAfficher: boolean;
  @Input() systemeDeJeu: string;
  @Input() affichagePoints: boolean = true;
  @Input() nombreMaxChoixCompetences: number;
  @Input() afficherIllustration: boolean;
  @Input() maxCompAvantScroll: number;
  @Input() identifiant: string;

  public ptRestant: number = 0;
  public competencesTriees = new Map();
  public competencesSelectionnees: Competence[];
  public competenceSelectionnee: Competence;

  private ptDepenses = new Map<string,number>();
  private ptDepensesIdentifiant = new Map<string, Map<string,number>>();

  constructor(private competencesService: CompetencesService) { }

  ngOnInit(): void {
    if(this.competences && this.competencesTriees.size == 0){
      this.ptRestant = this.ptCompetences;
      this.trierCompetences();
    }
  }

  ngOnChanges(): void {
    this.ptRestant = this.ptCompetences;
    this.generationCompetencesSelectionnees();
  }

  updateCompetencesSelectionnees(): void {
    if(this.competencesSelectionnees && this.competencesSelectionnees.length > 0){

      this.competencesSelectionnees.forEach(competence => {

        if(competence.valeur < competence.valeurMin){
          competence.valeur = competence.valeurMin;
        }

        if(competence.valeur > competence.valeurMax){
          competence.valeur = competence.valeurMax;
        }
      });
    }

    if(this.ptCompetences == undefined){
      this.ptCompetences = 0;
    }
  }

  generationCompetencesSelectionnees(): void {

    if (!this.competencesSelectionnees) {
      this.competencesSelectionnees = [];
    }

    const competenceModifiees: Competence[] = [];
    this.competences.forEach(competence => {
      if(!isNaN(competence.valeur) && competence.valeur > competence.valeurMin) {
        competenceModifiees.push(competence);
      }
    })

    this.competencesSelectionnees.forEach(competence => {
      const competenceModifiee = this.competences.find(comp => comp.nom === competence.nom);
      if (competenceModifiee) {
        competence.valeur = competenceModifiee.valeur;
      }
    });
  }

  encoreDispo(): boolean{
    if (!this.nombreMaxChoixCompetences || !this.competencesSelectionnees || this.competencesSelectionnees.length === 0){
      return true;
    }
    return this.nombreMaxChoixCompetences > this.competencesSelectionnees.length;
  }

  trierCompetences(){
    if(this.toutAfficher){
      return;
    }

    this.competencesTriees = new Map();

    // Tri par caractéristiques
    this.competences.forEach(competence => {

      if(this.cumul){
        if(this.competencesTriees.has(competence.caracteristique)){
          let comp: Competence[];
          comp = this.competencesTriees.get(competence.caracteristique)
          comp.push(competence);
        } else {
          let comp:Competence[] = [];
          comp.push(competence);
          this.competencesTriees.set(competence.caracteristique, comp);
        }
      } else {
        if(this.competencesTriees.has("Au choix")){
          let comp: Competence[];
          comp = this.competencesTriees.get("Au choix")
          comp.push(competence);
        } else {
          let comp:Competence[] = [];
          comp.push(competence);
          this.competencesTriees.set("Au choix", comp);
        }
      }
    })
  }

  competencesSelectionneesContains(competenceNom): boolean{
    return this.competencesSelectionnees.some(competence => competence.nom == competenceNom);
  }

  defineMaxHeight(liste: Competence[]): string{

    let scroll = "scrollComp";

    if(isNaN(this.maxCompAvantScroll) || this.maxCompAvantScroll){
      return "heightComp " + scroll;
    }

    if(liste && liste.length <= this.maxCompAvantScroll){
      scroll == "noScroll";
    }

    switch(this.maxCompAvantScroll){
      case 2 :
        return "heightComp2 " + scroll;
      case 4 :
        return "heightComp4 " + scroll;
      case 6 :
        return "heightComp6 " + scroll;
      case 8 :
        return "heightComp9 " + scroll;
    }
  }

  addComp(){

    if(!this.competenceSelectionnee){
      return;
    }

    if(!this.competencesSelectionnees){
      this.competencesSelectionnees = [];
    }

    if(this.nombreMaxChoixCompetences != 0 && this.competencesSelectionnees.length >= this.nombreMaxChoixCompetences){
      return;
    }

    if(this.competencesSelectionneesContains(this.competenceSelectionnee.nom)){
      return;
    }

    if(this.competenceSelectionnee.valeurMin){
      this.competenceSelectionnee.valeur = this.competenceSelectionnee.valeurMin;
    } else {
      this.competenceSelectionnee.valeur = 0
    }

    this.competencesSelectionnees.push(this.competenceSelectionnee);
    this.updateCompetencesSelectionnees();
  }

  remplirMapPtDepenses() {
    if(this.identifiant){
      this.ptDepensesIdentifiant.set(this.identifiant,this.ptDepenses);
    } else {
      this.ptDepensesIdentifiant.set("GENERIQUE",this.ptDepenses);
    }
    this.competencesService.sendPtDepenses(this.ptDepensesIdentifiant);
  }

  remplirEtEnvoyerPtRestant() {
    const ptRestant = new Map<string, number>();
    if(this.identifiant){
      this.competencesService.sendPtRestant(ptRestant.set(this.identifiant,this.ptRestant));
    } else {
      this.competencesService.sendPtRestant(ptRestant.set("GENERIQUE",this.ptRestant));
    }
  }

  actualisationEcritePtDepenses(competence: Competence, valeurEcrite: number): void{
    if (this.ptDepenses){
      const valeur = valeurEcrite-competence.valeurMin > 0 ? valeurEcrite-competence.valeurMin : valeurEcrite;
      this.ptDepenses.set(competence.nom, valeur);
      this.remplirMapPtDepenses();
    }
    this.remplirEtEnvoyerPtRestant();
  }

  actualisationMapPtDepenses(add: boolean, competence: Competence): void{

    const point = add ? 1 : -1;

    if(this.ptDepenses && this.ptDepenses.get(competence.nom) != null && Systeme[this.systemeDeJeu] != Systeme.D100){
      let value = this.ptDepenses.get(competence.nom);
      value += point;
      this.ptDepenses.set(competence.nom, value)
    } else if (this.ptDepenses){
      this.ptDepenses.set(competence.nom, point)
    }

    this.remplirMapPtDepenses();
  }

  gestionValeurEcrite(event, competence: Competence){

    const valeur = competence.valeur;
    const valeurMin = competence.valeurMin;
    const valeurMax = competence.valeurMax;
    let valeurEcrite = Number(event.target.value);

    if(this.systemeDeJeu == Systeme.D100){
      if(valeurEcrite > 90){
        valeurEcrite = 90;
      }
    }

    if(valeurEcrite > valeur){
      // Ajout de points
      if(!isNaN(valeurMax) && valeurEcrite > valeurMax){
        valeurEcrite = valeurMax;
      }

      if(valeurEcrite - valeur > this.ptRestant){
        valeurEcrite = valeur + this.ptRestant;
      }
      event.target.value = valeurEcrite;
      this.ptRestant -= valeurEcrite - valeur;
      competence.valeur = valeurEcrite;
      this.actualisationEcritePtDepenses(competence, valeurEcrite);
    } else {
      // Retrait de points
      if(!isNaN(valeurMin) && valeurEcrite < valeurMin){
        valeurEcrite = valeurMin;
      }

      event.target.value = valeurEcrite;
      this.ptRestant += valeur - valeurEcrite;
      competence.valeur = valeurEcrite;
      this.actualisationEcritePtDepenses(competence, (valeurEcrite - valeur));
    }
  }

  ajoutPt(competence: Competence): void{

    if(!this.ptRestant){
      this.ptRestant = 0;
    }

    let valeur = Number(competence.valeur);
    const valeurMax = Number(competence.valeurMax);

    if(this.ptRestant > 0){
      if(!isNaN(valeurMax)){
        if(valeur < valeurMax){
          this.ptRestant -= 1;
          valeur += 1;
        }
      } else {
        this.ptRestant -= 1;
        valeur += 1;
      }

      competence.valeur = valeur;
      this.actualisationMapPtDepenses(true, competence);
      this.remplirEtEnvoyerPtRestant();
    }
  }

  retraitPt(competence: Competence): void{

    if(!this.ptRestant){
      this.ptRestant = 0;
    }

    let valeur = Number(competence.valeur);
    let valeurMin = Number(competence.valeurMin);

    if(isNaN(valeurMin) || valeurMin == undefined){
      valeurMin = 0;
    }

    if(valeur <= valeurMin){
      return;
    }

    this.ptRestant += 1;
    valeur -= 1;

    competence.valeur = valeur;
    this.actualisationMapPtDepenses(false, competence);
    this.remplirEtEnvoyerPtRestant();
  }

  affichageComp(competence: Competence){

    if(!competence){
      return;
    }

    let valeur = Number(competence.valeur);

    if(this.cumul){
      // Cas de la génération aléatoire
      if(!this.competenceSelectionnee){
        this.competenceSelectionnee = this.competencesSelectionnees.find(comp => comp.nom === competence.nom)
      }
      const caracteristique = this.caracteristiques.find(carac => carac.getNomNormalise() === competence.caracteristique)
      valeur += Number(caracteristique.valeur);
    }

    switch(Systeme[this.systemeDeJeu]){
      case Systeme.D6 :
        return this.affichageCompD6(valeur);
      case Systeme.D100 :
        return this.affichageCompD100(valeur);
      default:
        return valeur;
    }
  }

  affichageCompD100(valeur: number){
    return valeur;
  }

  affichageCompD6(valeur: number){

    if(valeur % 3 === 0){
      return valeur/3 + "D";
    }

    const modulo = (valeur / 3) - Math.floor(valeur/3);

    if(modulo < 0.5){
      return Math.floor(valeur/3) + "D+1";
    } else {
      return Math.floor(valeur/3) + "D+2";
    }
  }

  suppressionComp(competence){

    let valeurMin = Number(competence.valeurMin);

    if(isNaN(valeurMin) || valeurMin == undefined){
      valeurMin = 0;
    }

    const pointAReverser = Number(competence.valeur) - valeurMin;
    this.ptRestant += pointAReverser;
    this.ptDepenses.delete(competence.nom);
    this.competences.forEach(comp => {
      if (comp.nom === competence.nom) {
        comp.valeur = comp.valeurMin;
      }
    });

    const index = this.competencesSelectionnees.indexOf(competence);
    this.competencesSelectionnees.splice(index,1);

    // Pour activer le rechargement du tableau : si pointAReverser = 0 cad qu'on supprime une compétence n'ayant pas reçue de point
    // Cela évitera un double update si on lance toujours this.generationCompetencesSelectionnees
    if (pointAReverser === 0) {
      this.generationCompetencesSelectionnees();
    }

    this.actualisationEcritePtDepenses(competence, valeurMin);
    this.remplirEtEnvoyerPtRestant();
    this.remplirMapPtDepenses();
  }

  validateNumberKey(event): boolean{

    const regex = /^\d*\.?\d*$/;
    if (event.key.match(regex) || event.key == "Backspace" || event.key == "Delete" || event.key == "ArrowLeft" || event.key == "ArrowRight" || event.key == "Tab") {
      return true;
    } else {
      return false;
    }
  }
}
