import { Component, Input, OnInit } from '@angular/core';
import { DiversService } from '../../services/divers.service';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';

@Component({
  selector: 'app-divers',
  templateUrl: './divers.component.html',
  styleUrls: ['./divers.component.css']
})
export class DiversComponent implements OnInit{

  @Input() divers: Divers;

  constructor(private diversService: DiversService) { }

  ngOnInit(): void {
    if(!this.divers){
      this.divers = new Divers();
      this.divers.tableauDivers = new Map<string,any>();
    }
  }

  actualisation(clef: string, event: Event){
    this.divers.tableauDivers.set(clef, (event.target as any).value);
    this.diversService.sendDivers(this.divers);
  }
}
