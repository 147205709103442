import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';

@Injectable({
  providedIn: 'root'
})
export class EquipementsService {

  private equipementSubject= new Subject<Equipement>();

  sendEquipement(equipement: Equipement): void {
    this.equipementSubject.next(equipement);
  }

  getEquipement(): Subject<Equipement>{
    return this.equipementSubject;
  }
}
