import { Component, Input } from '@angular/core';
import { TimeLineService } from '../../time-line/services/time-line.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  @Input() selectedIndex = 1;
  @Input() etapeMax;

  constructor(private timeLineService: TimeLineService) { }

  enArriere(){
    if(this.selectedIndex > 0){
      this.selectedIndex -= 1;
      this.timeLineService.sendTimeLineIndex(this.selectedIndex);
    }
  }

  enAvant(){
    if(this.selectedIndex < this.etapeMax+1){
      this.selectedIndex += 1;
      this.timeLineService.sendTimeLineIndex(this.selectedIndex);
    }
  }
}
