import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';

@Injectable({
  providedIn: 'root'
})
export class CaracteristiquesService {

  private pointSubject = new Subject<number>();
  private caracteristiquesSubject = new Subject<Caracteristique[]>();

  sendPointRestant(ptRestant: number): void {
    this.pointSubject.next(ptRestant);
  }

  getPointRestant(): Subject<number>{
    return this.pointSubject;
  }

  sendCaracteristiques(caracteristiques: Caracteristique[]): void {
    this.caracteristiquesSubject.next(caracteristiques);
  }

  getCaracteristiques(): Subject<Caracteristique[]>{
    return this.caracteristiquesSubject;
  }
}
