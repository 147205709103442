import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';

@Injectable({
  providedIn: 'root'
})
export class DiversService {

  private diversSubject= new Subject<Divers>();

  sendDivers(divers: Divers): void {
    this.diversSubject.next(divers);
  }

  getDivers(): Subject<Divers>{
    return this.diversSubject;
  }
}
