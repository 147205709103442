import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { EtatCivil } from 'src/modules/core/modeles/fiche/commun/etatCivil';
import { PouvoirForce } from 'src/modules/core/modeles/fiche/commun/pouvoirForce';
import { FicheSta } from 'src/modules/core/modeles/fiche/fichePersonnage/ficheSta';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { LoadDataService } from 'src/services/loadData.service';
import { StaAleatoireService } from '../services/staAleatoire.service';
import { StaGeneration } from '../services/staGeneration';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';
import { EquipementsService } from 'src/modules/shared/equipements/services/equipements.service';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';
import { DiversService } from 'src/modules/shared/divers/services/divers.service';
import { AleatoireService } from 'src/services/aleatoire.service';
import { EtatCivilService } from 'src/modules/shared/etat-civil/services/etat-civil.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TimeLineService } from 'src/modules/shared/time-line/services/time-line.service';
import { GenerationService } from 'src/modules/shared/generation/services/generation.service';
import { CaracteristiquesService } from 'src/modules/shared/caracteristiques/services/caracteristiques.service';
import { ForceService } from 'src/modules/shared/specifique/force/services/force.service';
import { Race } from 'src/modules/core/modeles/fiche/race/race';

@Component({
  selector: 'app-sta',
  templateUrl: './sta.component.html',
  styleUrls: ['./sta.component.css']
})
export class StaComponent implements OnInit, OnDestroy {

  public jeu: Jeu;
  public selectedIndex = 0;
  public fiche: FicheSta;
  public ptRestantCarac: number;
  public ficheGeneration: string[][] = new Array();
  public regles: Map<string, string> = new Map();

  private subscriptions: Subscription[] = [];
  private staAleatoireService: StaAleatoireService;
  private races: Race[] = [];

  constructor(private route: ActivatedRoute, private loadDataService: LoadDataService, private equipementsService: EquipementsService,
    private diversService: DiversService, private aleatoireService: AleatoireService, private etatCivilService: EtatCivilService, private timeLineService: TimeLineService,
    private generationService: GenerationService, private caracteristiquesService: CaracteristiquesService, private forceService: ForceService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const obj = params['jeu'];
      if (obj != null) {
        this.jeu = <Jeu>JSON.parse(obj);
      }
    });

    if (!this.fiche) {
      this.fiche = new FicheSta();
      this.fiche.etatCivil = new EtatCivil();
      this.fiche.equipement = new Equipement();
      this.fiche.equipement.description = "";
      this.fiche.divers = new Divers;
      this.fiche.divers.tableauDivers = new Map<string, any>();
      if (this.jeu.divers) {
        this.jeu.divers.forEach(inputText => {
          this.fiche.divers.tableauDivers.set(inputText, "");
        });
      }
    }

    this.staAleatoireService = new StaAleatoireService(this.fiche, this.jeu, this.loadDataService, this);

    const equipementSubscription = this.equipementsService.getEquipement().subscribe({
      next: (equipementsRecu: Equipement) => {
        this.fiche.equipement = equipementsRecu;
      }
    });

    const diversSubscription = this.diversService.getDivers().subscribe({
      next: (diversRecu: Divers) => {
        this.fiche.divers = diversRecu;
      }
    });

    const aleatoireSubscription = this.aleatoireService.getAleatoire().subscribe({
      next: () => {
        this.aleatoire();
      }
    });

    const etatCivilSubscription = this.etatCivilService.getRaceSelectionnee().subscribe({
      next: (raceSelectionneeRecu: string) => {
        this.loadRaceStarWars(raceSelectionneeRecu);
      }
    });

    const timeLineSubscription = this.timeLineService.getTimeLineIndex().subscribe({
      next: (selectedIndexRecu: number) => {
        this.selectedIndex = selectedIndexRecu;
      }
    });

    const generationSubscription = this.generationService.getGeneration().subscribe({
      next: () => {
        this.generation();
      }
    });

    const caracterisquesPtRestantSubscription = this.caracteristiquesService.getPointRestant().subscribe({
      next: (ptRestantRecu: number) => {
        this.ptRestantCarac = ptRestantRecu;
      }
    });

    const caracterisquesSubscription = this.caracteristiquesService.getCaracteristiques().subscribe({
      next: (caracteristiquesRecues: Caracteristique[]) => {
        caracteristiquesRecues.forEach(caracteristique => {
          let caracUpdated = this.fiche.caracteristiques.find(carac => carac.getNomNormalise() === caracteristique.getNomNormalise());
          caracUpdated = caracteristique;
        })
      }
    });

    const pouvoirsSubscription = this.forceService.getPouvoirs().subscribe({
      next: (pouvoirs: PouvoirForce[]) => {
        this.fiche.pouvoirsForce = pouvoirs;
      }
    });

    const sensibiliteSubscription = this.forceService.getSensibilite().subscribe({
      next: (sensibilite: boolean) => {
        this.fiche.race.sensibilite = sensibilite;
      }
    });

    const staAleatoireSubscription = this.staAleatoireService.getFinAleatoire().subscribe({
      next: (ficheStaGeneree: FicheSta) => {
        this.fiche = ficheStaGeneree;
      }
    });

    this.subscriptions.push(equipementSubscription);
    this.subscriptions.push(diversSubscription);
    this.subscriptions.push(aleatoireSubscription);
    this.subscriptions.push(etatCivilSubscription);
    this.subscriptions.push(timeLineSubscription);
    this.subscriptions.push(generationSubscription);
    this.subscriptions.push(caracterisquesPtRestantSubscription);
    this.subscriptions.push(caracterisquesSubscription);
    this.subscriptions.push(pouvoirsSubscription);
    this.subscriptions.push(sensibiliteSubscription);
    this.subscriptions.push(staAleatoireSubscription);
  }

  aleatoire() {
    if(!this.races || this.races.length === 0){
      var fichierRaces = '../assets/races/Sta/Sta.json';
      this.loadDataService.getByAcronyme(fichierRaces).subscribe(data => {
        this.races = data.races;
        this.staAleatoireService.generationAleatoire(this.races);
      });
    } else {
      this.staAleatoireService.generationAleatoire(this.races);
    }
  }

  generation() {
    this.ficheGeneration = new StaGeneration(this.fiche).generation();
  }

  loadRaceStarWars(race: string) {

    if (!race || "" === race) {
      return;
    }

    const fichierRace = '../assets/races/' + this.jeu.acronyme + '/' + race + '.json';

    this.loadDataService.getByAcronyme(fichierRace).subscribe(data => {
      this.fiche.race = data.race[0];
      this.ptRestantCarac = this.fiche.race.ptCarac;
      this.fiche.equipement = data.race[0].equipement;
      this.fiche.race.sensibilite = data.race[0].sensibilite == "true" ? true : false;
      this.generationCaracteristiques();
      this.generationCompetence();
      this.generationRegles();
    });
  }

  generationRegles() {

    this.regles.set("tailleMin", String(this.fiche.race.tailleMin));
    this.regles.set("tailleMax", String(this.fiche.race.tailleMax));
    this.regles.set("poidsMin", String(this.fiche.race.poidsMin));
    this.regles.set("poidsMax", String(this.fiche.race.poidsMax));
    this.regles.set("raceImage", "../assets/images/races/" + this.jeu.acronyme + "/" + this.fiche.race.nom + ".JPG");
    const description = this.fiche.race.description != "" ? this.fiche.race.description : " - ";
    const capacites = this.fiche.race.capacites != "" ? this.fiche.race.capacites : " - ";
    this.regles.set("raceDescription", "DESCRIPTION : " + description + " CAPACITES : " + capacites);
  }

  generationCompetence() {
    this.fiche.competences = null;
    const fichierCompetences = '../assets/competences/' + this.jeu.acronyme + '.json';
    this.loadDataService.getByAcronyme(fichierCompetences).subscribe(data => {
      this.fiche.competences = data.competences;
    });
  }

  generationCaracteristiques() {

    let caracteristiques = [];
    let caracteristiquesForce = [];

    let dexterite = new Caracteristique();
    dexterite.nom = "Dextérite";
    dexterite.valeur = this.fiche.race.dexteriteMin;
    dexterite.valeurMax = this.fiche.race.dexteriteMax;
    dexterite.valeurMin = this.fiche.race.dexteriteMin;
    this.loadDataService.getFileContent('../assets/caracteristiques/Dexterite.json').subscribe(data => dexterite.description = data);
    caracteristiques.push(dexterite);

    let perception = new Caracteristique();
    perception.nom = "Perception";
    perception.valeur = this.fiche.race.perceptionMin;
    perception.valeurMax = this.fiche.race.perceptionMax;
    perception.valeurMin = this.fiche.race.perceptionMin;
    this.loadDataService.getFileContent('../assets/caracteristiques/Perception.json').subscribe(data => perception.description = data);
    caracteristiques.push(perception);

    let savoir = new Caracteristique();
    savoir.nom = "Savoir";
    savoir.valeur = this.fiche.race.savoirMin;
    savoir.valeurMax = this.fiche.race.savoirMax;
    savoir.valeurMin = this.fiche.race.savoirMin;
    this.loadDataService.getFileContent('../assets/caracteristiques/Savoir.json').subscribe(data => savoir.description = data);
    caracteristiques.push(savoir);

    let vigueur = new Caracteristique();
    vigueur.nom = "Vigueur";
    vigueur.valeur = this.fiche.race.vigueurMin;
    vigueur.valeurMax = this.fiche.race.vigueurMax;
    vigueur.valeurMin = this.fiche.race.vigueurMin;
    this.loadDataService.getFileContent('../assets/caracteristiques/Vigueur.json').subscribe(data => vigueur.description = data);
    caracteristiques.push(vigueur);

    let mecanique = new Caracteristique();
    mecanique.nom = "Mécanique";
    mecanique.valeur = this.fiche.race.mecaniqueMin;
    mecanique.valeurMax = this.fiche.race.mecaniqueMax;
    mecanique.valeurMin = this.fiche.race.mecaniqueMin;
    this.loadDataService.getFileContent('../assets/caracteristiques/Mecanique.json').subscribe(data => mecanique.description = data);
    caracteristiques.push(mecanique);

    let technique = new Caracteristique();
    technique.nom = "Technique";
    technique.valeur = this.fiche.race.techniqueMin;
    technique.valeurMax = this.fiche.race.techniqueMax;
    technique.valeurMin = this.fiche.race.techniqueMin;
    this.loadDataService.getFileContent('../assets/caracteristiques/Technique.json').subscribe(data => technique.description = data);
    caracteristiques.push(technique);

    let sens = new Caracteristique();
    sens.nom = "Sens";
    sens.valeur = 0;
    sens.valeurMin = 0;
    sens.valeurMax = 18;
    this.loadDataService.getFileContent('../assets/caracteristiques/Sens.json').subscribe(data => sens.description = data);
    caracteristiquesForce.push(sens);

    let controle = new Caracteristique();
    controle.nom = "Contrôle";
    controle.valeur = 0;
    controle.valeurMin = 0;
    controle.valeurMax = 18;
    this.loadDataService.getFileContent('../assets/caracteristiques/Controle.json').subscribe(data => controle.description = data);
    caracteristiquesForce.push(controle);

    let alteration = new Caracteristique();
    alteration.nom = "Altération";
    alteration.valeur = 0;
    alteration.valeurMin = 0;
    alteration.valeurMax = 18;
    this.loadDataService.getFileContent('../assets/caracteristiques/Alteration.json').subscribe(data => alteration.description = data);
    caracteristiquesForce.push(alteration);

    this.fiche.caracteristiques = caracteristiques;
    this.fiche.caracteristiquesForce = caracteristiquesForce;
  }
}
