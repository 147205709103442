import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/modules/shared/shared.module';
import { FadComponent } from './fad/fad.component';

@NgModule({
  declarations: [FadComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [FadComponent]
})
export class FadingsunsModule { }
