import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';
import { EtatCivil } from 'src/modules/core/modeles/fiche/commun/etatCivil';
import { Faction } from 'src/modules/core/modeles/fiche/commun/faction';
import { QualiteDefaut } from 'src/modules/core/modeles/fiche/commun/qualiteDefaut';
import { FicheFad } from 'src/modules/core/modeles/fiche/fichePersonnage/ficheFad';
import { Race } from 'src/modules/core/modeles/fiche/race/race';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { DiversService } from 'src/modules/shared/divers/services/divers.service';
import { EquipementsService } from 'src/modules/shared/equipements/services/equipements.service';
import { EtatCivilService } from 'src/modules/shared/etat-civil/services/etat-civil.service';
import { GenerationService } from 'src/modules/shared/generation/services/generation.service';
import { FactionService } from 'src/modules/shared/specifique/faction/services/faction.service';
import { TimeLineService } from 'src/modules/shared/time-line/services/time-line.service';
import { AleatoireService } from 'src/services/aleatoire.service';
import { LoadDataService } from 'src/services/loadData.service';

@Component({
  selector: 'app-fad',
  templateUrl: './fad.component.html',
  styleUrls: ['./fad.component.css']
})
export class FadComponent implements OnInit, OnDestroy{

  public jeu: Jeu;
  public selectedIndex = 0;
  public fiche: FicheFad;
  public regles: Map<string, string> = new Map();

  private races: Race[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute, private loadDataService: LoadDataService,private equipementsService: EquipementsService, private diversService: DiversService,
    private aleatoireService: AleatoireService, private etatCivilService: EtatCivilService, private timeLineService: TimeLineService, private generationService: GenerationService,
    private factionService: FactionService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const obj = params['jeu'];
      if (obj != null) {
        this.jeu = <Jeu>JSON.parse(obj);
      }
    });

    if (!this.fiche) {
      this.fiche = new FicheFad();
      this.fiche.etatCivil = new EtatCivil();
      this.fiche.equipement = new Equipement();
      this.fiche.equipement.description = "";
      this.fiche.divers = new Divers;
      this.fiche.divers.tableauDivers = new Map<string, any>();
      this.fiche.qualites = [];
      this.fiche.defauts = [];
      this.fiche.atouts = [];
      this.fiche.handicaps = [];
      if (this.jeu.divers) {
        this.jeu.divers.forEach(inputText => {
          this.fiche.divers.tableauDivers.set(inputText, "");
        });
      }
    }

    const equipementSubscription = this.equipementsService.getEquipement().subscribe({
      next: (equipementsRecu: Equipement) => {
        this.fiche.equipement = equipementsRecu;
      }
    });

    const diversSubscription = this.diversService.getDivers().subscribe({
      next: (diversRecu: Divers) => {
        this.fiche.divers = diversRecu;
      }
    });

    const aleatoireSubscription = this.aleatoireService.getAleatoire().subscribe({
      next: () => {
        this.aleatoire();
      }
    });

    const etatCivilSubscription = this.etatCivilService.getRaceSelectionnee().subscribe({
      next: (raceSelectionneeRecu: string) => {
        this.loadRaceFadingSuns(raceSelectionneeRecu);
      }
    });

    const timeLineSubscription = this.timeLineService.getTimeLineIndex().subscribe({
      next: (selectedIndexRecu: number) => {
        this.selectedIndex = selectedIndexRecu;
      }
    });

    const generationSubscription = this.generationService.getGeneration().subscribe({
      next: () => {
        this.generation();
      }
    });

    const factionSubscription = this.factionService.getFaction().subscribe({
      next: (faction: Faction) => {
        this.fiche.faction = faction;
      }
    });

    const qualiteSubscription = this.factionService.getQualites().subscribe({
      next: (qualites: QualiteDefaut[]) => {
        this.fiche.qualites = qualites;
      }
    });

    const defautSubscription = this.factionService.getDefauts().subscribe({
      next: (defauts: QualiteDefaut[]) => {
        this.fiche.defauts = defauts;
      }
    });

    const atoutSubscription = this.factionService.getAtouts().subscribe({
      next: (atouts: QualiteDefaut[]) => {
        this.fiche.atouts = atouts;
      }
    });

    const handicapSubscription = this.factionService.getHandicaps().subscribe({
      next: (handicaps: QualiteDefaut[]) => {
        this.fiche.handicaps = handicaps;
      }
    });

    const jokerSubscription = this.factionService.getJoker().subscribe({
      next: (joker: number) => {
        this.fiche.pointsJoker = joker;
      }
    });

    this.subscriptions.push(equipementSubscription);
    this.subscriptions.push(diversSubscription);
    this.subscriptions.push(aleatoireSubscription);
    this.subscriptions.push(etatCivilSubscription);
    this.subscriptions.push(timeLineSubscription);
    this.subscriptions.push(generationSubscription);
    this.subscriptions.push(factionSubscription);
    this.subscriptions.push(qualiteSubscription);
    this.subscriptions.push(defautSubscription);
    this.subscriptions.push(atoutSubscription);
    this.subscriptions.push(handicapSubscription);
    this.subscriptions.push(jokerSubscription);
  }

  loadRaceFadingSuns(race: string) {

    if (!race || "" === race) {
      return;
    }

    const fichierRace = '../assets/races/' + this.jeu.acronyme + '/' + race + '.json';

    this.loadDataService.getByAcronyme(fichierRace).subscribe(data => {
      this.fiche.race = data.race[0];
      this.fiche.equipement = data.race[0].equipement;
      this.generationRegles();
      this.generationCaracteristiques();
    });
  }

  generation() {
    throw new Error('Method not implemented.');
  }

  aleatoire() {
    throw new Error('Method not implemented.');
  }

  generationRegles() {

    this.regles.set("tailleMin", String(this.fiche.race.tailleMin));
    this.regles.set("tailleMax", String(this.fiche.race.tailleMax));
    this.regles.set("poidsMin", String(this.fiche.race.poidsMin));
    this.regles.set("poidsMax", String(this.fiche.race.poidsMax));
    this.regles.set("raceImage", "../assets/images/races/" + this.jeu.acronyme + "/" + this.fiche.race.nom + ".JPG");
    const description = this.fiche.race.description != "" ? this.fiche.race.description : " - ";
    this.regles.set("raceDescription", "DESCRIPTION : " + description);
  }

  generationCaracteristiques() {
    let caracteristiques = [];
    let caracteristiquesEsprit = [];

    let force = new Caracteristique();
    force.nom = "Force";
    force.valeur = 3;
    force.valeurMax = 8;
    force.valeurMin = 3;
    this.loadDataService.getFileContent('../assets/caracteristiques/Force.json').subscribe(data => force.description = data);
    caracteristiques.push(force);

    let intelligence = new Caracteristique();
    intelligence.nom = "Intelligence";
    intelligence.valeur = 3;
    intelligence.valeurMax = 8;
    intelligence.valeurMin = 3;
    this.loadDataService.getFileContent('../assets/caracteristiques/Intelligence.json').subscribe(data => intelligence.description = data);
    caracteristiques.push(intelligence);

    let dexterite = new Caracteristique();
    dexterite.nom = "Dextérite";
    dexterite.valeur = 3;
    dexterite.valeurMax = 8;
    dexterite.valeurMin = 3;
    this.loadDataService.getFileContent('../assets/caracteristiques/Dexterite.json').subscribe(data => dexterite.description = data);
    caracteristiques.push(dexterite);

    let endurance = new Caracteristique();
    endurance.nom = "Endurance";
    endurance.valeur = 3;
    endurance.valeurMax = 8;
    endurance.valeurMin = 3;
    this.loadDataService.getFileContent('../assets/caracteristiques/Endurance.json').subscribe(data => endurance.description = data);
    caracteristiques.push(endurance);

    let perception = new Caracteristique();
    perception.nom = "Perception";
    perception.valeur = 3;
    perception.valeurMax = 8;
    perception.valeurMin = 3;
    this.loadDataService.getFileContent('../assets/caracteristiques/Perception.json').subscribe(data => perception.description = data);
    caracteristiques.push(perception);

    let tech = new Caracteristique();
    tech.nom = "Tech";
    tech.valeur = 3;
    tech.valeurMax = 8;
    tech.valeurMin = 3;
    this.loadDataService.getFileContent('../assets/caracteristiques/Tech.json').subscribe(data => tech.description = data);
    caracteristiques.push(tech);

    let passion = new Caracteristique();
    passion.nom = "Passion";
    passion.valeur = 1;
    passion.valeurMax = 8;
    passion.valeurMin = 1;
    this.loadDataService.getFileContent('../assets/caracteristiques/Passion.json').subscribe(data => passion.description = data);
    caracteristiquesEsprit.push(passion);

    let calme = new Caracteristique();
    calme.nom = "Calme";
    calme.valeur = 1;
    calme.valeurMax = 8;
    calme.valeurMin = 1;
    this.loadDataService.getFileContent('../assets/caracteristiques/Calme.json').subscribe(data => calme.description = data);
    caracteristiquesEsprit.push(calme);

    let foi = new Caracteristique();
    foi.nom = "Foi";
    foi.valeur = 1;
    foi.valeurMax = 8;
    foi.valeurMin = 1;
    this.loadDataService.getFileContent('../assets/caracteristiques/Foi.json').subscribe(data => foi.description = data);
    caracteristiquesEsprit.push(foi);

    let ego = new Caracteristique();
    ego.nom = "Ego";
    ego.valeur = 1;
    ego.valeurMax = 8;
    ego.valeurMin = 1;
    this.loadDataService.getFileContent('../assets/caracteristiques/Ego.json').subscribe(data => ego.description = data);
    caracteristiquesEsprit.push(ego);

    let introverti = new Caracteristique();
    introverti.nom = "Introverti";
    introverti.valeur = 1;
    introverti.valeurMax = 8;
    introverti.valeurMin = 1;
    this.loadDataService.getFileContent('../assets/caracteristiques/Introverti.json').subscribe(data => introverti.description = data);
    caracteristiquesEsprit.push(introverti);

    let extraverti = new Caracteristique();
    extraverti.nom = "Extraverti";
    extraverti.valeur = 1;
    extraverti.valeurMax = 8;
    extraverti.valeurMin = 1;
    this.loadDataService.getFileContent('../assets/caracteristiques/Extraverti.json').subscribe(data => extraverti.description = data);
    caracteristiquesEsprit.push(extraverti);

    this.fiche.caracteristiques = caracteristiques;
    this.fiche.caracteristiquesEsprit = caracteristiquesEsprit;
  }
}
