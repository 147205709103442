import { Caracteristique } from "../commun/caracteristique";
import { Faction } from "../commun/faction";
import { QualiteDefaut } from "../commun/qualiteDefaut";
import { RaceFadingSuns } from "../race/fadingSuns/raceFadingSuns";
import { Fiche } from "./fiche";

export class FicheFad extends Fiche{
  race: RaceFadingSuns;
  caracteristiquesEsprit: Caracteristique[];
  faction: Faction;
  qualites: QualiteDefaut[];
  defauts: QualiteDefaut[];
  atouts: QualiteDefaut[];
  handicaps: QualiteDefaut[];
  pointsJoker: number = 25;
}
