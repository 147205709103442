import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.css']
})
export class SearchMenuComponent implements OnInit {

  @Output() eventFiltre = new EventEmitter<string>();

  public filtre: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  emissionFiltre(){
    this.eventFiltre.emit(this.filtre);
  }
}
