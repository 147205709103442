import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameViewComponent } from 'src/ui/game-view/game-view.component';
import { MainGamesUiComponent } from 'src/ui/main-games-ui/main-games-ui.component';
import { PageNotFoundComponent } from 'src/ui/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'mainPage', component: MainGamesUiComponent,
  children: [
    {
      path: 'game/:titre', component: GameViewComponent
    }
  ]
  },
  { path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
