import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParcoursVieService {

  private ageSubject = new Subject<number>();
  private caracteristiquesSubject = new Subject<Map<string,number>>();
  private malusSubject = new Subject<Map<string,number>>();
  private resetSubject = new Subject<boolean>();
  private doneSubject = new Subject<boolean>();

  sendAge(age: number): void {
    this.ageSubject.next(age);
  }

  getAge(): Subject<number>{
    return this.ageSubject;
  }

  sendCaracteristiques(caracteristiques: Map<string,number>): void {
    this.caracteristiquesSubject.next(caracteristiques);
  }

  getCaracteristiques(): Subject<Map<string,number>>{
    return this.caracteristiquesSubject;
  }

  sendMalus(malus: Map<string,number>): void {
    this.malusSubject.next(malus);
  }

  getMalus(): Subject<Map<string,number>>{
    return this.malusSubject;
  }

  sendReset(reset: boolean): void {
    this.resetSubject.next(reset);
  }

  getReset(): Subject<boolean>{
    return this.resetSubject;
  }

  sendDone(done: boolean): void {
    this.doneSubject.next(done);
  }

  getDone(): Subject<boolean>{
    return this.doneSubject;
  }
}
