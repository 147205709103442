import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeLineComponent } from './time-line/time-line.component';

@NgModule({
  declarations: [TimeLineComponent],
  imports: [
    CommonModule
  ],
  exports: [TimeLineComponent]
})
export class TimeLineModule { }
