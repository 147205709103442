export class Jeu {

  id: string;
  libelle: string;
  description: string;
  acronyme: string;
  nbEtapes: string;
  etapes: string[];
  divers: string[];
  raceSelectionnable: boolean;
  professionSelectionnable: boolean;
  ageLibre: boolean;
  pret: boolean;
  regle: string;
  imageJeu: string;
  ficheLien: string[];
}
