import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EtatCivilService {

  private raceSelectionneeSubject= new Subject<string>();
  private professionSelectionneeSubject= new Subject<string>();

  sendRaceSelectionnee(raceSelectionnee: string): void {
    this.raceSelectionneeSubject.next(raceSelectionnee);
  }

  getRaceSelectionnee(): Subject<string>{
    return this.raceSelectionneeSubject;
  }

  sendProfessionSelectionnee(professionSelectionnee: string): void {
    this.professionSelectionneeSubject.next(professionSelectionnee);
  }

  getProfessionSelectionnee(): Subject<string>{
    return this.professionSelectionneeSubject;
  }
}
