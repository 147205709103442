import { Caracteristique } from "../commun/caracteristique";
import { Competence } from "../commun/competence";
import { Divers } from "../commun/divers";
import { Equipement } from "../commun/equipement";
import { EtatCivil } from "../commun/etatCivil";

export class Fiche {

  fiche: string;
  etatCivil: EtatCivil;
  caracteristiques: Caracteristique[];
  competences: Competence[];
  equipement: Equipement;
  divers: Divers;
}
