<div *ngIf="jeu" id="timeLine" class="timeLine">
  <div *ngFor="let etape of jeu.etapes; let index = index" class="!mobile desktop">
    <div class="etape" [ngClass]="index != selectedIndex ? 'inactif' : 'actif'" id="etape_{{ index }}" [ngStyle]="{'width.%': widthEtape}" (click)="goToEtape(index)">
      <img src="assets/images/des.png" alt = "etape" class="diceImg">
      <span class="numeroEtape noSelect">{{ index + 1 }}</span>
      <span class="libelleEtape noSelect">{{ jeu.etapes[index] }}</span>
    </div>
  </div>
  <div class="mobile !desktop mb-2 w-100">
    <div class="etape w-100 d-inline-block mt-0 pl-3">
      <span class="d-inline-block col-2 p-0 m-0 ml-2" role="button">
        <img *ngIf="isTobeDisplayed(true)" src="assets/images/avant.png" alt = "etape" class="w-50 float-left" (click)="goToEtape(selectedIndex - 1)">
      </span>
      <span class="libelleEtape text-center super noSelect col-7 p-0 m-0">{{ jeu.etapes[selectedIndex] }}</span>
      <span class="d-inline-block col-2 p-0" role="button">
        <img *ngIf="isTobeDisplayed(false)" src="assets/images/apres.png" alt = "etape" class="w-50 float-right" (click)="goToEtape(selectedIndex + 1)">
      </span>
    </div>
  </div>
</div>
