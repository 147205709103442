import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class TimeLineService {

  private timeLineSubject= new Subject<number>();

  sendTimeLineIndex(index: number): void {
    this.timeLineSubject.next(index);
  }

  getTimeLineIndex(): Subject<number>{
    return this.timeLineSubject;
  }
}
