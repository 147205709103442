import { Directive, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appAjoutTemplate]'
})
export class AjoutTemplateDirective implements OnInit {

  constructor(public viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void { }
}
