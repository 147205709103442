import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { Competence } from 'src/modules/core/modeles/fiche/commun/competence';
import { EtatCivil } from 'src/modules/core/modeles/fiche/commun/etatCivil';
import { FicheAdc } from 'src/modules/core/modeles/fiche/fichePersonnage/ficheAdc';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { LoadDataService } from 'src/services/loadData.service';
import { AdcAleatoireService } from '../services/adcAleatoire.service';
import { AdcGeneration } from '../services/adcGeneration';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';
import { EquipementsService } from 'src/modules/shared/equipements/services/equipements.service';
import { DiversService } from 'src/modules/shared/divers/services/divers.service';
import { Divers } from 'src/modules/core/modeles/fiche/commun/divers';
import { AleatoireService } from 'src/services/aleatoire.service';
import { EtatCivilService } from 'src/modules/shared/etat-civil/services/etat-civil.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TimeLineService } from 'src/modules/shared/time-line/services/time-line.service';
import { GenerationService } from 'src/modules/shared/generation/services/generation.service';
import { CaracteristiquesService } from 'src/modules/shared/caracteristiques/services/caracteristiques.service';
import { ParcoursVieService } from 'src/modules/shared/specifique/parcoursVie/services/parcours-vie.service';
import { CompetencesService } from 'src/modules/shared/competences/services/competences.service';
import { Profession } from 'src/modules/core/modeles/fiche/profession/profession';

@Component({
  selector: 'app-adc',
  templateUrl: './adc.component.html',
  styleUrls: ['./adc.component.css']
})
export class AdcComponent implements OnInit, OnDestroy {

  public jeu: Jeu;
  public selectedIndex = 0;
  public regles: Map<string, string> = new Map();
  public fiche: FicheAdc;
  public pointsCompetences: number;
  public pointsCompetencesPro: number;
  public malusDone: boolean = false;
  public resetMalus: boolean = false;
  public prenomMasculins: string[];
  public prenomFeminins: string[];
  public malus: Map<string, number> = new Map();
  public ptDepensesPro: Map<string, number> = new Map();
  public ptDepensesGeneral: Map<string, number> = new Map();
  public ficheGeneration: string[][] = [];
  public enfantActif: string;

  private OPTION: string = "OPTION - ";
  private referentiel: Competence[] = [];
  private subscriptions: Subscription[] = [];
  private professions: Profession[] = [];

  constructor(private route: ActivatedRoute, private loadDataService: LoadDataService, private equipementsService: EquipementsService,
    private diversService: DiversService, private aleatoireService: AleatoireService, private etatCivilService: EtatCivilService, private timeLineService: TimeLineService,
    private generationService: GenerationService, private caracteristiquesService: CaracteristiquesService, private parcoursVieService: ParcoursVieService,
    private competenceService: CompetencesService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let obj = params['jeu'];
      if (obj != null) {
        this.jeu = <Jeu>JSON.parse(obj);
      }
    });

    if (null == this.fiche || undefined == this.fiche) {
      this.initFiche();
    }

    const equipementSubscription = this.equipementsService.getEquipement().subscribe({
      next: (equipementsRecu: Equipement) => {
        this.fiche.equipement = equipementsRecu;
      }
    });

    const diversSubscription = this.diversService.getDivers().subscribe({
      next: (diversRecu: Divers) => {
        this.fiche.divers = diversRecu;
      }
    });

    const aleatoireSubscription = this.aleatoireService.getAleatoire().subscribe({
      next: () => {
        this.aleatoire();
      }
    });

    const etatCivilSubscription = this.etatCivilService.getProfessionSelectionnee().subscribe({
      next: (professionSelectionneeRecu: string) => {
        this.loadProfessionCthulhu(professionSelectionneeRecu);
      }
    });

    const timeLineSubscription = this.timeLineService.getTimeLineIndex().subscribe({
      next: (selectedIndexRecu: number) => {
        this.selectedIndex = selectedIndexRecu;
      }
    });

    const generationSubscription = this.generationService.getGeneration().subscribe({
      next: () => {
        this.generation();
      }
    });

    const caracterisquesSubscription = this.caracteristiquesService.getCaracteristiques().subscribe({
      next: (caracteristiquesRecues: Caracteristique[]) => {
        this.actualisationCaracValeurs(caracteristiquesRecues);
      }
    });

    const parcoursAgeSubscription = this.parcoursVieService.getAge().subscribe({
      next: (age: number) => {
        this.fiche.etatCivil.age = age;
      }
    });

    const parcoursMalusSubscription = this.parcoursVieService.getMalus().subscribe({
      next: (malus: Map<string,number>) => {
        this.malus = malus;
        this.pointsCompetencesPro = this.calculPointsCompetencesPro();
        this.actualisationEsquive();
      }
    });

    const parcoursDoneSubscription = this.parcoursVieService.getDone().subscribe({
      next: (done: boolean) => {
        this.malusDone = done;
      }
    });

    const parcoursResetSubscription = this.parcoursVieService.getReset().subscribe({
      next: (reset: boolean) => {
        this.resetMalus = reset;
      }
    });

    const competencesPtDepensesSubscription = this.competenceService.getPtDepenses().subscribe({
      next: (ptDepenses: Map<string, Map<string, number>>) => {
        if(ptDepenses.keys().next().value === "competencesAuChoix" || ptDepenses.keys().next().value === "competencesPro"){
          this.actualisationPtDepensesPro(ptDepenses.values().next().value);
        } else {
          this.actualisationPtDepenses(ptDepenses.values().next().value);
        }
      }
    });

    const competencesPtRestantSubscription = this.competenceService.getPtRestant().subscribe({
      next: (ptRestant: Map<string,number>) => {
        if(ptRestant.keys().next().value == "competencesAuChoix" || ptRestant.keys().next().value == "competencesPro"){
          this.pointsCompetencesPro = ptRestant.values().next().value;
        }
      }
    });

    this.subscriptions.push(equipementSubscription);
    this.subscriptions.push(diversSubscription);
    this.subscriptions.push(aleatoireSubscription);
    this.subscriptions.push(etatCivilSubscription);
    this.subscriptions.push(timeLineSubscription);
    this.subscriptions.push(generationSubscription);
    this.subscriptions.push(caracterisquesSubscription);
    this.subscriptions.push(parcoursAgeSubscription);
    this.subscriptions.push(parcoursMalusSubscription);
    this.subscriptions.push(parcoursDoneSubscription);
    this.subscriptions.push(parcoursResetSubscription);
    this.subscriptions.push(competencesPtDepensesSubscription);
    this.subscriptions.push(competencesPtRestantSubscription);
  }

  initFiche() {
    this.fiche = new FicheAdc();
    this.fiche.equipement = new Equipement();
    this.fiche.equipement.description = "";
    this.fiche.etatCivil = new EtatCivil();
    this.fiche.divers = new Divers;
    this.fiche.divers.tableauDivers = new Map<string,any>();

    if (this.jeu.divers != null && this.jeu.divers != undefined) {
      this.jeu.divers.forEach(inputText => {
        this.fiche.divers.tableauDivers.set(inputText, "");
      });
    }

    this.generationCaracteristiques();
    this.generationCompetence();
    this.chargerPrenoms();
    this.fiche.malus = new Map<string, number>();
  }

  aleatoire() {

    this.initFiche();
    const service = new AdcAleatoireService(this.fiche, this.jeu, this.loadDataService, this);

    if(!this.professions || this.professions.length === 0){
      var fichierProfessions = '../assets/professions/Adc/Adc.json';
      this.loadDataService.getByAcronyme(fichierProfessions).subscribe(data => {
        this.professions = data.professions;
        service.generationAleatoire(this.professions);
      });
    } else {
      service.generationAleatoire(this.professions);
    }
  }

  generation(){
    this.ficheGeneration = new AdcGeneration(this.fiche, this.referentiel, this).generation();
  }

  chargerPrenoms() {

    const urlPrenomMasculins = '../assets/divers/prenomFrMasculin.json';
    const urlPrenomsFeminins = '../assets/divers/prenomFrFeminin.json';

    this.loadDataService.getFileContent(urlPrenomMasculins).subscribe(data => {
      this.prenomMasculins = data.split(',');
    });

    this.loadDataService.getFileContent(urlPrenomsFeminins).subscribe(data => {
      this.prenomFeminins = data.split(',');
    });
  }

  loadProfessionCthulhu(profession: string) {

    if (null == profession || undefined == profession || "" === profession) {
      return;
    }

    // Réinit
    this.fiche.competencesPro = new Array<Competence>();
    this.fiche.competencesAuChoix = new Array<Competence>();

    const fichierProfession = '../assets/professions/' + this.jeu.acronyme + '/' + profession.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '.json';

    this.loadDataService.getByAcronyme(fichierProfession).subscribe(data => {
      this.fiche.profession = data.profession[0];
      if(data.profession[0].equipement){
        this.fiche.equipement = data.profession[0].equipement;
      }
      this.fiche.nombreCompentencesChoix = data.profession[0].nombreCompentencesChoix;
      this.fiche.pointsOccupation = data.profession[0].pointsOccupation;
      this.generationCompetencePro(data.profession[0].competences);
      this.generationChoixCompetence(data.profession[0].competencesChoix);
      this.generationRegles();

      // Reinit des competences lors du changement de profession
      this.reinitCompetences();

      // Reinit des points à dépenser lors du changement de profession si pointsCompetencesPro n'est pas vide (ie il y a déjà eu des caracs tirées)
      if (this.pointsCompetencesPro) {
        this.pointsCompetencesPro = this.calculPointsCompetencesPro();
      }
    });
  }

  reinitCompetences() {

    if (this.referentiel.length == 0) {
      return;
    }

    this.fiche.competences = [];

    this.referentiel.forEach(competence => {
      let nouvelleCompetence = Object.assign({}, competence);
      this.fiche.competences.push(nouvelleCompetence);
    });

    this.actualisationEsquive();
  }

  generationRegles(): void {

    const description = this.fiche.profession.description != "" ? this.fiche.profession.description : " - ";
    this.regles.set("profesionImage", "../assets/images/professions/" + this.jeu.acronyme + "/" + this.fiche.profession.nom + ".JPG");
    this.regles.set("professionDescription", description);
    this.regles.set("tailleMin", String(120));
    this.regles.set("tailleMax", String(200));
    this.regles.set("poidsMin", String(40));
    this.regles.set("poidsMax", String(130));
  }

  generationCompetencePro(competences: string[]) {

    this.fiche.competencesPro = new Array<Competence>();
    competences.forEach(competenceNom => {
      const competenceTrouvee = this.fiche.competences.filter(competence => competence.nom == competenceNom);

      if (competenceTrouvee[0]) {
        let nouvelleCompetence = Object.assign({}, competenceTrouvee[0]);
        this.fiche.competencesPro.push(nouvelleCompetence);
      }
    });

    this.gestionCredit();
  }

  generationChoixCompetence(competences: string[]) {

    this.fiche.competencesAuChoix = new Array<Competence>();

    competences.forEach(competenceNom => {
      const competenceTrouvee = this.fiche.competences.filter(competence => competence.nom == competenceNom);
      if (competenceTrouvee[0] != null && competenceTrouvee[0] != undefined) {
        let nouvelleCompetence = Object.assign({}, competenceTrouvee[0]);
        this.fiche.competencesAuChoix.push(nouvelleCompetence);
      }
    });
  }

  generationCompetence() {
    this.fiche.competences = null;
    const fichierCompetences = '../assets/competences/' + this.jeu.acronyme + '.json';
    this.loadDataService.getByAcronyme(fichierCompetences).subscribe(data => {
      this.fiche.competences = data.competences;
      this.fiche.competences.forEach(competence => {
        let nouvelleCompetence = Object.assign({}, competence);
        this.referentiel.push(nouvelleCompetence);
      });
      this.actualisationEsquive();
    });
  }

  gestionCredit() {
    const credit = this.fiche.competences.filter(competence => competence.nom == "Crédit")[0];

    if (credit == undefined) {
      return;
    }

    credit.valeur = Number(this.fiche.profession.creditMin);
    credit.valeurMin = Number(this.fiche.profession.creditMin);
    credit.valeurMax = Number(this.fiche.profession.creditMax);
  }

  getCaracteristiqueDescription(caracteristique: Caracteristique): void {

    if (!caracteristique.description || caracteristique.description == '') {
      this.loadDataService.getFileContent('../assets/caracteristiques/' + caracteristique.nom.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '.json').subscribe(data => caracteristique.description = data);
    }
  }

  generationCaracteristiques() {

    let caracteristiques = [];

    let dexterite = new Caracteristique();
    dexterite.nom = "Dextérité";
    dexterite.typeDes = 6;
    dexterite.nombreDes = 3;
    dexterite.bonusDes = 0;
    dexterite.seuilRelance = 0;
    dexterite.valeurMin = 3;
    dexterite.valeurMax = 18;
    dexterite.multiplicateur = 5;
    caracteristiques.push(dexterite);

    let constitution = new Caracteristique();
    constitution.nom = "Constitution";
    constitution.typeDes = 6;
    constitution.nombreDes = 3;
    constitution.bonusDes = 0;
    constitution.seuilRelance = 0;
    constitution.valeurMin = 3;
    constitution.valeurMax = 18;
    constitution.multiplicateur = 5;
    caracteristiques.push(constitution);

    let apparence = new Caracteristique();
    apparence.nom = "Apparence";
    apparence.typeDes = 6;
    apparence.nombreDes = 3;
    apparence.bonusDes = 0;
    apparence.seuilRelance = 0;
    apparence.valeurMin = 3;
    apparence.valeurMax = 18;
    apparence.multiplicateur = 5;
    caracteristiques.push(apparence);

    let pouvoir = new Caracteristique();
    pouvoir.nom = "Pouvoir";
    pouvoir.typeDes = 6;
    pouvoir.nombreDes = 3;
    pouvoir.bonusDes = 0;
    pouvoir.seuilRelance = 0;
    pouvoir.valeurMin = 3;
    pouvoir.valeurMax = 18;
    pouvoir.multiplicateur = 5;
    caracteristiques.push(pouvoir);

    let taille = new Caracteristique();
    taille.nom = "Taille";
    taille.typeDes = 6;
    taille.nombreDes = 2;
    taille.bonusDes = 6;
    taille.seuilRelance = 0;
    taille.valeurMin = 8;
    taille.valeurMax = 18;
    taille.multiplicateur = 5;
    caracteristiques.push(taille);

    let intelligence = new Caracteristique();
    intelligence.nom = "Intelligence";
    intelligence.typeDes = 6;
    intelligence.nombreDes = 2;
    intelligence.bonusDes = 6;
    intelligence.seuilRelance = 0;
    intelligence.valeurMin = 8;
    intelligence.valeurMax = 18;
    intelligence.multiplicateur = 5;
    caracteristiques.push(intelligence);

    let education = new Caracteristique();
    education.nom = "Education";
    education.typeDes = 6;
    education.nombreDes = 2;
    education.bonusDes = 6;
    education.seuilRelance = 0;
    education.valeurMin = 8;
    education.valeurMax = 18;
    education.multiplicateur = 5;
    caracteristiques.push(education);

    let force = new Caracteristique();
    force.nom = "Force";
    force.typeDes = 6;
    force.nombreDes = 3;
    force.bonusDes = 0;
    force.seuilRelance = 0;
    force.valeurMin = 3;
    force.valeurMax = 18;
    force.multiplicateur = 5;
    caracteristiques.push(force);

    let chance = new Caracteristique();
    chance.nom = "Chance";
    chance.typeDes = 6;
    chance.nombreDes = 3;
    chance.bonusDes = 0;
    chance.seuilRelance = 0;
    chance.valeurMin = 3;
    chance.valeurMax = 18;
    chance.multiplicateur = 5;
    caracteristiques.push(chance);

    caracteristiques.forEach(caracteristique => { this.getCaracteristiqueDescription(caracteristique) });
    this.fiche.caracteristiques = caracteristiques;
  }

  actualisationCaracValeurs(valeurs): void {

    if (!valeurs) {
      return;
    }

    valeurs.forEach((value: number, key: string) => {
      const carac = this.fiche.caracteristiques.filter(caracteristique => caracteristique.nom == key)[0];
      if (carac) {
        carac.valeur = value;
      }
    });

    this.malus = new Map();
    this.pointsCompetences = this.fiche.caracteristiques.filter(carac => carac.nom == "Intelligence")[0].valeur * 2;
    this.resetMalus = true;
    this.pointsCompetencesPro = this.calculPointsCompetencesPro();
    this.actualisationEsquive();
  }

  actualisationEsquive() {

    // Compétence générale
    let esquive: Competence = null;
    if (this.fiche.competences) {
      esquive = this.fiche.competences.filter(comp => comp.nom == "Esquive")[0];
    }

    // Compétence pro
    let esquivePro: Competence = null;
    if (this.fiche.competencesPro) {
      esquivePro = this.fiche.competencesPro.filter(comp => comp.nom == "Esquive")[0];
    }

    // Malus
    const malus = this.getMalusFor("Dextérité");

    if (esquive) {
      esquive.valeur = esquive.valeurMin = Math.floor(((this.fiche.caracteristiques.filter(carac => carac.nom == "Dextérité")[0].valeur - malus) / 5) * 2);
    }
    if (esquivePro) {
      esquivePro.valeur = esquivePro.valeurMin = Math.floor(((this.fiche.caracteristiques.filter(carac => carac.nom == "Dextérité")[0].valeur - malus) / 5) * 2);
    }
  }

  getMalusFor(nomCarac: string): number {

    if (!this.malus) {
      return 0;
    }

    return this.malus.has(nomCarac) ? this.malus.get(nomCarac) : 0;
  }

  calculPointsCompetencesPro(): number {

    if (this.fiche.pointsOccupation == null || this.fiche.pointsOccupation == undefined) {
      return;
    }

    const tableauCarac = this.fiche.pointsOccupation[0];
    const tableauMultiplicateur = this.fiche.pointsOccupation[1];

    if (tableauCarac == null || tableauCarac.length == 0 || tableauMultiplicateur == null || tableauMultiplicateur.length == 0) {
      return 0;
    }

    // Cas simple : 1 seule caractéristique
    if (tableauCarac.length == 1) {
      const malus = this.getMalusFor(tableauCarac[0]);
      return (this.fiche.caracteristiques.filter(carac => carac.nom == tableauCarac[0])[0].valeur - malus) * Number(tableauMultiplicateur[0]);
    }

    // Détermine si il existe des OPTIONs
    const longeur = tableauCarac.length;
    let hasOption = false;

    for (let index = 0; index < longeur; index++) {
      if (tableauCarac[index].includes(this.OPTION)) {
        hasOption = true;
      }
    }

    // Cas multiple : plusieurs caractéristiques mais sans OPTION
    if (!hasOption) {
      let multipleValeurs = 0;
      for (let index = 0; index < longeur; index++) {
        const malus = this.getMalusFor(tableauCarac[index]);
        multipleValeurs += (this.fiche.caracteristiques.filter(carac => carac.nom == tableauCarac[index])[0].valeur - malus) * Number(tableauMultiplicateur[index])
      }
      return multipleValeurs;
    }

    // Cas multiple : plusieurs caratéristiques avec des OPTIONs

    // Création et remplissage des tableaux des OPTIONs
    let caracOptionIndex: string[] = [];
    let multiOptionIndex: string[] = [];

    for (let index = 0; index < longeur; index++) {
      if (tableauCarac[index].includes(this.OPTION)) {
        caracOptionIndex.push(tableauCarac[index].replace(this.OPTION, ""));
        multiOptionIndex.push(tableauMultiplicateur[index]);
      }
    }

    // Conservation de la meilleure caractéristique
    let caracRetenue: string;
    let multiplicateurRetenu: string;
    let valeurTemp: number = 0;

    for (let index = 0; index < longeur; index++) {
      let carac = this.fiche.caracteristiques.filter(carac => carac.nom == caracOptionIndex[index]);

      if (carac[0] == undefined || carac[0].valeur == undefined) {
        continue;
      }

      if (Number(carac[0].valeur) > valeurTemp) {
        valeurTemp = Number(carac[0].valeur);
        caracRetenue = caracOptionIndex[index];
        multiplicateurRetenu = multiOptionIndex[index];
      }
    }

    const valeurOption = this.fiche.caracteristiques.filter(carac => carac.nom == caracRetenue)[0].valeur * Number(multiplicateurRetenu);

    // Calcul final
    let multipleValeurs = 0;
    for (let index = 0; index < longeur; index++) {
      const malus = this.getMalusFor(tableauCarac[index]);
      let carac = this.fiche.caracteristiques.filter(carac => carac.nom == tableauCarac[index]);

      if (carac[0] == undefined) {
        continue;
      }

      multipleValeurs += (carac[0].valeur - malus) * Number(tableauMultiplicateur[index]);
    }

    return multipleValeurs + valeurOption;
  }

  actualisationPtDepensesPro(ptDepensesPro: Map<string, number>): void {
    this.actualisationSeuil(ptDepensesPro, true);
  }

  actualisationPtDepenses(ptDepenses: Map<string, number>): void {
    this.actualisationSeuil(ptDepenses, false);
  }

  actualisationSeuil(ptDepenses: any, concerneLesCompPro: boolean) {
    // Actualisation des points dépensés dans les compétences globales comme valeur de seuil pour les compétences Pro / Au choix
    // Quand c'est une modification manuelle, la valeur envoyée est somme totale envoyée : valeur comp - min référentiel
    // Quand c'est une modification par les boutons, on envoi seulement +1 ou -1
    ptDepenses.forEach((value: number, key: string) => {

      let competencePro = this.fiche.competencesPro.find(competence => competence.nom == key);

      if (!competencePro && this.fiche.competencesAuChoix && this.fiche.competencesAuChoix.length > 0) {
        competencePro = this.fiche.competencesAuChoix.find(competence => competence.nom == key);
      }

      const minReferentiel = this.referentiel.find(competence => competence.nom == key).valeurMin;
      const competenceGlobale = this.fiche.competences.find(competence => competence.nom == key);
      const seuil = value + minReferentiel > minReferentiel ? value + minReferentiel : minReferentiel;

      if (competenceGlobale && competencePro) {
        if(concerneLesCompPro){
          if(value === 1){
            competenceGlobale.valeurMin += value;
          } else{
            competenceGlobale.valeurMin = seuil
          }
          competenceGlobale.valeur = competencePro.valeur;
        } else {
          if(value === 1){
            competencePro.valeurMin += value;
          } else{
            competencePro.valeurMin = seuil;
          }
          competencePro.valeur = competenceGlobale.valeur;
        }
      }
    });
  }
}
