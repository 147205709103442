import { Competence } from "src/modules/core/modeles/fiche/commun/competence";
import { FicheAdc } from "src/modules/core/modeles/fiche/fichePersonnage/ficheAdc";
import { GenerationHelper } from "src/modules/core/utilitaires/generationHelper";
import { AdcComponent } from "../adc/adc.component";

export class AdcGeneration {

  private ficheGeneration: string[][] = [];
  private fiche: FicheAdc;
  private referentiel: Competence[];
  private adcComponent: AdcComponent;

  constructor(fiche: FicheAdc, referentiel: Competence[], adcComponent: AdcComponent) {
    this.fiche = fiche;
    this.referentiel = referentiel;
    this.adcComponent = adcComponent;
  }

  generation(): string[][] {

    this.ficheGeneration[0] = [];
    this.ficheGeneration[0].push("nom#" + GenerationHelper.safeReturn(this.fiche.etatCivil.nom).substring(0, 17));
    this.ficheGeneration[0].push("prenom#" + GenerationHelper.safeReturn(this.fiche.etatCivil.prenom).substring(0, 17));
    let nomFiche = GenerationHelper.safeReturn(GenerationHelper.safeReturn(this.fiche.etatCivil.nom) + " " + GenerationHelper.safeReturn(this.fiche.etatCivil.prenom));
    if (nomFiche.length <= 1) {
      nomFiche = "FichePersonnageAppelDeCthulhu";
    }
    this.ficheGeneration[0].push("nomFiche#" + nomFiche);

    // avatar : width 138 / height 170
    if (GenerationHelper.safeReturn(this.fiche.etatCivil.avatar).length != 0) {
      this.ficheGeneration[0].push("avatar#140&174!" + GenerationHelper.safeReturn(this.fiche.etatCivil.avatar));
    }

    this.ficheGeneration[0].push("age#" + GenerationHelper.getD100Value(this.fiche.etatCivil.age));
    this.ficheGeneration[0].push("sexe#" + GenerationHelper.safeReturn(this.fiche.etatCivil.sexe));
    this.ficheGeneration[0].push("profession#" + GenerationHelper.safeReturn(this.fiche.etatCivil.profession));
    this.ficheGeneration[0].push("naissance#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Lieu de naissance").substring(0, 24)));
    this.ficheGeneration[0].push("residence#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Résidence").substring(0, 29)));
    this.ficheGeneration[0].push("santeMentale#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[3].valeur));
    this.ficheGeneration[0].push("pointMagie#" + GenerationHelper.getArrondiInferieur(this.fiche.caracteristiques[3].valeur / 5));
    this.ficheGeneration[0].push("pointDeVie#" + GenerationHelper.getArrondiInferieur((this.fiche.caracteristiques[4].valeur + this.fiche.caracteristiques[1].valeur) / 10));
    this.ficheGeneration[0].push("mouvement#" + this.calculerMouvement());
    this.ficheGeneration[0].push("impact#" + this.calculerImpactEtCarrure(true));
    this.ficheGeneration[0].push("carrure#" + this.calculerImpactEtCarrure(false));
    this.ficheGeneration[0].push("dexterite#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[0].valeur - this.adcComponent.getMalusFor("Dextérité")));
    this.ficheGeneration[0].push("constitution#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[1].valeur - this.adcComponent.getMalusFor("Constitution")));
    this.ficheGeneration[0].push("apparence#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[2].valeur - this.adcComponent.getMalusFor("Apparence")));
    this.ficheGeneration[0].push("pouvoir#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[3].valeur));
    this.ficheGeneration[0].push("taille#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[4].valeur));
    this.ficheGeneration[0].push("intelligence#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[5].valeur));
    this.ficheGeneration[0].push("education#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[6].valeur - this.adcComponent.getMalusFor("Education")));
    this.ficheGeneration[0].push("force#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[7].valeur - this.adcComponent.getMalusFor("Force")));
    this.ficheGeneration[0].push("chance#" + GenerationHelper.getD100Value(this.fiche.caracteristiques[8].valeur - this.adcComponent.getMalusFor("Chance")));

    this.generationFicheCompetence();

    // Page 2
    this.ficheGeneration[1] = [];
    this.ficheGeneration[1].push("description1#" + GenerationHelper.safeReturn(this.fiche.etatCivil.description).substring(0, 43));
    this.ficheGeneration[1].push("description2#" + GenerationHelper.safeReturn(this.fiche.etatCivil.description).substring(42, 52));
    this.ficheGeneration[1].push("biens1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Biens précieux").substring(0, 41)));
    this.ficheGeneration[1].push("biens2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Biens précieux").substring(40, 52)));
    this.ficheGeneration[1].push("ideologie1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Idéologie et croyances").substring(0, 35)));
    this.ficheGeneration[1].push("ideologie2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Idéologie et croyances").substring(34, 52)));
    this.ficheGeneration[1].push("lieux1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Lieux significatifs").substring(0, 39)));
    this.ficheGeneration[1].push("lieux2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Lieux significatifs").substring(38, 52)));
    this.ficheGeneration[1].push("ouvrages1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Ouvrages occultes, sorts et artefacts").substring(0, 24)));
    this.ficheGeneration[1].push("ouvrages2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Ouvrages occultes, sorts et artefacts").substring(23, 52)));
    this.ficheGeneration[1].push("personnes1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Personnes importantes").substring(0, 34)));
    this.ficheGeneration[1].push("personnes2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Personnes importantes").substring(33, 52)));
    this.ficheGeneration[1].push("phobies1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Phobies et manies").substring(0, 38)));
    this.ficheGeneration[1].push("phobies2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Phobies et manies").substring(37, 52)));
    this.ficheGeneration[1].push("rencontres1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Rencontres avec des entités étranges").substring(0, 24)));
    this.ficheGeneration[1].push("rencontres2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Rencontres avec des entités étranges").substring(23, 52)));
    this.ficheGeneration[1].push("traits1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Traits").substring(0, 47)));
    this.ficheGeneration[1].push("traits2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Traits").substring(46, 52)));
    this.ficheGeneration[1].push("sequelles1#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Séquelles et cicatrices").substring(0, 36)));
    this.ficheGeneration[1].push("sequelles2#" + GenerationHelper.safeReturn(this.fiche.divers.tableauDivers.get("Séquelles et cicatrices").substring(35, 52)));

    this.ficheGeneration[1].push("equipement1#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(0, 72));
    this.ficheGeneration[1].push("equipement2#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(72, 72));
    this.ficheGeneration[1].push("equipement3#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(144, 72));
    this.ficheGeneration[1].push("equipement4#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(216, 72));
    this.ficheGeneration[1].push("equipement5#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(288, 72));
    this.ficheGeneration[1].push("equipement6#" + GenerationHelper.safeReturn(this.fiche.equipement.description).substring(360, 72));

    const note = "Taille : " + this.fiche.etatCivil.taille + " cm / Poids : " + this.fiche.etatCivil.poids + " kg";
    this.ficheGeneration[1].push("note#" + GenerationHelper.safeReturn(note));

    this.generationCredit();

    return this.ficheGeneration;
  }

  generationCredit() {
    const credit = Number(this.fiche.competences.filter(competence => competence.nom == "Crédit")[0].valeur);
    if (isNaN(credit) || credit == null || credit == undefined) {
      return;
    }

    if (credit == 0) {
      this.ficheGeneration[1].push("especes#" + GenerationHelper.safeReturn("0.50$"));
      this.ficheGeneration[1].push("capital#" + GenerationHelper.safeReturn("Aucun"));
      this.ficheGeneration[1].push("depenses#" + GenerationHelper.safeReturn("0.50$"));
    } else if (credit > 0 && credit < 10) {
      this.ficheGeneration[1].push("especes#" + GenerationHelper.safeReturn(credit + "$"));
      this.ficheGeneration[1].push("capital#" + GenerationHelper.safeReturn((credit * 10) + "$"));
      this.ficheGeneration[1].push("depenses#" + GenerationHelper.safeReturn("2$"));
    } else if (credit > 9 && credit < 50) {
      this.ficheGeneration[1].push("especes#" + GenerationHelper.safeReturn((credit * 2) + "$"));
      this.ficheGeneration[1].push("capital#" + GenerationHelper.safeReturn((credit * 50) + "$"));
      this.ficheGeneration[1].push("depenses#" + GenerationHelper.safeReturn("10$"));
    } else if (credit > 49 && credit < 90) {
      this.ficheGeneration[1].push("especes#" + GenerationHelper.safeReturn((credit * 5) + "$"));
      this.ficheGeneration[1].push("capital#" + GenerationHelper.safeReturn((credit * 500) + "$"));
      this.ficheGeneration[1].push("depenses#" + GenerationHelper.safeReturn("50$"));
    } else if (credit > 89 && credit < 99) {
      this.ficheGeneration[1].push("especes#" + GenerationHelper.safeReturn((credit * 20) + "$"));
      this.ficheGeneration[1].push("capital#" + GenerationHelper.safeReturn((credit * 2000) + "$"));
      this.ficheGeneration[1].push("depenses#" + GenerationHelper.safeReturn("250$"));
    } else if (credit > 99) {
      this.ficheGeneration[1].push("especes#" + GenerationHelper.safeReturn("50 000$"));
      this.ficheGeneration[1].push("capital#" + GenerationHelper.safeReturn("5 millions ou plus de $"));
      this.ficheGeneration[1].push("depenses#" + GenerationHelper.safeReturn("5000$"));
    }
  }

  generationFicheCompetence() {

    // Récupération des différentes valeurs des compétences Pro
    if(this.fiche.competencesPro.length > 0){
      this.fiche.competencesPro.forEach(competence => {
        const comp = this.fiche.competences.filter(compGenerale => compGenerale.nom == competence.nom)[0];
        if (competence.valeur > comp.valeur) {
          comp.valeur = competence.valeur;
        }
      })
    }

    // Récupération des différentes valeurs des compétences au choix
    if(this.fiche.competencesAuChoix.length > 0){
      this.fiche.competencesAuChoix.forEach(competence => {
        const comp = this.fiche.competences.filter(compGenerale => compGenerale.nom == competence.nom)[0];
        if (competence.valeur > comp.valeur) {
          comp.valeur = competence.valeur;
        }
      })
    }

    let surplus: string[] = [];
    const competenceExcluesGenerationDirecteArtEtMetier = ["Agriculture", "Barbier", "Beaux-arts", "Calligraphie", "Chant", "Charpenterie", "Comédie", "Confection", "Contrefaçon", "Cordonnerie", "Cuisine", "Dactylographie", "Danse", "Danse traditionnelle", "Dessin technique", "Dressage", "Ebénisterie", "Instrument", "Littérature", "Opéra", "Peinture et décoration", "Pickpocket", "Plongée", "Photographie", "Poterie", "Sculpture"];
    const competenceExcluesGenerationDirecteCombatRapproche = ["Epée", "Fléaux", "Fouet", "Garrots", "Haches", "Lances", "Tronçonneuse"];
    const competenceExcluesGenerationDirecteCombatDistance = ["Arcs", "Armes lourdes", "Artillerie", "Lances-flammes", "Mitraillettes", "Mitrailleuses"];
    const competenceExcluesGenerationDirecteScience = ["Astronomie", "Biologie", "Botanique", "Chimie", "Criminalistique", "Cryptographie", "Géologie", "Hypnose", "Ingénierie", "Mathématique", "Météorologie", "Pharmacologie", "Physique", "Zoologie"];
    const competenceExcluesGenerationDirectePilotage = ["Avions", "Bateaux"];
    const competenceExcluesGenerationDirecteDivers = ["Explosif", "Lecture sur les lèvres"];
    const globale = [];
    globale.concat(competenceExcluesGenerationDirecteArtEtMetier, competenceExcluesGenerationDirecteCombatRapproche, competenceExcluesGenerationDirecteCombatDistance, competenceExcluesGenerationDirecteScience, competenceExcluesGenerationDirectePilotage, competenceExcluesGenerationDirecteDivers);

    this.fiche.competences.forEach(competence => {

      // Check si valeur a été modifiée
      const referentiel = this.referentiel.filter(ref => ref.nom == competence.nom)[0];

      if (competence.valeur == referentiel.valeurMin) {
        return;
      }

      // Ne pas prendre en compte les arts et métiers, combat à distance, combat rapproché, survie
      if (globale.indexOf(competence.nom) > 0) {
        return;
      }

      const nomCompetence = GenerationHelper.supprimerLesEspaces(competence.nom.toLowerCase());

      // Generation de sa valeur
      if (competence.valeur > 1) {
        this.ficheGeneration[0].push(nomCompetence + "#" + GenerationHelper.getD100Value(competence.valeur));
      }

      // Generation de sa moitie
      this.ficheGeneration[0].push(nomCompetence + "2#" + GenerationHelper.getArrondiInferieur(competence.valeur / 2));

      // Generation de son cinquième
      this.ficheGeneration[0].push(nomCompetence + "5#" + GenerationHelper.getArrondiInferieur(competence.valeur / 5));
    })

    // Esquive bis
    const esquive = this.fiche.competences.filter(competence => competence.nom == "Esquive")[0];
    this.ficheGeneration[0].push("esquivebis#" + GenerationHelper.getArrondiInferieur(esquive.valeur));
    this.ficheGeneration[0].push("esquivebis2#" + GenerationHelper.getArrondiInferieur(esquive.valeur / 2));
    this.ficheGeneration[0].push("esquivebis5#" + GenerationHelper.getArrondiInferieur(esquive.valeur / 5));

    // Langue maternelle
    this.ficheGeneration[0].push("languematernelle#" + GenerationHelper.getArrondiInferieur(this.fiche.caracteristiques[6].valeur));
    this.ficheGeneration[0].push("languematernelle2#" + GenerationHelper.getArrondiInferieur(this.fiche.caracteristiques[6].valeur / 2));
    this.ficheGeneration[0].push("languematernelle5#" + GenerationHelper.getArrondiInferieur(this.fiche.caracteristiques[6].valeur / 5));

    // Arts et métiers
    Array.prototype.push.apply(surplus, this.generationCompetencesVariables(competenceExcluesGenerationDirecteArtEtMetier, "artsetmetiers", 3));

    // Combat à distance
    Array.prototype.push.apply(surplus, this.generationCompetencesVariables(competenceExcluesGenerationDirecteCombatDistance, "armedistance", 2));

    // Combat rapproche
    Array.prototype.push.apply(surplus, this.generationCompetencesVariables(competenceExcluesGenerationDirecteCombatRapproche, "armerapproche", 2));

    // Pilotage
    Array.prototype.push.apply(surplus, this.generationCompetencesVariables(competenceExcluesGenerationDirectePilotage, "pilotage", 1));

    // Sciences
    Array.prototype.push.apply(surplus, this.generationCompetencesVariables(competenceExcluesGenerationDirecteScience, "sciences", 3));

    // Surplus
    Array.prototype.push.apply(surplus, competenceExcluesGenerationDirecteDivers);

    this.generationCompetencesVariables(surplus, "surplus", 5);

    // Ligne chance
    if (this.fiche.caracteristiques[8].valeur < 29) {
      const X = ((this.fiche.caracteristiques[8].valeur - 1) * 2 * 15.4) + 335;
      this.ficheGeneration[0].push(GenerationHelper.creerUneLigne(255, 0, 0, X, 523, 15));
    } else if (this.fiche.caracteristiques[8].valeur > 28 && this.fiche.caracteristiques[8].valeur < 65) {
      const X = ((this.fiche.caracteristiques[8].valeur - 29) * 2 * 15.4) + 70;
      this.ficheGeneration[0].push(GenerationHelper.creerUneLigne(255, 0, 0, X, 546, 15));
    } else if (this.fiche.caracteristiques[8].valeur > 64) {
      const X = ((this.fiche.caracteristiques[8].valeur - 65) * 2 * 15.4) + 70;
      this.ficheGeneration[0].push(GenerationHelper.creerUneLigne(255, 0, 0, X, 569, 15));
    }
  }

  generationCompetencesVariables(competenceArray: string[], nomLocalise: string, nombreMax: number): any[] {

    let index = 0;
    let surplus: string[] = [];

    competenceArray.forEach(nomCompetence => {

      const comp = this.fiche.competences.filter(competence => competence.nom == nomCompetence)[0];

      if (comp == null || comp == undefined) {
        return;
      }

      // Vérification de la valeur par rapport au référentiel
      const referentiel = this.referentiel.filter(ref => ref.nom == comp.nom)[0];

      // Check si valeur a été modifiée
      if (comp.valeur == referentiel.valeurMin) {
        return;
      }

      if (index < nombreMax) {
        index += 1;
        this.ficheGeneration[0].push(index + "nom" + nomLocalise + "#" + GenerationHelper.safeReturn(comp.nom));
        this.ficheGeneration[0].push(index + nomLocalise + "#" + GenerationHelper.getArrondiInferieur(comp.valeur));
        this.ficheGeneration[0].push(index + nomLocalise + "2#" + GenerationHelper.getArrondiInferieur(comp.valeur / 2));
        this.ficheGeneration[0].push(index + nomLocalise + "5#" + GenerationHelper.getArrondiInferieur(comp.valeur / 5));
      } else {
        surplus.push(comp.nom);
      }
    });

    return surplus;
  }


  calculerMouvement() {

    const force = this.fiche.caracteristiques[7].valeur;
    const dexterite = this.fiche.caracteristiques[0].valeur;
    const taille = this.fiche.caracteristiques[4].valeur;
    const age = this.fiche.etatCivil.age;
    const base = force + dexterite < taille ? 7 : force > taille && dexterite > taille ? 9 : 8;
    let malus = 0;

    if (age > 40 && age < 50) {
      malus = 1;
    } else if (age > 49 && age < 60) {
      malus = 2;
    } else if (age > 59 && age < 70) {
      malus = 3;
    } else if (age > 69 && age < 80) {
      malus = 4;
    } else if (age > 79) {
      malus = 5;
    }

    return (base - malus).toString();
  }

  calculerImpactEtCarrure(impact: boolean): string {

    const total = this.fiche.caracteristiques[7].valeur + this.fiche.caracteristiques[4].valeur;

    if (total < 65) {
      return "-2";
    } else if (total > 64 && total < 85) {
      return "-1";
    } else if (total > 84 && total < 125) {
      return "0";
    } else if (total > 124 && total < 165) {
      if (impact) {
        return "+1D4";
      } else {
        return "+1";
      }
    } else if (total > 164 && total < 205) {
      if (impact) {
        return "+1D6";
      } else {
        return "+2";
      }
    } else if (total > 204 && total < 285) {
      if (impact) {
        return "+2D6";
      } else {
        return "+3";
      }
    }
  }
}
