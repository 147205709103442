import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AjoutTemplateDirective } from 'src/modules/core/directives/ajout-template.directive';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { AdcComponent } from 'src/modules/jeux/cthulhu/adc/adc.component';
import { FadComponent } from 'src/modules/jeux/fadingsuns/fad/fad.component';
import { StaComponent } from 'src/modules/jeux/starwars/sta/sta.component';
import { AleatoireService } from 'src/services/aleatoire.service';
import { PageNotFoundComponent } from 'src/ui/page-not-found/page-not-found.component';

@Component({
  selector: 'app-game-view',
  templateUrl: './game-view.component.html',
  styleUrls: ['./game-view.component.css']
})
export class GameViewComponent implements OnInit {

  @ViewChild(AjoutTemplateDirective) appAjoutTemplate: AjoutTemplateDirective;
  @Input() jeu: Jeu;

  private createdComponentRef: ComponentRef<unknown>;
  private components = [AdcComponent, StaComponent, FadComponent, PageNotFoundComponent];

  constructor(private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver, private firstRef: ViewContainerRef, private aleatoireService: AleatoireService) {  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let obj = params['jeu'];
      if(obj!=null){
        this.jeu = <Jeu> JSON.parse(obj);
        this.loadComponent();
      }
    });
  }

  private loadComponent(){

    // Destruction des anciens composants chargés
    if(this.createdComponentRef != null && this.createdComponentRef != undefined){
      this.createdComponentRef.destroy();
    }

    // Création du nouveau composant
    if(this.jeu != null && this.jeu != undefined){
      let composant = this.getComponent(this.jeu.acronyme + "Component");
      if(composant == null){
        return;
      }

      if(this.appAjoutTemplate == null && this.appAjoutTemplate == undefined){
        this.appAjoutTemplate = new AjoutTemplateDirective(this.firstRef);
      }

      this.appAjoutTemplate.viewContainerRef.clear();
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(composant as any);
      const viewContainerRef = this.appAjoutTemplate.viewContainerRef;
      viewContainerRef.clear();
      this.createdComponentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  getComponent(acronyme){

    let composant;

    this.components.forEach(function (value) {
      if(value.name == acronyme) {
        composant = value;
      }
    });

    if(composant == null || composant == undefined){
      composant = PageNotFoundComponent;
    }

    return composant;
  }

  generationAleatoire(): void {
    this.createdComponentRef.instance['selectedIndex'] = 0;
    this.aleatoireService.sendAleatoire(true);
  }
}
