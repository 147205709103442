import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GameViewComponent } from './game-view/game-view.component';
import { GamesMenuComponent } from './games-menu/games-menu.component';
import { HeaderComponent } from './header/header.component';
import { MainGamesUiComponent } from './main-games-ui/main-games-ui.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainTemplatesComponent } from 'src/ui/main-templates/main-templates.component';
import { SearchMenuComponent } from 'src/ui/search-menu/search-menu.component';

@NgModule({
  declarations: [
    MainGamesUiComponent,
    PageNotFoundComponent,
    GameViewComponent,
    GamesMenuComponent,
    HeaderComponent,
    MainTemplatesComponent,
    SearchMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    MainGamesUiComponent,
    PageNotFoundComponent,
    GameViewComponent,
    GamesMenuComponent,
    HeaderComponent,
    MainTemplatesComponent,
    SearchMenuComponent
  ]
})
export class UiModule { }
