import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CaracteristiquesModule } from '../../caracteristiques/caracteristiques.module';
import { ForceComponent } from './force/force.component';

@NgModule({
  declarations: [ForceComponent],
  imports: [
    CommonModule,
    CaracteristiquesModule,
    FormsModule
  ],
  exports: [ForceComponent]
})
export class ForceModule { }
