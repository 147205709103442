import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ParcoursVieComponent } from './parcoursVie/parcoursVie.component';

@NgModule({
  declarations: [ParcoursVieComponent],
  imports: [
    CommonModule
  ],
  exports: [ParcoursVieComponent]
})
export class ParcoursVieModule { }
