import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Jeu } from 'src/modules/core/modeles/jeu';

@Component({
  selector: 'app-games-menu',
  templateUrl: './games-menu.component.html',
  styleUrls: ['./games-menu.component.css']
})
export class GamesMenuComponent implements OnInit {

  @Input() listeLettrines: string[];
  @Input() listeJeux: Jeu[];
  @Input() filtre: string;

  hideOnMobile: boolean;

  listeJeuxTriee = new Map<string, Jeu[]>();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.importEtTri();
    this.hideOnMobile = true;
  }

  importEtTri() {

    // Pour chaque lettrine
    this.listeLettrines.forEach( lettrine => {

      // Pour chaque jeux
      this.listeJeux.forEach( jeu => {

        // Si la première lettre du jeu correspond à la lettrine
        if(lettrine == jeu.libelle.substring(0,1)){

          if(this.listeJeuxTriee.get(lettrine) != undefined){

            // Si la liste triée contient déjà un tableau pour cette lettrine, on ajoute le jeu au tableau existant
            var tableau = this.listeJeuxTriee.get(lettrine);
            tableau.push(jeu);
          } else {

            // Si la liste triée ne contient pas déjà un tableau pour cette lettrine, on ajoute un nouveau tableau avec le jeu
            var tableau: Jeu[];
            tableau = [jeu];
            this.listeJeuxTriee.set(lettrine,tableau);
          }
        }
      })
    })
  }

  showOrHide(event, index) {

    const nom = index + 'jeu';

    if(event.target.className == 'lettrineShow'){

      event.target.className = 'lettrineHide';
      document.getElementById(nom).style.display = "none";
    }else {

      event.target.className = 'lettrineShow';
      document.getElementById(nom).style.display = "block";
    }
  }

  redirectTo(jeu){
    this.router.navigate(['/game'],  { queryParams: { jeu : JSON.stringify(jeu) }, skipLocationChange: true });
    if(window.innerWidth <= 600){
      this.hideOnMobile = false;
    }
  }
}
