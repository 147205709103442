<div class="col-md-8">

  <div class="col-md-12 displayBlock">
    <div class="displayBlock col-md-12">
      <div class="noSelect col-md-3 displayElement titre pr-0">
        <label for="labelRace">Choisissez votre faction : </label>
        <span class="tooltiptext onRight noSelect">Sélectionnez une faction pour en avoir une description</span>
        <div *ngIf="factionSelectionnee" class="displayOnMobile">
          <div class="input-group-prepend noSelect titre">
            <span class="info">?</span>
            <span class="tooltiptext onRight noSelect">{{factionSelectionnee.description}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-8 displayElement pl-0">
        <select tabindex="1" type="text" class="form-control col-md-6 displayElement" placeholder="Faction" [(ngModel)]="factionSelectionnee" (change)="onOptionsFactionSelected()" required>
          <option></option>
          <optgroup *ngFor="let alliance of factionsTriees | keyvalue" label="{{alliance.key}}">
            <option *ngFor="let faction of alliance.value" [ngValue]="faction">{{faction.nom}}</option>
          </optgroup>
        </select>
        <div *ngIf="factionSelectionnee" class="displayElement col-md-5">
          <p class="font-weight-bold">{{factionSelectionnee.nom}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 displayBlock">
    <div *ngIf="factionSelectionnee" class="col-md-12 mt-2 input-group noDisplay">
        <div class="input-group-prepend noSelect titre">
          <span class="input-group-text">En bref</span>
          <span class="tooltiptext onRight noSelect">D'où vous venez, qui vous entoure, un peu d'histoire sur vous et les votres</span>
        </div>
        <textarea tabindex="9" class="form-control textAreaSize col-md-12" id="inputDescription" aria-label="With textarea" readonly="true">
          {{factionSelectionnee.description}}
        </textarea>
        <img src="assets/images/specifique/Fad/factions/{{factionSelectionnee.nom}}.jpg" alt="Faction" class="faction ml-2">
    </div>
  </div>

  <div *ngIf="factionSelectionnee" class="col-md-12 displayBlock mt-2">
    <div class="col-md-3 displayElement noSelect titre text-center">
      <label class="font-weight-bold text-center">ATOUT</label>
      <span class="info">?</span>
      <span class="tooltiptext onRight noSelect">Vous avez 10 pts de base à dépenser en atout plus des pts joker si vous le souhaitez</span>
    </div>
    <div class="col-md-3 displayElement displayOnMobile mb-2">
      <select tabindex="2" type="text" class="form-control" placeholder="atouts" [(ngModel)]="atoutSelectionne" (change)="onOptionsAtoutSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of atoutsTries | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let atout of groupe.value" [ngValue]="atout">{{atout.nom}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-3 displayElement noSelect titre text-center">
      <label class="font-weight-bold text-center">HANDICAP</label>
      <span class="info">?</span>
      <span class="tooltiptext onRight noSelect">Chaque handicap va vous octroyer des points jokers</span>
    </div>
    <div class="col-md-3 displayElement displayOnMobile mb-2">
      <select tabindex="3" type="text" class="form-control" placeholder="handicaps" [(ngModel)]="handicapSelectionne" (change)="onOptionsHandicapSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of handicapsTries | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let handicap of groupe.value" [ngValue]="handicap">{{handicap.nom}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-3 displayElement noSelect titre text-center">
      <label class="font-weight-bold text-center">QUALITE</label>
      <span class="info">?</span>
      <span class="tooltiptext onRight noSelect">Vous ne pouvez être parfait, 7 qualités maximum</span>
    </div>
    <div class="col-md-3 displayElement displayOnMobile mb-2">
      <select tabindex="4" type="text" class="form-control" placeholder="qualités" [(ngModel)]="qualiteSelectionnee" (change)="onOptionsQualiteSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of qualitesTriees | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let qualite of groupe.value" [ngValue]="qualite">{{qualite.nom}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-3 displayElement noSelect titre text-center">
      <label class="font-weight-bold text-center">DEFAUT</label>
      <span class="info">?</span>
      <span class="tooltiptext onRight noSelect">Vous ne pouvez être complétement nul, 7 pts de défaut maximum</span>
    </div>
    <div class="col-md-3 displayElement displayOnMobile mb-2">
      <select tabindex="5" type="text" class="form-control" placeholder="defauts" [(ngModel)]="defautSelectionne" (change)="onOptionsDefautSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of defautsTries | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let defaut of groupe.value" [ngValue]="defaut">{{defaut.nom}}</option>
        </optgroup>
      </select>
    </div>
  </div>

  <div *ngIf="factionSelectionnee" class="col-md-12 displayBlock mt-2">
    <div class="col-md-3 displayElement noDisplayOnMobile">
      <select tabindex="2" type="text" class="form-control" placeholder="atouts" [(ngModel)]="atoutSelectionne" (change)="onOptionsAtoutSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of atoutsTries | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let atout of groupe.value" [ngValue]="atout">{{atout.nom}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-3 displayElement noDisplayOnMobile">
      <select tabindex="3" type="text" class="form-control" placeholder="handicaps" [(ngModel)]="handicapSelectionne" (change)="onOptionsHandicapSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of handicapsTries | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let handicap of groupe.value" [ngValue]="handicap">{{handicap.nom}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-3 displayElement noDisplayOnMobile">
      <select tabindex="4" type="text" class="form-control" placeholder="qualités" [(ngModel)]="qualiteSelectionnee" (change)="onOptionsQualiteSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of qualitesTriees | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let qualite of groupe.value" [ngValue]="qualite">{{qualite.nom}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-3 displayElement noDisplayOnMobile">
      <select tabindex="5" type="text" class="form-control" placeholder="defauts" [(ngModel)]="defautSelectionne" (change)="onOptionsDefautSelected()">
        <option></option>
        <optgroup *ngFor="let groupe of defautsTries | keyvalue" label="{{groupe.key}}">
          <option *ngFor="let defaut of groupe.value" [ngValue]="defaut">{{defaut.nom}}</option>
        </optgroup>
      </select>
    </div>
  </div>

  <div class="col-md-12 displayBlock mt-2 selection">
    <div #qualiteAffichee *ngFor="let qualite of qualitesSelectionnees let index = index" class="displayElement ml-1">
      <div class="inlineBlock titre noSelect">
        <p id="qualite{{index}}" class="inlineBlock qualite qualiteDefaut p-2" (click)="suppressionQualite(qualite); qualiteAffichee.remove()">{{qualite.nom}}</p>
        <span class="tooltipGen noSelect">{{qualite.cout}}pt / {{qualite.description}}</span>
      </div>
    </div>
    <div #atoutAffiche *ngFor="let atout of atoutsSelectionnes let index = index" class="displayElement ml-1">
      <div class="inlineBlock titre noSelect">
        <p id="atout{{index}}" class="inlineBlock atout qualiteDefaut p-2" (click)="suppressionAtout(atout); atoutAffiche.remove()">{{atout.nom}}</p>
        <span class="tooltipGen noSelect">{{atout.cout}}pt / {{atout.description}}</span>
      </div>
    </div>
    <div #defautAffiche *ngFor="let defaut of defautsSelectionnes let index = index" class="displayElement ml-1">
      <div class="inlineBlock titre noSelect">
        <p id="defaut{{index}}" class="inlineBlock defaut qualiteDefaut p-2" (click)="suppressionDefaut(defaut); defautAffiche.remove()">{{defaut.nom}}</p>
        <span class="tooltipGen noSelect">{{defaut.cout}}pt / {{defaut.description}}</span>
      </div>
    </div>
    <div #handicapAffiche *ngFor="let handicap of handicapsSelectionnes let index = index" class="displayElement ml-1">
      <div class="inlineBlock titre noSelect">
        <p id="handicap{{index}}" class="inlineBlock handicap qualiteDefaut p-2" (click)="suppressionHandicap(handicap); handicapAffiche.remove()">{{handicap.nom}}</p>
        <span class="tooltipGen noSelect">{{handicap.cout}}pt / {{handicap.description}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="factionSelectionnee" class="col-md-12 displayBlock mt-2">
    <div class="col-6 text-center font-weight-bold displayElement">
      <p class="text">Point d'atouts restant : {{10 - this.totalPtAtoutDepense >= 0 ? 10 - this.totalPtAtoutDepense : 0}} pt</p>
    </div>
    <div class="col-6 text-center font-weight-bold displayElement">
      <p class="text">Point joker restant : {{this.pointsJoker}} pt</p>
    </div>
  </div>
</div>
