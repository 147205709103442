import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { PouvoirForce } from 'src/modules/core/modeles/fiche/commun/pouvoirForce';
import { GenerationHelper } from 'src/modules/core/utilitaires/generationHelper';
import { CaracteristiquesService } from 'src/modules/shared/caracteristiques/services/caracteristiques.service';
import { LoadDataService } from 'src/services/loadData.service';
import { ForceService } from '../services/force.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-force',
  templateUrl: './force.component.html',
  styleUrls: ['./force.component.css']
})
export class ForceComponent implements OnInit, OnDestroy {

  @Input() ptCaracteristiques: number;
  @Input() caracteristiques: Caracteristique[];
  @Input() seuil: number;
  @Input() sensible: boolean;
  @Input() pouvoirsForce: PouvoirForce[];

  public ptRestantCarac: number;
  public nombrePouvoirsSens: number;
  public nombrePouvoirsControle: number;
  public nombrePouvoirsAlteration: number;
  public pouvoirSelectionne: PouvoirForce;
  public pouvoirsTries = new Map();
  public totalNombrePouvoir: number;
  public ptPouvoirDepenseSens = 0;
  public ptPouvoirDepenseControle = 0;
  public ptPouvoirDepenseAlteration = 0;

  private subscriptions: Subscription[] = [];

  constructor(private loadDataService: LoadDataService, private caracteristiquesService: CaracteristiquesService, private forceService: ForceService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {

    this.nombrePouvoirsAlteration = 0;
    this.nombrePouvoirsControle = 0;
    this.nombrePouvoirsSens = 0;
    this.ptPouvoirDepenseSens = 0;
    this.ptPouvoirDepenseControle = 0;
    this.ptPouvoirDepenseAlteration = 0;
    this.ptRestantCarac = this.ptCaracteristiques;
    this.generationPouvoirs();

    const caracterisquesPtRestantSubscription = this.caracteristiquesService.getPointRestant().subscribe({
      next: (ptRestantRecu: number) => {
        this.ptRestantCarac = ptRestantRecu;
        this.eligibleAuxPouvoirs();
      }
    }
    );

    this.subscriptions.push(caracterisquesPtRestantSubscription);
  }

  gestionSensibiliteForce() {
    this.ptRestantCarac = this.ptCaracteristiques;

    // Si passage à non sensible : vérifier les points dépenser -> les rajouter et remettre à 0 les carac
    if (!this.sensible) {

      this.caracteristiques.forEach(caracteristique => {

        this.ptRestantCarac += caracteristique.valeur;
        caracteristique.valeur = 0;
      })

      this.ptCaracteristiques = this.ptRestantCarac;

      this.caracteristiquesService.sendPointRestant(this.ptRestantCarac);
      this.eligibleAuxPouvoirs();
      this.pouvoirsForce = new Array();
      this.ptPouvoirDepenseSens = 0;
      this.ptPouvoirDepenseControle = 0;
      this.ptPouvoirDepenseAlteration = 0;
    }

    this.forceService.sendSensibilite(this.sensible);
  }

  eligibleAuxPouvoirs() {
    this.caracteristiques.forEach(caracteristique => {
      let valeur = caracteristique.valeur; // > 0 ? Math.trunc((Number(caracteristique.valeur)/3)) : 0;
      if (caracteristique.nom === "Sens") {
        this.nombrePouvoirsSens = valeur;
      } else if (caracteristique.nom === "Contrôle") {
        this.nombrePouvoirsControle = valeur;
      } else {
        this.nombrePouvoirsAlteration = valeur;
      }
    });
  }

  nombreTotalPouvoir() {

    this.totalNombrePouvoir = this.nombrePouvoirsSens
      + this.nombrePouvoirsControle
      + this.nombrePouvoirsAlteration
      - this.ptPouvoirDepenseAlteration
      - this.ptPouvoirDepenseControle
      - this.ptPouvoirDepenseSens;

    if (this.totalNombrePouvoir >= 0) {
      return this.totalNombrePouvoir;
    } else {

      if (this.pouvoirsForce.length > 0) {
        for (let i = 0; i < this.pouvoirsForce.length; i++) {
          const element: HTMLElement = document.getElementById('pouvoirForce' + i) as HTMLElement;
          element.click();
        }
        this.pouvoirsForce = new Array();
        this.nombreTotalPouvoir();
      } else {
        return 0;
      }
    }
  }

  generationPouvoirs() {

    const fichierPouvoirs = '../assets/pouvoirs/pouvoirForce.json';
    let pouvoirs = new Array();

    this.loadDataService.getByAcronyme(fichierPouvoirs).subscribe(data => {
      pouvoirs = data.pouvoirForce;

      // Tri par caractèristiques
      pouvoirs.forEach(pouvoir => {

        if (this.pouvoirsTries.has(pouvoir.caracteristique)) {
          let pouv: PouvoirForce[];
          pouv = this.pouvoirsTries.get(pouvoir.caracteristique)
          pouv.push(pouvoir);
        } else {
          let pouv = new Array();
          pouv.push(pouvoir);
          this.pouvoirsTries.set(pouvoir.caracteristique, pouv);
        }
      })
    });
  }

  pouvoirsForceContains(pouvoirNom): boolean {

    for (var i = 0; i < this.pouvoirsForce.length; i++) {
      if (this.pouvoirsForce[i].nom == pouvoirNom) {
        return true;
      }
    }

    return false;
  }

  addPouvoir() {

    if (this.pouvoirSelectionne == null || this.pouvoirSelectionne == undefined || !this.pouvoirSelectionne) {
      return;
    }

    if (!this.pouvoirsForce) {
      this.pouvoirsForce = new Array();
    }

    if (this.pouvoirsForceContains(this.pouvoirSelectionne.nom)) {
      return;
    }

    if (this.pouvoirSelectionne.caracteristique == "Controle") {

      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsControle - this.ptPouvoirDepenseControle) {
        return;
      } else {
        this.ptPouvoirDepenseControle += 1;
      }
    } else if (this.pouvoirSelectionne.caracteristique == "Sens") {
      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsSens - this.ptPouvoirDepenseSens) {
        return;
      } else {
        this.ptPouvoirDepenseSens += 1;
      }
    } else if (this.pouvoirSelectionne.caracteristique == "Alteration") {
      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsAlteration - this.ptPouvoirDepenseAlteration) {
        return;
      } else {
        this.ptPouvoirDepenseAlteration += 1;
      }
    } else if (this.pouvoirSelectionne.caracteristique == "Controle-Sens") {
      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsControle + this.nombrePouvoirsSens - this.ptPouvoirDepenseControle - this.ptPouvoirDepenseSens) {
        return;
      } else {
        this.ptPouvoirDepenseControle += 1;
        this.ptPouvoirDepenseSens += 1;
      }
    } else if (this.pouvoirSelectionne.caracteristique == "Controle-Alteration") {
      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsControle + this.nombrePouvoirsAlteration - this.ptPouvoirDepenseControle - this.ptPouvoirDepenseAlteration) {
        return;
      } else {
        this.ptPouvoirDepenseControle += 1;
        this.ptPouvoirDepenseAlteration += 1;
      }
    } else if (this.pouvoirSelectionne.caracteristique == "Sens-Alteration") {
      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsSens + this.nombrePouvoirsAlteration - this.ptPouvoirDepenseSens - this.ptPouvoirDepenseAlteration) {
        return;
      } else {
        this.ptPouvoirDepenseSens += 1;
        this.ptPouvoirDepenseAlteration += 1;
      }
    } else if (this.pouvoirSelectionne.caracteristique == "Controle-Sens-Alteration") {
      if (this.pouvoirSelectionne.cout > this.nombrePouvoirsSens + this.nombrePouvoirsAlteration + this.nombrePouvoirsControle - this.ptPouvoirDepenseControle - this.ptPouvoirDepenseSens - this.ptPouvoirDepenseAlteration) {
        return;
      } else {
        this.ptPouvoirDepenseSens += 1;
        this.ptPouvoirDepenseAlteration += 1;
        this.ptPouvoirDepenseControle += 1;
      }
    }

    this.pouvoirsForce.push(this.pouvoirSelectionne);
    this.forceService.sendPouvoirs(this.pouvoirsForce);
  }

  suppressionPouvoir(pouvoir: PouvoirForce) {

    if (pouvoir.caracteristique == "Controle") {

      this.ptPouvoirDepenseControle -= 1
    } else if (pouvoir.caracteristique == "Sens") {

      this.ptPouvoirDepenseSens -= 1;
    } else if (pouvoir.caracteristique == "Alteration") {

      this.ptPouvoirDepenseAlteration -= 1;
    } else if (pouvoir.caracteristique == "Controle-Sens") {

      this.ptPouvoirDepenseControle -= 1;
      this.ptPouvoirDepenseSens -= 1;

    } else if (pouvoir.caracteristique == "Controle-Alteration") {

      this.ptPouvoirDepenseControle -= 1;
      this.ptPouvoirDepenseAlteration -= 1;
    } else if (pouvoir.caracteristique == "Sens-Alteration") {

      this.ptPouvoirDepenseSens -= 1;
      this.ptPouvoirDepenseAlteration -= 1;
    } else if (pouvoir.caracteristique == "Controle-Sens-Alteration") {

      this.ptPouvoirDepenseSens -= 1;
      this.ptPouvoirDepenseAlteration -= 1;
      this.ptPouvoirDepenseControle -= 1;
    }

    const index = this.pouvoirsForce.indexOf(pouvoir);
    this.pouvoirsForce.splice(index, 1);
    this.forceService.sendPouvoirs(this.pouvoirsForce);
  }
}
