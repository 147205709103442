<div class="paddingBlanc15 noSelect col-md-12">
  <div class="fondStandard bordsArrondis">
    <div class="noSelect titre d-inline-block">
      <img src="assets/images/Pc_icon.jpg" class="bordsArrondisIcon padding15 d-inline-block picSize">
      <label class="title d-inline-block">Personnage Creator</label>
      <span class="tooltiptext onRight noSelect w-50">Un site fait en France pour des francophones ! Oui, toi belle mamie du Cantal tu vas pouvoir gratuitement réaliser tes rêves les plus fous !
        Générer un PDF de ta fiche de personnage pour ton jeu préfère avec plein de choses qu'elles sont bonnes dedans.</span>
    </div>
    <div class="float-right">
      <a href="https://www.decados.fr">
        <img src="assets/images/home.png" class="bordsArrondisIcon padding15 picSize cursor">
      </a>
      <img src="assets/images/copyright.png" class="bordsArrondisIcon padding15 picSize cursor noDisplyOnMobile" (click)="copyright()">
      <a [href]="emailInfos" target="blank">
        <img src="assets/images/email.png" class="bordsArrondisIcon padding15 picSize"/>
      </a>
    </div>
  </div>
</div>
