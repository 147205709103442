import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class GenerationService {

  private generationSubject = new Subject<string>();

  sendGeneration(generation: string): void {
    this.generationSubject.next(generation);
  }

  getGeneration(): Subject<string>{
    return this.generationSubject;
  }
}
