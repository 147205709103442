import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { PouvoirForce } from 'src/modules/core/modeles/fiche/commun/pouvoirForce';

@Injectable({
  providedIn: 'root'
})
export class ForceService {

  private sensibiliteSubject= new Subject<boolean>();
  private pouvoirsSubject= new Subject<PouvoirForce[]>();

  sendSensibilite(sensibilite: boolean): void {
    this.sensibiliteSubject.next(sensibilite);
  }

  getSensibilite(): Subject<boolean>{
    return this.sensibiliteSubject;
  }

  sendPouvoirs(pouvoirs: PouvoirForce[]): void {
    this.pouvoirsSubject.next(pouvoirs);
  }

  getPouvoirs(): Subject<PouvoirForce[]>{
    return this.pouvoirsSubject;
  }
}
