<div *ngIf="caracteristiques" class="displayElement col-md-8 noOverFlow">
  <div *ngIf="seuil > 0" class="ptCarac">
    <span class="textPtCarac">Point à répartir : {{ptRestant}}</span>
  </div>
  <div *ngIf="seuil <= 0" class="ptCarac centrage">
    <span class="textPtCarac">Cliquez sur le dé et croisez les doigts : </span>
    <img src="assets/images/desLancer.png" alt="Lancer de dès" class="affichageInline lancer m-0" (click)="lancer()">
  </div>
  <div class="fullWidth" [ngClass]="this.caracteristiques.length > 8 ? 'scrollCarac' : ''">
    <div *ngFor="let caracteristique of caracteristiques" class="affichageInline quarante">
      <div class="caracteristique">
        <div class="affichageInline noSelect titre">
          <p class="cartouche">{{caracteristique.nom}}</p>
          <span class="tooltiptext noSelect">{{caracteristique.description}}</span>
        </div>
        <!-- Si il existe des points à répartir : système à points -->
        <div *ngIf="seuil > 0" class="affichageInline affichageValeur">
          <p class="affichageInline tailleValeur">{{affichageCarac(caracteristique)}}</p>
          <p class="actionCarac affichageInline" (click)="modifPt(caracteristique,true)">+</p>
          <p class="actionCarac affichageInline moins" (click)="modifPt(caracteristique,false)">-</p>
        </div>
        <!-- Si il n'existe pas de point à répartir : système à dés -->
        <div *ngIf="seuil <= 0 " class="affichageInline affichageValeur jauge">
          <p class="affichageInline tailleValeurGeneree align-text-bottom">{{affichageCarac(caracteristique)}}</p>
          <img id="{{caracteristique.nom}}_ImgJauge" src="assets/images/curseurJauge.png" alt="Jauge" class="affichageInline curseurJauge">
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="caracteristiques && afficherIllustration" class="floatRight col-md-4 verticalBottom">
  <img src="assets/images/composants/caracteristiques.jpg" alt="Caractéristiques" class="imageCaracteristique">
</div>
