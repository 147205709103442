import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CaracteristiquesModule } from './caracteristiques/caracteristiques.module';
import { CompetencesModule } from './competences/competences.module';
import { DiversModule } from './divers/divers/divers.module';
import { EquipementsModule } from './equipements/equipements.module';
import { EtatCivilModule } from './etat-civil/etat-civil.module';
import { FooterModule } from './footer/footer.module';
import { GenerationModule } from './generation/generation/generation.module';
import { ForceModule } from './specifique/force/force.module';
import { ParcoursVieModule } from './specifique/parcoursVie/parcoursVie.module';
import { SpinnerModule } from './spinner/spinner.module';
import { TimeLineModule } from './time-line/time-line.module';
import { FactionModule } from './specifique/faction/faction.module';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    TimeLineModule,
    EtatCivilModule,
    CaracteristiquesModule,
    CompetencesModule,
    FooterModule,
    SpinnerModule,
    ParcoursVieModule,
    ForceModule,
    GenerationModule,
    FooterModule,
    EquipementsModule,
    DiversModule
  ],
  exports: [
    TimeLineModule,
    EtatCivilModule,
    CaracteristiquesModule,
    CompetencesModule,
    FooterModule,
    SpinnerModule,
    ParcoursVieModule,
    ForceModule,
    GenerationModule,
    FooterModule,
    EquipementsModule,
    DiversModule,
    FactionModule
  ]
})
export class SharedModule { }
