import { Competence } from "../commun/competence";
import { ProfessionCthulhu } from "../profession/cthulhu/professionCthulhu";
import { Fiche } from "./fiche";

export class FicheAdc extends Fiche {

  profession: ProfessionCthulhu;
  competencesAuChoix: Competence[];
  competencesPro: Competence[];
  malus: Map<string,number>;
  nombreCompentencesChoix: number;
  pointsOccupation: string[];
}
