import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiversComponent } from './divers/divers.component';



@NgModule({
  declarations: [DiversComponent],
  imports: [
    CommonModule
  ],
  exports: [DiversComponent]
})
export class DiversModule { }
