import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AleatoireService {

  private aleatoireSubject= new Subject<boolean>();

  sendAleatoire(aleatoire: boolean): void {
    this.aleatoireSubject.next(aleatoire);
  }

  getAleatoire(): Subject<boolean>{
    return this.aleatoireSubject;
  }
}
