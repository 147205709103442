import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerationComponent } from './generation/generation.component';

@NgModule({
  declarations: [GenerationComponent],
  imports: [
    CommonModule
  ],
  exports: [GenerationComponent]
})
export class GenerationModule { }
