import { Component, Input, OnInit } from '@angular/core';
import { Equipement } from 'src/modules/core/modeles/fiche/commun/equipement';
import { EquipementsService } from '../services/equipements.service';

@Component({
  selector: 'app-equipements',
  templateUrl: './equipements.component.html',
  styleUrls: ['./equipements.component.css']
})
export class EquipementsComponent implements OnInit{

  @Input() equipement: Equipement;

  constructor(private equipementsService: EquipementsService) { }

  ngOnInit(): void {
    if(!this.equipement){
      this.equipement = new Equipement();
      this.equipement.description = "";
    }
  }

  updateEquipement(event: Event): void{
    this.equipement.description = (event.target as any).value;
    this.equipementsService.sendEquipement(this.equipement);
  }
}
