<div class="marginDebut col-md-8" *ngIf="affichagePoints">
  <p class="textPtComp affichageInline">Points à répartir : {{ptRestant}}</p>
  <p *ngIf="nombreMaxChoixCompetences > 0" class="textPtComp affichageInline noMarginNoPadding"> et {{this.nombreMaxChoixCompetences}} compétences au choix</p>
</div>
<!-- Sélection des compétences -->
<div id="cumulAvecChoix" *ngIf="!toutAfficher" class="marginComp col-md-8">
  <div class="affichageInline marginBlockCompetence soixanteQuinze">
    <span class="inlineBlock">Sélectionner une compétence à ajouter : </span>
    <select *ngIf="encoreDispo()" [(ngModel)]="competenceSelectionnee" type="text" class="form-control inlineBlock menuComp" id="selectComp" (change)="addComp()">
      <option></option>
      <optgroup *ngFor="let carac of competencesTriees | keyvalue" label="{{carac.key}}">
          <option *ngFor="let competence of carac.value" [ngValue]="competence">{{competence.nom}}</option>
        </optgroup>
    </select>
  </div>
</div>

<!-- Affichage des compétences -->
<!-- Tout afficher : NON - Cumul : OUI -->
<div id="affichageCompSelectionnees" *ngIf="!toutAfficher && cumul" class="soixanteQuinze marginComp" [ngClass]="defineMaxHeight(competencesSelectionnees)">
  <div #competenceAffichee *ngFor="let competence of competencesSelectionnees" class="affichageInline quaranteCinq">
    <div class="affichageInline fullWidth">
      <div class="competence">
        <div class="affichageInline noSelect titre cinquanteDeux">
          <p class="cartouche" [ngClass]="competence.nom.length > 16 ? (competence.nom.length > 20 ? 'font12' : 'font14') : 'font16'">{{competence.nom}}</p>
          <span class="tooltiptext onRight noSelect">{{competence.description}}</span>
        </div>
        <!-- Si il existe des points à répartir : système à points -->
        <!-- D6 -->
        <div *ngIf="systemeDeJeu == 'D6'" class="affichageInline affichageValeur quaranteCinqComp">
          <p class="affichageInline tailleValeur">{{affichageComp(competence)}}</p>
          <p class="actionComp affichageInline" (click)="ajoutPt(competence)">+</p>
          <p class="actionComp affichageInline moins marginLeft15" (click)="retraitPt(competence)">-</p>
        </div>
      </div>
    </div>
    <!-- Suppression de la compétence -->
    <div class="deleteBtn">
      <img src="assets/images/corbeille.svg" (click)="suppressionComp(competence); competenceAffichee.remove()">
    </div>
  </div>
</div>
<!-- Tout afficher : NON - Cumul : NON -->
<div id="affichageCompSelectionnees" *ngIf="!toutAfficher && !cumul" class="soixanteQuinze marginComp" [ngClass]="defineMaxHeight(competencesSelectionnees)">
  <div #competenceAffichee *ngFor="let competence of competencesSelectionnees" class="affichageInline quaranteCinq">
    <div class="affichageInline fullWidth">
      <div class="competence">
        <div class="affichageInline noSelect titre cinquanteDeux">
          <p class="cartouche" [ngClass]="competence.nom.length > 16 ? (competence.nom.length > 20 ? 'font12' : 'font14') : 'font16'">{{competence.nom}}</p>
          <span class="tooltiptext onRight noSelect">{{competence.description}}</span>
        </div>
        <!-- Si il existe des points à répartir : système à points -->
        <!-- D100 -->
        <div *ngIf="systemeDeJeu == 'D100'" class="affichageInline affichageValeur quaranteCinqComp">
          <input type="text" maxlength="2" class="affichageInline tailleValeurInput" (focusout)="gestionValeurEcrite($event, competence)" (keydown)="validateNumberKey($event)" value="{{affichageComp(competence)}}"/>
          <p class="actionComp affichageInline" (click)="ajoutPt(competence)">+</p>
          <p class="actionComp affichageInline moins marginLeft15" (click)="retraitPt(competence)">-</p>
        </div>
      </div>
    </div>
    <!-- Suppression de la compétence -->
    <div class="deleteBtn">
      <img src="assets/images/corbeille.svg" (click)="suppressionComp(competence); competenceAffichee.remove()">
    </div>
  </div>
</div><i class="fa fa-server" aria-hidden="true"></i>
<!-- Tout afficher : OUI - Cumul : NON -->
<div id="affichageCompSelectionnees" *ngIf="toutAfficher && !cumul" class="soixanteQuinze marginComp" [ngClass]="defineMaxHeight(competences)">
  <div #competenceAffichee *ngFor="let competence of competences" class ="affichageInline quaranteCinq">
    <div class ="affichageInline fullWidth">
      <div class="competence">
        <div class="affichageInline noSelect titre cinquanteDeux">
          <p class="cartouche" [ngClass]="competence.nom.length > 16 ? (competence.nom.length > 20 ? 'font12' : 'font14') : 'font16'">{{competence.nom}}</p>
          <span class="tooltiptext onRight noSelect">{{competence.description}}</span>
        </div>
        <!-- Si il existe des points à répartir : système à points -->
        <!-- D100 -->
        <div *ngIf="systemeDeJeu == 'D100'" class="affichageInline affichageValeur quaranteCinqComp">
          <input type="text" maxlength="2" class="affichageInline tailleValeurInput" (focusout)="gestionValeurEcrite($event, competence)" (keydown)="validateNumberKey($event)" value="{{affichageComp(competence)}}" />
          <p class="actionComp affichageInline" (click)="ajoutPt(competence)">+</p>
          <p class="actionComp affichageInline moins marginLeft15" (click)="retraitPt(competence)">-</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="afficherIllustration">
  <img src="assets/images/composants/competences.jpg" alt="Les compétences" class="imageCompetences"/>
</div>
