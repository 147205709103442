export class EtatCivil {

  nom: string;
  prenom: string;
  age: number;
  sexe: string;
  race: string;
  taille: number;
  poids: number;
  profession: string;
  description: string;
  avatar: string;
}
