import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { Des } from 'src/modules/core/utilitaires/des';
import { CaracteristiquesService } from '../services/caracteristiques.service';

@Component({
  selector: 'app-caracteristiques',
  templateUrl: './caracteristiques.component.html',
  styleUrls: ['./caracteristiques.component.css']
})
export class CaracteristiquesComponent implements OnChanges, OnInit {

  @Input() ptCaracteristiques: number;
  @Input() caracteristiques: Caracteristique[];
  @Input() seuil: number;
  @Input() D6: boolean;
  @Input() afficherIllustration: boolean;

  public ptRestant: number;

  constructor(private caracteristiquesService: CaracteristiquesService) { }

  ngOnInit(): void {
    this.caracteristiquesService.sendPointRestant(this.ptRestant);
  }

  ngOnChanges() {
    if(this.caracteristiques){
      this.ptRestant = this.ptCaracteristiques;
    }
  }

  affichageCarac(caracteristique: Caracteristique){

    if(!caracteristique.valeur){
      return 0;
    }

    if(this.seuil > 0){
      return this.affichageValeurDistribuee(caracteristique);
    } else {
      return this.affichageValeurGeneree(caracteristique);
    }
  }

  rotationJauge(valeurMin: number, valeurMax: number, valeur: number, nom: string, multiplicateur: number){

    let multi = 1;

    if(multiplicateur != 0){
      multi = multiplicateur;
    }

    const pas = 180 / (valeurMax * multi - valeurMin * multi);
    const angle = (valeur - valeurMin * multi) * pas;
    if(document.getElementById(nom + "_ImgJauge")){
      document.getElementById(nom + "_ImgJauge").style.transform = "rotate(" + angle + "deg)";
    }
  }

  affichageValeurGeneree(caracteristique: Caracteristique){
    this.rotationJauge(caracteristique.valeurMin, caracteristique.valeurMax, caracteristique.valeur, caracteristique.nom, caracteristique.multiplicateur);
    return Number(caracteristique.valeur);
  }

  affichageValeurDistribuee(caracteristique: Caracteristique){

    let valeur = Number(caracteristique.valeur);

    if(valeur < caracteristique.valeurMin){
      valeur = caracteristique.valeurMin;
    }

    if(!this.D6){
      return valeur;
    }

    if(valeur % 3 === 0){
      return valeur/3 + "D";
    }

    const modulo = (valeur / 3) - Math.floor(valeur/3);

    if(modulo < 0.5){
      return Math.floor(valeur/3) + "D+1";
    } else {
      return Math.floor(valeur/3) + "D+2";
    }
  }

  modifPt(caracteristique: Caracteristique, ajout: boolean): void{

    let valeur = Number(caracteristique.valeur);

    if(ajout){
      const valeurMax = Number(caracteristique.valeurMax);

      if(this.ptRestant > 0 && valeur < valeurMax){
        this.ptRestant -= 1;
        valeur = valeur + 1;
      }
    } else {
      const valeurMin= Number(caracteristique.valeurMin);

      if(this.ptRestant < this.seuil && valeur > valeurMin){
        this.ptRestant += 1;
        valeur -= 1;
      }
    }

    caracteristique.valeur = valeur;
    this.caracteristiquesService.sendPointRestant(this.ptRestant);
    this.caracteristiquesService.sendCaracteristiques(this.caracteristiques);
  }

  lancer(){
    this.caracteristiques.forEach(caracteristique =>{
      caracteristique.valeur = Des.lancer(caracteristique.typeDes,caracteristique.nombreDes,caracteristique.seuilRelance,caracteristique.bonusDes, caracteristique.multiplicateur);
    });
    this.caracteristiquesService.sendCaracteristiques(this.caracteristiques);
  }
}
