export class Des {

  static lancer(typeDes: number, nombreDes: number, seuilRelance: number, bonus: number, multiplicateur: number){

    let resultat;

    do{
      resultat = this.lancementGlobal(typeDes, nombreDes, bonus, multiplicateur);
    }while(resultat < seuilRelance);

    return resultat;
  }

  static aleatoire(borneMin: number, borneMax: number){
    return Math.floor(Math.random() * (borneMax - borneMin + 1)) + borneMin;
  }

  private static lancementGlobal(typeDes: number, nombreDes: number, bonus: number, multiplicateur: number): number{

    let resultat = bonus;

    for(let i = 1; i <= nombreDes; i++){
      resultat += this.lancementUnitaire(typeDes);
    }

    if(multiplicateur != 0){
      resultat = resultat * multiplicateur;
    }

    return resultat;
  }

  private static lancementUnitaire(typeDes: number): number{
    return Math.floor(Math.random() * typeDes ) + 1;
  }

  private static lancementBorne(typeDes: number, bonus: number, seuil: number): number{
    return (Math.floor(Math.random() * (typeDes - seuil + 1) ) + seuil) + bonus;
  }
}
