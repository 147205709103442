import { Component, Input, OnInit } from '@angular/core';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { LoadDataService } from 'src/services/loadData.service';

@Component({
  selector: 'app-main-templates',
  templateUrl: './main-templates.component.html',
  styleUrls: ['./main-templates.component.css']
})
export class MainTemplatesComponent implements OnInit {

  @Input() listeLettrines: string[];
  @Input() listeJeux: Jeu[] =  [];

  public donneesChargees: Promise<boolean>;
  public filtreRecherche: string;

  constructor(private loadDataService: LoadDataService) {
    this.listeLettrines=['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
   }

  ngOnInit() {
    this.importJeux();
  }

  importJeux(){
    const fichierJeux = '../assets/jeux/jeux.json'
    this.loadDataService.getByAcronyme(fichierJeux).subscribe(data => {
      this.listeJeux = data.jeux;
      this.donneesChargees = Promise.resolve(true);
    });
  }

  updateFiltre(filtre: string){
    this.filtreRecherche = filtre;
  }
}
