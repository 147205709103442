<div class="col-md-12">
  <div class="ptCarac marginTop30">
    <input class="form-check-input checkForce" type="checkbox" [(ngModel)]="sensible" (change)="gestionSensibiliteForce()" checked="sensible">
    <label class="form-check-label textPtCarac" for="checkForce">
      Sensibilité à la Force
    </label>
  </div>
  <div *ngIf="sensible" id="caracForce" class="caracForce">
    <app-caracteristiques [seuil]="seuil" [ptCaracteristiques]="ptCaracteristiques" [D6]="true" [caracteristiques]="caracteristiques" [afficherIllustration]="false"></app-caracteristiques>
  </div>
  <div *ngIf="sensible" class="marginLeftPouvoir col-md-8">
    <div class="inlineBlock noSelect titre">
      <p class="textPtCarac inlineBlock marginLeftSelection">Nombre de pouvoirs sélectionnables : </p>
      <p class="textPtPouvoir inlineBlock">{{nombreTotalPouvoir()}}</p>
      <span class="tooltiptext onRight noSelect width15">
        <p>{{nombrePouvoirsAlteration - ptPouvoirDepenseAlteration}} pouvoirs d'altération.</p>
        <p>{{nombrePouvoirsControle - ptPouvoirDepenseControle}} pouvoirs de contrôle.</p>
        <p>{{nombrePouvoirsSens - ptPouvoirDepenseSens}} pouvoirs de sens.</p>
      </span>
    </div>
    <!-- Sélection des compétences -->
    <div *ngIf="totalNombrePouvoir > 0" class="inlineBlock verticalSuper width24 titre">
      <select [(ngModel)]="pouvoirSelectionne" type="text" class="form-control width65" id="selectPouvoir" (change)="addPouvoir()">
        <option></option>
        <optgroup *ngFor="let carac of pouvoirsTries | keyvalue" label="{{carac.key}}">
          <option *ngFor="let pouvoir of carac.value" [ngValue]="pouvoir">{{pouvoir.nom}}</option>
        </optgroup>
      </select>
    </div>
    <!-- Affichage des pouvoirs -->
    <div *ngIf="pouvoirsForce && pouvoirsForce.length > 0">
      <div #pouvoirAffichee *ngFor="let pouvoir of pouvoirsForce let index = index" class="inlineBlock marginLeft10">
          <div class="inlineBlock titre noSelect">
            <p id="pouvoirForce{{index}}" [ngClass]="pouvoir.caracteristique" class="inlineBlock pouvoir" (click)="suppressionPouvoir(pouvoir); pouvoirAffichee.remove()">{{pouvoir.nom}}</p>
            <span [ngClass]="pouvoir.caracteristique" class="tooltipPouvoir onRight noSelect">{{pouvoir.description}}</span>
          </div>
      </div>
    </div>
  </div>
</div>
<div>
  <img src="assets/images/composants/force.jpg" alt="Force" class="imageForce">
</div>
