import { Component, Input, OnInit } from '@angular/core';
import { Faction } from 'src/modules/core/modeles/fiche/commun/faction';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { LoadDataService } from 'src/services/loadData.service';
import { FactionService } from '../services/faction.service';
import { QualiteDefaut } from 'src/modules/core/modeles/fiche/commun/qualiteDefaut';

@Component({
  selector: 'app-faction',
  templateUrl: './faction.component.html',
  styleUrls: ['./faction.component.css']
})
export class FactionComponent implements OnInit {

  @Input() jeu: Jeu;
  @Input() factionSelectionnee: Faction;
  @Input() qualitesSelectionnees: QualiteDefaut[];
  @Input() defautsSelectionnes: QualiteDefaut[];
  @Input() atoutsSelectionnes: QualiteDefaut[];
  @Input() handicapsSelectionnes: QualiteDefaut[];
  @Input() pointsJoker: number;

  public factionsTriees = new Map();
  public qualitesTriees = new Map();
  public defautsTries = new Map();
  public atoutsTries = new Map();
  public handicapsTries = new Map();
  public qualiteSelectionnee: QualiteDefaut;
  public defautSelectionne: QualiteDefaut;
  public atoutSelectionne: QualiteDefaut;
  public handicapSelectionne: QualiteDefaut;
  public totalPtAtoutDepense: number = 0;

  constructor(private loadDataService: LoadDataService, private factionService: FactionService) { }

  ngOnInit(): void {
    this.loadFaction();
    this.loadQualite();
    this.loadDefault();
    this.loadAtout();
    this.loadHandicap();
    this.calculPoints();
    console.log("INIT this.factionSelectionnee : " + this.factionSelectionnee);
  }

  loadAtout(): void {
    const fichierAtouts = '../assets/atouts/' + this.jeu.acronyme + '.json';
    let ensemble = new Array();

    this.loadDataService.getByAcronyme(fichierAtouts).subscribe(data => {
      ensemble = data.atouts;

      // Tri par groupe d'atouts
      ensemble.forEach(atout => {
        let nbClasses = Object.keys(atout).length;
        for (let i=0; i<= nbClasses - 1; i++){
          const groupe = Object.keys(atout)[i];
          const atoutsRecup = this.getAtouts(Object.values(atout)[i]);
          if (this.atoutsTries.has(groupe)) {
            let atouts = this.atoutsTries.get(groupe)
            atouts.push(atoutsRecup);
          } else {
            this.atoutsTries.set(Object.keys(atout)[i], atoutsRecup);
          }
        }
      })
    });
  }

  loadQualite(): void {
    const fichierQualites = '../assets/qualites/' + this.jeu.acronyme + '.json';
    let ensemble = new Array();

    this.loadDataService.getByAcronyme(fichierQualites).subscribe(data => {
      ensemble = data.qualites;

      // Tri par groupe de qualités
      ensemble.forEach(qualite => {
        let nbClasses = Object.keys(qualite).length;
        for (let i=0; i<= nbClasses - 1; i++){
          const groupe = Object.keys(qualite)[i];
          const qualitesRecup = this.getQualites(Object.values(qualite)[i]);
          if (this.qualitesTriees.has(groupe)) {
            let qualites = this.qualitesTriees.get(groupe)
            qualites.push(qualitesRecup);
          } else {
            this.qualitesTriees.set(Object.keys(qualite)[i], qualitesRecup);
          }
        }
      })
    });
  }

  loadDefault(): void {
    const fichierDefaults = '../assets/defauts/' + this.jeu.acronyme + '.json';
    let ensemble = new Array();

    this.loadDataService.getByAcronyme(fichierDefaults).subscribe(data => {
      ensemble = data.defauts;

      // Tri par groupe de défauts
      ensemble.forEach(defaut => {
        let nbClasses = Object.keys(defaut).length;
        for (let i=0; i<= nbClasses - 1; i++){
          const groupe = Object.keys(defaut)[i];
          const defautsRecup = this.getDefauts(Object.values(defaut)[i]);
          if (this.defautsTries.has(groupe)) {
            let defauts = this.defautsTries.get(groupe)
            defauts.push(defautsRecup);
          } else {
            this.defautsTries.set(Object.keys(defaut)[i], defautsRecup);
          }
        }
      })
    });
  }

  loadHandicap(): void {
    const fichierHandicaps = '../assets/handicaps/' + this.jeu.acronyme + '.json';
    let ensemble = new Array();

    this.loadDataService.getByAcronyme(fichierHandicaps).subscribe(data => {
      ensemble = data.handicaps;

      // Tri par groupe d'handicaps
      ensemble.forEach(handicap => {
        let nbClasses = Object.keys(handicap).length;
        for (let i=0; i<= nbClasses - 1; i++){
          const groupe = Object.keys(handicap)[i];
          const handicapsRecup = this.getHandicaps(Object.values(handicap)[i]);
          if (this.handicapsTries.has(groupe)) {
            let handicaps = this.handicapsTries.get(groupe)
            handicaps.push(handicapsRecup);
          } else {
            this.handicapsTries.set(Object.keys(handicap)[i], handicapsRecup);
          }
        }
      })
    });
  }

  loadFaction(): void {
    const fichierFactions = '../assets/specifiques/' + this.jeu.acronyme + '/factions.json';
    let ensemble = new Array();

    this.loadDataService.getByAcronyme(fichierFactions).subscribe(data => {
      ensemble = data.factions;

      // Tri par faction
      ensemble.forEach(faction => {
        let nbClasses = Object.keys(faction).length;
        for (let i=0; i<= nbClasses - 1; i++){
          const groupe = Object.keys(faction)[i];
          const factionsRecup = this.getFactions(Object.values(faction)[i]);

          if (this.factionsTriees.has(groupe)) {
            let factions = this.factionsTriees.get(groupe)
            factions.push(factionsRecup);
          } else {
            this.factionsTriees.set(Object.keys(faction)[i], factionsRecup);
          }
        }
      })
    });
  }

  getHandicaps(groupeObject: Object): QualiteDefaut[] {
    const handicaps: QualiteDefaut[] = [];
    const nbOjects = Object.keys(groupeObject).length;

    for (let i = 0; i<= nbOjects - 1; i++){
      const obj = Object.values(groupeObject)[i];
      const handicap: QualiteDefaut = new QualiteDefaut();
      handicap.nom = obj.nom;
      handicap.description = obj.description;
      handicap.cout = obj.cout;
      handicaps.push(handicap);
    }

    return handicaps;
  }

  getAtouts(groupeObject: Object): QualiteDefaut[] {
    const atouts: QualiteDefaut[] = [];
    const nbOjects = Object.keys(groupeObject).length;

    for (let i = 0; i<= nbOjects - 1; i++){
      const obj = Object.values(groupeObject)[i];
      const atout: QualiteDefaut = new QualiteDefaut();
      atout.nom = obj.nom;
      atout.description = obj.description;
      atout.cout = obj.cout;
      atouts.push(atout);
    }

    return atouts;
  }

  getQualites(groupeObject: Object): QualiteDefaut[] {
    const qualites: QualiteDefaut[] = [];
    const nbOjects = Object.keys(groupeObject).length;

    for (let i = 0; i<= nbOjects - 1; i++){
      const obj = Object.values(groupeObject)[i];
      const qualite: QualiteDefaut = new QualiteDefaut();
      qualite.nom = obj.nom;
      qualite.description = obj.description;
      qualite.cout = obj.cout;
      qualites.push(qualite);
    }

    return qualites;
  }

  getDefauts(groupeObject: Object): QualiteDefaut[] {
    const defauts: QualiteDefaut[] = [];
    const nbOjects = Object.keys(groupeObject).length;

    for (let i = 0; i<= nbOjects - 1; i++){
      const obj = Object.values(groupeObject)[i];
      const defaut: QualiteDefaut = new QualiteDefaut();
      defaut.nom = obj.nom;
      defaut.description = obj.description;
      defaut.cout = obj.cout;
      defauts.push(defaut);
    }

    return defauts;
  }

  getFactions(groupeObject: Object): Faction[] {
    const factions: Faction[] = [];
    const nbOjects = Object.keys(groupeObject).length;

    for (let i = 0; i<= nbOjects - 1; i++){
      const obj = Object.values(groupeObject)[i];
      const faction: Faction = new Faction();
      faction.nom = obj.nom;
      faction.description = obj.description;
      factions.push(faction);
    }

    return factions;
  }

  checkPointAtout(): boolean {
    if(this.totalPtAtoutDepense < 10 || (Number(this.pointsJoker) - Number(this.atoutSelectionne.cout) >= 0)){
      return true;
    }
    return false;
  }

  checkPointDefaut(): boolean {
    let total: number = 0;
    this.defautsSelectionnes.forEach(defaut => total += Number(defaut.cout))
    total += Number(this.defautSelectionne.cout);
    return total > 7 ? false : true;
  }

  checkPointQualite(cout: number): boolean {
    if(this.pointsJoker <= 0 || this.pointsJoker - cout < 0){
      return false
    }
    return true;
  }

  calculPoints(): void {
    let total: number = 0;
    this.totalPtAtoutDepense = 0;
    this.pointsJoker = 25;

    this.defautsSelectionnes.forEach(defaut => total -= Number(defaut.cout));
    this.handicapsSelectionnes.forEach(handicap => total -= Number(handicap.cout));
    this.qualitesSelectionnees.forEach(qualite => total += Number(qualite.cout));
    this.atoutsSelectionnes.forEach(atout => {
      this.totalPtAtoutDepense += Number(atout.cout);
    });

    if(this.totalPtAtoutDepense - 10 < 0) {
      this.pointsJoker -= total;
    } else {
      this.pointsJoker -= (total + this.totalPtAtoutDepense - 10);
    }

    this.factionService.sendJoker(this.pointsJoker);
  }

  onOptionsFactionSelected(): void{
    if(this.factionSelectionnee){
      this.factionService.sendFaction(this.factionSelectionnee);
    }
  }

  onOptionsQualiteSelected(): void {
    if (this.qualiteSelectionnee
      && this.qualitesSelectionnees.length < 7
      && this.qualitesSelectionnees.indexOf(this.qualiteSelectionnee) == -1
      && this.checkPointQualite(this.qualiteSelectionnee.cout)) {

      this.qualitesSelectionnees.push(this.qualiteSelectionnee);
      this.factionService.sendQualites(this.qualitesSelectionnees);
      this.calculPoints();
    }
  }

  onOptionsDefautSelected(): void {
    if (this.defautSelectionne
      && this.defautsSelectionnes.indexOf(this.defautSelectionne) == -1
      && this.checkPointDefaut()) {

      this.defautsSelectionnes.push(this.defautSelectionne);
      this.factionService.sendDefauts(this.defautsSelectionnes);
      this.calculPoints();
    }
  }

  onOptionsAtoutSelected(): void {
    if (this.atoutSelectionne
      && this.checkPointAtout()
      && (this.atoutsSelectionnes.indexOf(this.atoutSelectionne) == -1
    || this.atoutSelectionne.nom === 'Reliquaire d\'Anima' || this.atoutSelectionne.nom === 'Objet miraculeux'
    || this.atoutSelectionne.nom === 'Articles de piété')) {

      this.atoutsSelectionnes.push(this.atoutSelectionne);
      this.factionService.sendAtouts(this.atoutsSelectionnes);
      this.calculPoints();
    }
  }

  onOptionsHandicapSelected(): void {
    if (this.handicapSelectionne
      && this.handicapsSelectionnes.indexOf(this.handicapSelectionne) == -1) {

      this.handicapsSelectionnes.push(this.handicapSelectionne);
      this.factionService.sendHandicaps(this.handicapsSelectionnes);
      this.calculPoints();
    }
  }

  suppressionAtout(atout: QualiteDefaut): void {
    const index = this.atoutsSelectionnes.indexOf(atout);
    this.atoutsSelectionnes.splice(index, 1);
    this.factionService.sendAtouts(this.atoutsSelectionnes);
    this.calculPoints();
  }

  suppressionQualite(qualite: QualiteDefaut): void {
    const index = this.qualitesSelectionnees.indexOf(qualite);
    this.qualitesSelectionnees.splice(index, 1);
    this.factionService.sendQualites(this.qualitesSelectionnees);
    this.calculPoints();
  }

  suppressionDefaut(defaut: QualiteDefaut): void {
    const index = this.defautsSelectionnes.indexOf(defaut);
    this.defautsSelectionnes.splice(index, 1);
    this.factionService.sendDefauts(this.defautsSelectionnes);
    this.calculPoints();
  }

  suppressionHandicap(handicap: QualiteDefaut): void {
    const index = this.handicapsSelectionnes.indexOf(handicap);
    this.handicapsSelectionnes.splice(index, 1);
    this.factionService.sendDefauts(this.handicapsSelectionnes);
    this.calculPoints();
  }
}
