import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/modules/shared/shared.module';
import { AdcComponent } from './adc/adc.component';

@NgModule({
  declarations: [AdcComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [AdcComponent]
})
export class CthulhuModule { }
