<div *ngIf="!jeu" class="noSelect">
  <img src="assets/images/des.png" alt="etape" class="desChoix">
  <div class="choixText">
    <img src="assets/images/Pc_icon.jpg" class="bordsArrondisIcon iconChoix inlineBlock">
    <p class="textChoix inlineBlock">CHOISIR UN JEU</p>
    <img src="assets/images/Pc_icon.jpg" class="bordsArrondisIcon iconChoix inlineBlock">
  </div>
</div>
<div *ngIf="jeu" class="noSelect gameView">
  <div class="gameViewDisplayElement col-md-8">
    <div class="noSelect titre">
      <label for="labelLibelle">{{ jeu.libelle }}</label>
      <span class="tooltiptext onRight noSelect">
        <div>
          <img class="picJeu" src="{{ jeu.imageJeu }}">
        </div>
        <div >
          <span class="textJeu">{{ jeu.description }}</span>
        </div>
      </span>
    </div>
  </div>
  <div *ngIf="jeu.pret" class="gameViewDisplayElement col-md-4">
    <div class="noSelect titre" (click)="generationAleatoire()">
      <img src="assets/images/aleatoire.png" class="bordsArrondisIcon iconChoix imgAleatoire pointer">
      <label for="labelAleatoire" class="textAleatoire">Aléatoirement votre !</label>
      <span class="tooltiptext onRight noSelect">Pour créer votre personnage de façon complément aléatoire, convient plutôt aux PNJs</span>
    </div>
  </div>
</div>
<div *ngIf="jeu">
  <ng-template appAjoutTemplate></ng-template>
</div>
