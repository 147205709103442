import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import jsPDF from 'jspdf';
import { Jeu } from 'src/modules/core/modeles/jeu';
import { LoadDataService } from 'src/services/loadData.service';
import { GenerationService } from '../../services/generation.service';

@Component({
  selector: 'app-generation',
  templateUrl: './generation.component.html',
  styleUrls: ['./generation.component.css']
})
export class GenerationComponent implements OnInit, OnChanges {

  @Input() jeu: Jeu;
  @Input() ficheValeurs: string[][];

  private fichePositions = new Array();
  private elementsValeur = new Map();
  private elementsPosition = new Map();

  constructor(private loadDataService: LoadDataService, private generationService: GenerationService) { }

  ngOnInit(): void {
    this.generationFiche();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let nomFiche: string;

    if (this.fichePositions.length > 0 && this.ficheValeurs.length > 0) {

      // Page A4 en 150 dpi
      const fileWidth = 1240;
      const fileHeight = 1754;

      // Constitution de la map des positions : clef = attribut / valeur = position [x,y,longueurAVantSautDeLigne]
      this.createElementToGeneratePositionMap();

      // Constitution du pdf
      const PDF = new jsPDF('p', 'px', [fileWidth, fileHeight]);
      PDF.setFont("helvetica");
      PDF.setFontSize(24);

      let max = this.jeu.ficheLien.length;

      // Boucle for car le forEach ne garanti pas l'ordre
      for (let index = 0; index <= max - 1; index++) {

        PDF.addImage(this.jeu.ficheLien[index], 'jpg', 0, 0, fileWidth, fileHeight);

        // Constitution de la map des valeurs : clef = attribut / valeur = valeur à inscrire
        this.createElementToGenerateValueMap(this.ficheValeurs[index]);

        // Ajout des éléments à générer au pdf
        this.generateElements(PDF);

        // Alimentation du nom de la fiche
        if (nomFiche == null || nomFiche == undefined) {
          nomFiche = this.elementsValeur.get("nomFiche");
        }

        PDF.addPage();
      }
      PDF.internal.pages.length;
      PDF.deletePage(PDF.internal.pages.length - 1);
      PDF.save(nomFiche);
    }
  }

  generation(): void {
    this.generationService.sendGeneration("");
  }

  generateElements(PDF: jsPDF): void {

    this.elementsValeur.forEach((valeurElement: string, nomElement: string) => {

      if (nomElement.includes("ligne&")) {
        this.generateLigne(nomElement, PDF);
      }

      if (this.elementsPosition.get(nomElement) != null) {
        if (nomElement == "avatar" && String(this.elementsValeur.get(nomElement)).length > 8) {
          this.generateAvatar(nomElement, PDF);
        } else {
          PDF.text(String(this.elementsValeur.get(nomElement)), this.elementsPosition.get(nomElement)[0], this.elementsPosition.get(nomElement)[1]);
        }
      }
    })
  }

  generateLigne(nomElement: string, PDF: jsPDF): void {
    const elements = nomElement.split("&");
    const length = Number(elements[5]) + Number(elements[4]);
    const rouge = Number(elements[1]);
    const vert = Number(elements[2]);
    const bleu = Number(elements[3]);
    PDF.setDrawColor(rouge, vert, bleu);
    PDF.line(Number(elements[5]), Number(elements[6]), length, Number(elements[6]));
  }

  generateAvatar(nomElement: string, PDF: jsPDF): void {
    const cheminEtTailleMax = String(this.elementsValeur.get(nomElement));
    const index = cheminEtTailleMax.indexOf("&");
    const lastIndex = cheminEtTailleMax.lastIndexOf("!");
    const width = Number(cheminEtTailleMax.substr(0, index));
    const height = Number(cheminEtTailleMax.substr(index + 1, lastIndex - index - 1));
    const typeIndexBegin = cheminEtTailleMax.indexOf("/");
    const typeIndexLast = cheminEtTailleMax.indexOf(";");
    let type = cheminEtTailleMax.substr(typeIndexBegin + 1, typeIndexLast - typeIndexBegin - 1);

    if (type == "" || type == undefined) {
      type = "JPEG";
    }

    PDF.addImage(cheminEtTailleMax.substr(lastIndex + 1), type, Number(this.elementsPosition.get(nomElement)[0]), Number(this.elementsPosition.get(nomElement)[1]), width, height);
  }

  generationFiche() {

    const fichierFiche = '../assets/fiches/' + this.jeu.acronyme + '.json';
    this.loadDataService.getByAcronyme(fichierFiche).subscribe(data => {
      this.fichePositions = data.fiche;
    });
  }

  createElementToGenerateValueMap(valeurs: string[]): void {

    if (!valeurs) {
      return;
    }

    this.elementsValeur = new Map();

    valeurs.forEach(valeurElement => {
      const index = valeurElement.indexOf("#");
      const attribut = valeurElement.substr(0, index);
      const valeur = valeurElement.substr(index + 1);
      this.elementsValeur.set(attribut, valeur);
    })
  }

  createElementToGeneratePositionMap(): void {

    this.fichePositions.forEach(positionElement => {
      const indexUn = positionElement.indexOf("#");
      const indexDeux = positionElement.lastIndexOf("#");
      const clef = positionElement.substr(0, indexUn);
      const x = positionElement.substr(indexUn + 1, indexDeux - indexUn - 1);
      const y = positionElement.substr(indexDeux + 1);
      const positions = new Array();
      positions.push(x);
      positions.push(y);
      this.elementsPosition.set(clef, positions);
    });
  }
}
