import { Caracteristique } from "../commun/caracteristique";
import { PouvoirForce } from "../commun/pouvoirForce";
import { RaceStarWars } from "../race/starwars/raceStarWars";
import { Fiche } from "./fiche";

export class FicheSta extends Fiche{

  race: RaceStarWars;
  caracteristiquesForce : Caracteristique[];
  pouvoirsForce: PouvoirForce[];
}
