export class Caracteristique {

  nom: string;
  valeur: number;
  valeurMin: number;
  valeurMax: number;
  description: string;
  typeDes: number;
  nombreDes: number;
  bonusDes: number;
  seuilRelance: number;
  bonusAutre: number;
  multiplicateur: number;

  getNomNormalise() {
    return this.nom.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }
}
