export class GenerationHelper {

  static attendre(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  static safeReturn(valeur: any): string{

    if(valeur == null || valeur == undefined){
      return "";
    }

    return valeur;
  }

  static getD6Value(valeur: number): string{

    if(valeur == null || valeur == undefined){
      return "0";
    }

    if(valeur % 3 === 0){
      return valeur/3 + "D";
    }

    const modulo = (valeur / 3) - Math.floor(valeur/3);

    if(modulo < 0.5){
      return Math.floor(valeur/3) + "D+1";
    } else {
      return Math.floor(valeur/3) + "D+2";
    }
  }

  static getD100Value(valeur: number): string{

    if(valeur == null || valeur == undefined){
      return "0";
    }

    return valeur.toString();
  }

  static getArrondiInferieur(valeur: number): string{

    if(valeur == null || valeur == undefined){
      return "0";
    }

    return Math.floor(valeur).toString();
  }

  static supprimerLesEspaces(valeur: string): string{
    return valeur.replace(/\s+/g, '');
  }

  static creerUneLigne(rouge: number, vert: number, bleu: number, xpos: number, ypos: number, longueur: number): string{
    return "ligne&" + rouge + "&" + vert + "&" + bleu + "&" + longueur + "&" + xpos + "&" + ypos + "#";
  }

  static creerUnNom(longueur: number): string {

    const consonnes = ['B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Z'];
    const voyelles = ['A', 'E', 'I', 'O', 'U', 'Y'];
    let nom = '';
    let consonne : boolean = false;

    for (let i = 1; i <= longueur; i++){
      const lettres = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
      if(lettres == 1 && !consonne){
        nom += consonnes[Math.floor(Math.random() * (20 - 1 + 1))];
        consonne = true;
      } else if(lettres == 2 && consonne){
        nom += voyelles[Math.floor(Math.random() * (6 - 1 + 1))];
        consonne = false;
      } else if(lettres == 1 && consonne){
        nom += voyelles[Math.floor(Math.random() * (6 - 1 + 1))];
        consonne = false;
      } else {
        let alea = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
        if(alea == 1){
          nom += consonnes[Math.floor(Math.random() * (20 - 1 + 1))];
          consonne = true;
        } else {
          nom += voyelles[Math.floor(Math.random() * (6 - 1 + 1))];
          consonne = false;
        }
      }
    }

    return nom;
  }
}
