import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Caracteristique } from 'src/modules/core/modeles/fiche/commun/caracteristique';
import { Des } from 'src/modules/core/utilitaires/des';
import { ParcoursVieService } from '../services/parcours-vie.service';

@Component({
  selector: 'app-parcours-vie',
  templateUrl: './parcoursVie.component.html',
  styleUrls: ['./parcoursVie.component.css']
})
export class ParcoursVieComponent implements OnInit, OnChanges {

  @Input() caracteristiques: Caracteristique[];
  @Input() malus: Map<string,number>;
  @Input() resetMalus: boolean;
  @Input() age: number;

  public chance: number = 0;
  public education: number = 0;
  public premierJetChance: number;
  public secondJetChance: number;
  public experienceJets: string[];
  public bonus: number;
  public malusCommun: number = 5;
  public malusCourant: number = 5;
  public malusApparence: number = 0;
  public malusEducation: number = 5;
  public malusForce: number = 0;
  public malusTaille: number = 0;
  public malusConstitution: number = 0;
  public malusDexterite: number = 0;
  public malusMaxForce: number;
  public malusMaxTaille: number;
  public malusMaxConstitution: number;
  public malusMaxDexterite: number;
  private categorie: number = 0;

  constructor(private parcoursVieService: ParcoursVieService) { }

  ngOnChanges() {
    if(this.resetMalus){

      this.reinitDistributionMalusCarac();
      this.actualisationResetMalus();

    } else if(this.malus != undefined && this.malus.size > 0){

      this.malus.get("Apparence") > 0 ? this.malusApparence = this.malus.get("Apparence") : 0;
      this.malus.get("Force") > 0 ? this.malusForce = this.malus.get("Force") : 0;
      this.malus.get("Constitution") > 0 ? this.malusConstitution = this.malus.get("Constitution") : 0;
      this.malus.get("Dexterite") > 0 ? this.malusDexterite = this.malus.get("Dexterite") : 0;
      this.malus.get("Taille") > 0 ? this.malusTaille = this.malus.get("Taille") : 0;
      this.malus.get("Education") > 0 ? this.malusEducation = this.malus.get("Education") : 0;

      let total = this.malusForce + this.malusConstitution + this.malusDexterite + this.malusTaille;
      const categories = [5,0,5,10,20,40,80];
      this.calculCategorie();
      this.malusCommun = categories[this.categorie];
      this.malusCourant = this.malusCommun - total;
      if(this.age < 20 ){
        this.experienceJets = [];
        this.education = this.caracteristiques[6].valeur;
        this.lancerChance();
      } else if(this.age > 19) {
        this.chance = this.caracteristiques[8].valeur;
        this.lancerExperience();
      }
    }
  }

  ngOnInit(): void {
    this.experienceJets = new Array();

    if(this.age === undefined || this.age <= 15){
      this.age = 15;
    }
  }

  updateFiche(){
    this.parcoursVieService.sendAge(this.age);
    this.actualisationParcours();
    this.actualisationCaracs();
  }

  actualisationCaracs(){

    // Actualisation des valeurs avec les bonus et malus non variabilisé
    if(this.chance > 0){
      this.malus.set("Chance", (this.chance - this.caracteristiques[8].valeur) * -1);
    }

    if(this.education > 0 && this.malusEducation == 0){
      this.malus.set("Education", (this.education - this.caracteristiques[6].valeur) * -1);
    } else if(this.malusEducation > 0){
      this.malus.set("Education", this.malusEducation);
    }

    if(this.malusApparence > 0){
      this.malus.set("Apparence", this.malusApparence);
    }

    this.parcoursVieService.sendMalus(this.malus);
  }

  calculCategorie(){
    if(this.age < 20){
      this.categorie = 0;
    } else if (this.age > 19 && this.age < 40){
      this.categorie = 1;
    } else if (this.age > 39 && this.age < 50){
      this.categorie = 2;
    } else if (this.age > 49 && this.age < 60){
      this.categorie = 3;
    } else if (this.age > 59 && this.age < 70){
      this.categorie = 4;
    } else if (this.age > 69 && this.age < 80){
      this.categorie = 5;
    } else if (this.age > 79){
      this.categorie = 6;
    }
  }

  actualisationAge(valeur){

    if(valeur.target){
      this.age = valeur.target.value;
    }

    let categoriePrecedente = this.categorie;

    this.reinitDistributionMalusCarac();
    this.calculCategorie();

    if(this.age < 20){
      this.malusCommun = 5;
      this.malusEducation = 5;
      this.malusApparence = 0;
    } else if (this.age > 19 && this.age < 40){
      this.malusApparence = 0;
      this.malusCommun = 0;
      this.malusEducation = 0;
    } else if (this.age > 39 && this.age < 50){
      this.malusApparence = 5;
      this.malusCommun = 5;
      this.malusEducation = 0;
    } else if (this.age > 49 && this.age < 60){
      this.malusApparence = 10;
      this.malusCommun = 10;
      this.malusEducation = 0;
    } else if (this.age > 59 && this.age < 70){
      this.malusApparence = 15;
      this.malusCommun = 20;
      this.malusEducation = 0;
    } else if (this.age > 69 && this.age < 80){
      this.malusApparence = 20;
      this.malusCommun = 40;
      this.malusEducation = 0;
    } else if (this.age > 79){
      this.malusApparence = 25;
      this.malusCommun = 80;
      this.malusEducation = 0;
    }

    if(categoriePrecedente != this.categorie){
      this.chance = this.caracteristiques[8].valeur;
      this.education = this.caracteristiques[6].valeur;
      this.premierJetChance = null;
      this.secondJetChance = null;
      this.experienceJets = new Array();
      this.bonus = null;
      this.malus = new Map();
    }

    this.malusMaxForce = this.malusMaxTaille = this.malusMaxConstitution = this.malusMaxDexterite = this.malusCourant = this.malusCommun;
    this.updateFiche();
  }

  reinitDistributionMalusCarac(){
    this.malusForce = 0;
    this.malusConstitution = 0;
    this.malusDexterite = 0;
    this.malusTaille = 0;
  }

  actualisationParcours(): void{
    this.parcoursVieService.sendDone(this.malusDexterite + this.malusConstitution + this.malusForce + this.malusTaille == this.malusCommun ? true : false);
  }

  actualisationResetMalus(): void{
    this.parcoursVieService.sendReset(false);
  }

  lancerChance() {

    this.chance = 0;
    this.premierJetChance = Des.lancer(6,3,0,0,5);
    this.secondJetChance = Des.lancer(6,3,0,0,5);
    this.chance = Math.max(this.premierJetChance, this.secondJetChance, this.caracteristiques[8].valeur);
    this.updateFiche();
  }
​
  lancerExperience(){

    let nombreLance;
    this.categorie > 3 ? nombreLance = 4 : nombreLance = this.categorie;
    this.experienceJets = [];
    this.bonus = 0;
    this.education = this.caracteristiques[6].valeur;

    for ( let i = 1; i <= nombreLance; i++){
      const jet = Des.lancer(100,1,0,0,1);
      if(jet > this.education){
        this.bonus += Des.lancer(10,1,0,0,1);
        this.experienceJets.push("OK");
      } else {
        this.experienceJets.push("KO");
      }
    }

    this.education += this.bonus;
    this.education > 99 ? this.education = 99 : this.education;
    this.updateFiche();
  }

  calculValeur(caracteristique: Caracteristique, modificateur: number): number{
    return caracteristique.valeur + modificateur;
  }

  updateMalus(event: Event, caracteristique: Caracteristique): void{

    const valeur = Number(event.target["value"]);

    if(this.malusCourant == 0 && (this.malusCommun - valeur <= 0)){
      event.target["value"] = this.malusCommun;
      return;
    }

    if(caracteristique.valeur - valeur < 0){
      switch(caracteristique.nom){
        case "Force":
          this.malusForce -= 1;
          break;
        case "Taille":
          this.malusTaille -= 1;
          break;
        case "Dextérité":
          this.malusDexterite -= 1;
          break;
        case "Constitution":
          this.malusConstitution -= 1;
          break;
      }
      return;
    }

    if(this.age < 20 ){
      if(caracteristique.nom == "Force"){
        this.malusForce = valeur;
        this.malusMaxTaille = this.malusCommun - valeur;
        this.malus.set(caracteristique.nom,this.malusForce);
      } else{
        this.malusTaille = valeur;
        this.malusMaxForce = this.malusCommun - valeur;
        this.malus.set(caracteristique.nom,this.malusTaille);
      }
    } else if(this.age > 39 ){
      if(caracteristique.nom == "Force"){
        this.malusForce = valeur;
        this.malusMaxConstitution = this.malusCommun - this.malusForce - this.malusDexterite;
        this.malusMaxDexterite = this.malusCommun - this.malusForce - this.malusConstitution;
        this.malus.set(caracteristique.nom,this.malusForce);
      } else if (caracteristique.nom == "Constitution"){
        this.malusConstitution = valeur;
        this.malusMaxDexterite = this.malusCommun - this.malusForce - this.malusConstitution;
        this.malusMaxForce = this.malusCommun - this.malusDexterite - this.malusConstitution;
        this.malus.set(caracteristique.nom,this.malusConstitution);
      }else {
        this.malusDexterite = valeur;
        this.malusMaxConstitution = this.malusCommun - this.malusForce - this.malusDexterite;
        this.malusMaxForce = this.malusCommun - this.malusDexterite - this.malusConstitution;
        this.malus.set(caracteristique.nom,this.malusDexterite);
      }
    }

    this.malusCourant = this.malusCommun - this.malusForce - this.malusConstitution - this.malusTaille - this.malusDexterite;
    this.updateFiche();
  }

  checkNumber(event, minValue: Number, maxValue: Number, caracteristique: Caracteristique){
    const inputValue = event.target.value.trim();
    const isValid = /^\d{0,1}$/.test(inputValue) || inputValue === 'Backspace' || inputValue === 'Delete';

    if (!isValid) {
      event.target.value = minValue;
      return;
    }

    if(inputValue < minValue){
      event.target.value = minValue;
      return;
    }

    if(inputValue > maxValue){
      event.target.value = maxValue;
      return;
    }

    this.updateMalus(event, caracteristique);
  }
}
